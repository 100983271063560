<script lang="ts">
    import Selector from "./Selector.svelte";
    import {t} from "../../i18n"
    import {btConnection} from "../../script/common"
    import OutputBehaviour from "../../script/connection-behaviours/OutputBehaviour";

    /** Save details about output */
    export let gesture = undefined;

    let sound = gesture.output?.sound || 0;

    export let trigger = () => {
    };

    btConnection.subscribe(({outputVersion}) => {
        if (outputVersion === 1) {
            trigger = () => {
                if (!chosen?.sound) return;
                playSound(chosen.sound)
            }
        } else if (outputVersion === 2) {
            trigger = () => {
                if (!chosen?.id) return;
                OutputBehaviour.sendToSound(chosen.id);
            }
        } else if (outputVersion === 0) { //When disconnected
            trigger = () => {
            };
        }

        const playSound = (path) => {
            const sound = new Audio(path);
            sound.play();
        }
    })

    // Soundeffects available
    const soundArray = [
        {name: $t("content.model.output.soundOptionCongratulations"), id: "0", sound: "sounds/congratulations.wav"},
        {name: $t("content.model.output.soundOptionHighPitchBeep"), id: "1", sound: "sounds/high_pitch.wav"},
        {name: $t("content.model.output.soundOptionLowPitchBeep"), id: "2", sound: "sounds/low_pitch.wav"},
        {name: $t("content.model.output.soundOptionLoser"), id: "3", sound: "sounds/looser.wav"},
        {name: $t("content.model.output.soundOptionMistake"), id: "4", sound: "sounds/mistake.mp3"},
        {name: $t("content.model.output.soundOptionHugeMistake"), id: "5", sound: "sounds/huge_mistake.wav"},
    ];

    // ensure that sound is saved in gesture
    let chosen = soundArray[parseInt(sound)];
    $: gesture.output.sound = chosen?.id;
</script>

<Selector title={$t("content.model.output.playSound")} options={soundArray} bind:chosen/>
