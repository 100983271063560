<script lang="ts">
	import { alertUser, DeviceRequestStates, informUser, state } from "../../script/common";
	import LiveGraph from "../graphs/LiveGraph.svelte";
	import { t } from "../../i18n";
	import { get, writable } from "svelte/store";
	import ConnectButton from "../ConnectButton.svelte";
	import ConnectionBehaviours from "../../script/connection-behaviours/ConnectionBehaviours";
	import { openConnectionPromptModal } from "../../script/modal/connectionPrompt";
	import Information from "../information/Information.svelte";

	// Local variable to store the isConnected-state
	$: notConnected = !$state.isConnected;

	let isConnecting;
	let isConnected;

	// todo: 'as {device: BluetoothDevice}' is a hack to avoid red squiggles in the IDE
	const btDevice = writable({} as { device: BluetoothDevice });

	// Local variable and function to register the state of the system.
	// The function is passed to the bt-connector, which ensures that
	// The button displays the proper feedback
	const connectButtonClicked = () => {
		if (get(state).isConnected) {
			informUser($t("alert.micro.isConnectedInform"));
			alertUser($t("alert.micro.isConnectedAlert"));
			return;
		}
		if (!navigator.bluetooth) {
			alertUser($t("alert.micro.noBluetoothAlert"));
			return;
		}
		isConnecting = true;
		openConnectionPromptModal(DeviceRequestStates.INPUT);
	};

	const disconnectButtonClicked = () => {
		ConnectionBehaviours.getInputBehaviour().bluetoothDisconnect(true);
		isConnecting = false;
	};

	let w = window.innerWidth - 460;

	setInterval(() => {
		if (w != window.innerWidth - 460) {
			// Screen was resized
			w = window.innerWidth - 460;
		}
	}, 500);
</script>

<main
	class="h-full w-full flex flex-row bg-white border-t-2"
	class:border-gray-200={!notConnected}
	class:border-red-500={notConnected}
>
	<div class="relative float-left">
		<Information
			boxOffset={{x:20, y:-20}}
			iconOffset={{x:3, y:0}}
			text={$t("footer.helpContent")}
			title={$t("footer.helpHeader")}
			width={22}
		/>
	</div>
	<div class="h-full w-52 text-center" id="left">
		<ConnectButton
			onConnectClick={connectButtonClicked}
			onDisconnectClick={disconnectButtonClicked}
		/>
	</div>

	<div class="w-full h-full text-center" id="right">
		<!-- If microbit is not connected. Write in big letters that the user should connect ... -->
		<p
			class="absolute text-red-500 font-bold text-3xl"
			hidden={!notConnected}
		>
			{$t("footer.heading")}
		</p>

		<!-- Show live graph of input from micro:bit -->
		<LiveGraph width={w} />
	</div>
</main>
