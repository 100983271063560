<script>
    import { state } from "../script/common";
    import { t } from "../i18n" 
</script>

{#if $state.isPredicting}
<div class="w-full h-full justify-center items-center flex">
    <img
    src="model.svg"
    alt="Model Icon"
    class="w-50 mt-6 animate-spin animate-duration-[10s]"
  />
</div>
{:else}
<div class="w-full h-full justify-center items-center flex">
    <div
    class="flex items-center justify-center border-6 border-black border-opacity-30 rounded-lg border-dashed m-2 mt-6 p-1 align-middl text-2xl font-bold text-warm-gray-300 h-60 w-60"
  >
    {$t("content.trainer.noModel")}
  </div>
</div>
{/if}