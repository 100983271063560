export default {
    da: {
        // ALERTS:
        //In micro.js
        "alert.micro.inputDetached": "Input Micro:bit frakoblet",
        "alert.micro.isConnectedInform": "Hvis du oplever problemer: Frakobl først!",
        "alert.micro.isConnectedAlert": "Du er allerede tilsluttet!",
        "alert.micro.noBluetoothAlert": "Din browser har ikke bluetooth...",
        "alert.micro.GATTserverInform": "Tilkobling til GATT server...",
        "alert.micro.microBitServiceInform": "Tilkobling til Micro:bit services...",
        "alert.micro.gettingDataInform": "Anskaffer data",
        "alert.micro.nowConnectedInform": "Micro:bit er nu tilkoblet",
        //In gesture.svelte
        "alert.data.classNameLengthAlert": "Navne må kun bestå af 25 tegn",
        //In input.svelte
        "alert.data.error.deleting": "Dine gestures blev ikke slettet.",
        //In output.js
        "alert.output.outputDetached": "Output Micro:bit frakoblet",
        "alert.output.isConnectedInform": "Hvis du oplever problemer: Prøv at afkoble først!",
        "alert.output.isConnectedAlert": "Du er allerede forbundet!",
        "alert.output.noBluetoothAlert": "Din browser har ikke bluetooth...",
        "alert.output.GATTserverInform": "Tilkobling til GATT server...",
        "alert.output.microBitServiceInform": "Tilkobling til Micro:bit services...",
        "alert.output.connectingToComponents": "Anskaffer forbindelse til enkelte komponenter...",
        "alert.output.nowConnectedInform": "Micro:bit er nu tilkoblet",
        "alert.output.isDisconnectedInform": "Der er ikke tilsluttet en Micro:bit",
        //In common.js
        "alert.isRecording": "Du er i gang med at optage!",
        "alert.isTesting": "Du er i gang med at optage!",
        "alert.isTraining": "Du er i gang med at træne en model!",
        "alert.isNotConnected": "Din Micro:bit skal være tilsluttet!",
        "alert.localDataInform": "Den lokale data har fejl, og er derfor blevet slettet :)",
        "alert.localData.Alert": "Noget gik galt med at indlæse lokale filer",
        "alert.clearGesturesConfirm": "Er du sikker på at du vil slette dine eksempler?",
        "alert.clearGesturesAlert": "Undladet at slette eksempler",
        "placeholder.data.inactive": "Stille",
        "placeholder.data.circle": "Cirkel",
        //In filemanagement.ts
        "alert.confirmOverwirte": "Vil du overskrive de eksisterende eksempler?",
        "alert.interruptUpload": "Afbryder uploading af fil",
        "alert.wrongFilmeFormat": "Filen har ikke det rigtige fil-format",
        "alert.updateGestures": "Eksempler er opdateret",
        "alert.noGesturesToSave": "Der er ingen eksempler at gemme",
        "alert.savingProject": "Gemmer dit projekt",
        //In ml.ts
        "alert.beginModelSetup": "Påbegynder opsætning af model",
        "alert.trainingModel": "Træner modellen",
        "alert.twoGestures": "Du skal have mindst to klasser",
        "alert.oneDataRepresentation": "Du skal have mindst en data-repræsentation",
        "alert.recordingsPerGesture": "Du skal have mindst tre eksempler for hver klasse",
        // HOME PAGE:
        "content.index.heading": "Gør Det Selv Machine Learning",
        "content.index.howHeading": "Hvordan Det Virker",
        "content.index.howBody": "Denne hjemmeside muliggør interaktion med en Machine Learning (ML) algoritme, ved at lade brugeren optage sine egne bevægelser ved hjælp af Micro:Bit'ens accelerometer og bluetooth. Herefter trænes en ML-model til at genkende disse bevægelser. Yderligere kan der tilsluttes en Micro:bit mere, der opsættes til at reagere på genkendelsen af de forskellige bevægelser",
        "content.index.setupHeading": "Virker Nu Med micro:bit V1",
        "content.index.setupBody1": "ml-machine.org understøtter nu micro:bit version 1! De to versioner er identiske med undtagelse af lyde, der vil afspilles fra computeren med v1, mens de afspilles fra micro:bit'en med v2 (der er en indbygget højtaler i v2). Forbind din micro:bit med USB og tryk 'Tilslut' for at komme i gang.",
        "content.index.setupBody2": "Hjemmesiden benytter bluetooth, accelerometer, LED'er, knapper og lyd fra micro:bit'en. For at hjemmesiden kan tilgå disse, skal der overføres et program til Micro:Bit'en. Det downloades første gang du tilslutter micro:bit'en med USB. Efterfølgende kan du blot forbinde med bluetooth uden at bruge USB-kablet.",
        "content.index.v1": "V1",
        "content.index.v2": "V2",
        "content.index.contactHeading": "Kontakt",
        "content.index.contactBody": "Hvis du finder fejl og mangler på denne platform, skal du være velkommen til at skrive til os. Hvis du har andre efterspørgsler, ideer eller generel interesse, skal du være velkommen til at kontakte os. Skaberne af denne platform kan kontaktes på keb@cs.au.dk.",
        "content.index.byline": "Skabt af CEED, Aarhus Universitet",
        // DATA PAGE:
        "content.data.header.delete": "Slet eksempler",
        "content.data.header.save": "Gem eksempler",
        "content.data.header.upload": "Upload eksempler",
        "content.data.header.shortcutHeading": "Optagelsesgenvej:",
        "content.data.header.shortcutHelpBody": "Denne genvej giver mulighed for at aktivere optagelsesprocessen ved at trykke på knap A eller B. Først vælger du den kasse du gerne vil optage til, og derefter kan du let optage eksempler ved at trykke på din micro:bit",
        "content.data.header.shortcutOptionNone": "Ingen",
        "content.data.header.shortcutOptionA": "Knap A",
        "content.data.header.shortcutOptionB": "Knab B",
        "content.data.header.shortcutOptionAB": "Knap A & B",
        "content.data.classPlaceholderNewClass": "Klik for at ændre navnet",
        "content.data.select": "Vælg",
        "content.data.selected": "Valgt",
        "content.data.record": "Optag",
        "content.data.delete": "Slet",
        "content.data.classHelpHeader": "Klasse",
        "content.data.classHelpBody": "En klasse beskriver en type af bevægelse. Ved at optage eksempler af en bestem typer bevægelser og putte eksemplerne i samme klasse, kan træneren 'træne' en model, der kan genkende denne type bevægelse. Flere eksmpler vil typisk reulstere i en bedre model, men overvej på hvor mange forskellige måder en bevægelse kan laves.",
        // TRAINER PAGE:
        "content.trainer.noModel": "Ingen model",
        "content.trainer.filters.recordExamplesFirstHeading": "Optag nogle eksempler først!",
        "content.trainer.filters.recordExamplesFirstBody": "Gå til Datasiden",
        "content.trainer.filters.header.recordExampleButton": "Optag Eksempel",
        "content.trainer.filters.header.focus": "Fokuser på:",
        "content.trainer.filters.max.heading": "Maksværdier",
        "content.trainer.filter.max.helpBody": "Det højeste punkt blandt alle datapunkter i en bevægelse.",
        "content.trainer.filters.min.heading": "Minimumsværdier",
        "content.trainer.filters.min.helpBody": "Det laveste punkt blandt alle datapunkter i en bevægelse.",
        "content.trainer.filters.standardDev.heading": "Standardafvigelse",
        "content.trainer.filter.standardDev.helpBody": "Den gennemsnitlige afvigelse fra 0 blandt alle datapunkter i en bevægelse.",
        "content.trainer.filter.extremes.heading": "Antal ekstremer",
        "content.trainer.filter.extremes.helpBody": "Antallet af ekstremer blandt alle datapunkter i en bevægelse.",
        "content.trainer.filter.acc.heading": "Samlet acceleration",
        "content.trainer.filter.acc.helpBody": "Summen af acceleration for hele bevægelsen, udregnet med absolutte værdier, således at minus tæller som plus. God til at forstå mængden af bevægelse.",
        "content.trainer.filter.recordExampleButton": "Optag Eksempel",
        "content.trainer.filter.recordingHeading": "Optagelse",
        "content.trainer.testcenter.header.deleteTestsButton": "Slet alle test",
        "content.trainer.testcenter.header.recordingShortcut": "Optagelsesgenvej:",
        "content.trainer.testcenter.header.recordingShortcut.helpHeading": "Optagelsesgenvej",
        "content.trainer.testcenter.header.recordingShortcut.helpBody": "Med denne genvej kan du optage ved at trykke på knap A eller B. Først vælger du den klasse du gerne vil optage eksempler til, og derefter kan du optage eksempler ved at trykke på din Micro:bit",
        "content.trainer.testcenter.header.recordingOptionNone": "Ingen",
        "content.trainer.testcenter.header.recordingOptionA": "Knap A",
        "content.trainer.testcenter.header.recordingOptionAB": "Knap A & B",
        "content.trainer.testcenter.recordFirst": "Opret nogle klasser først!",
        "content.trainer.testcenter.GoToDataPage": "Gå til Datasiden",
        "content.trainer.testcenter.testOf": "Test af",
        "content.trainer.testcenter.chooseButton": "Vælg",
        "content.trainer.testcenter.chosenButton": "Valgt",
        "content.trainer.testcenter.recordButton": "Optag",
        "content.trainer.testcenter.helpHeading": "Test-meter",
        "content.trainer.testcenter.helpBody": "Her kan du lave tests til dine forskellige klasser. For hver test du optager, vil boksen under navnet deles op. Hver opdeling repræsenterer en af dine tests. Når du træner din model, vil disse opdelinger lyse op i grøn, hvis modellen gætter rigtig og rød, hvis den gætter forkert. Dette kan du bruge til at undersøge hvilke bevægelser din model har svært ved at genkende.",
        "content.trainer.testCenter.alert.deleteTests": "Er du sikker på at du vil slette dine test?",
        "content.trainer.testCenter.alert.deleteTests.failed": "Noget gik galt. Dine test blev ikke slettet.",
        "content.trainer.testCenter.alert.recordingFinished": "Færdiggjort optagelse.",
        "content.trainer.testCenter.alert.disconnectDuringRecording": "Micro:bit frakoblede under optagelse",
        // MODEL PAGE:
        "content.model.trainModelFirstHeading": "Træn modellen først!",
        "content.model.trainModelFirstBody": "Gå til 'Træner'-siden",
        "content.model.header.automaticOn": "Automatisk til",
        "content.model.header.automaticOff": "Automatisk fra!",
        "content.model.header.automatic.helpHeading": "Klassifikation",
        "content.model.header.automatic.helpBody": "Når modellen gætter, hvilken bevægelse Micro:bit'en bevæges i, siger vi at den laver en klssifikation. Her kan du specificere, hvornår dit system skal lave en klassiciferingen. Automatisk betyder, at det hele tiden klassificere, mens det kun laver en klassificering, når du trykker på knap A eller B, hvis du slår det fra.",
        "content.model.classification.helpHeading": "Sandsynligheds-meteret",
        "content.model.classification.helpBody": "For hver klassifikation du laver, vil modellen producere et procent-tal, der beskriver hvor sikker modellen er på at det er denne bevægelse. Dette meter går fra 0% til 100%. Med slideren ved siden af, kan du bestemme grænsen for, hvor sikker modellen skal være i sin klassificering for at lave et output",
        "content.model.output.helpHeading": "Sæt Output",
        "content.model.output.helpBody": "Her kan du vælge, hvilket output systemet skal lave. Du kan sætte LED displayet på Micro:Bit'en, få den til at spille en lyd, eller tænde for en pin. Dette signal vil sendes til din output Micro:Bit såfremt modellen gætter på denne klasse.",
        "content.model.output.affect": "Påvirk",
        "content.model.output.optionPin": "Pin",
        "content.model.output.optionSound": "Lyd",
        "content.model.output.pinOn": "Tænd pin",
        "content.model.output.playSound": "Afspil",
        "content.model.output.soundOptionCongratulations": "Tillykke",
        "content.model.output.soundOptionHighPitchBeep": "Højtone Bip",
        "content.model.output.soundOptionLowPitchBeep": "Lavtone Bip",
        "content.model.output.soundOptionLoser": "Taber",
        "content.model.output.soundOptionMistake": "Fejl",
        "content.model.output.soundOptionHugeMistake": "Kæmpe fejl",
        // FOOTER:
        "footer.connectButtonNotConnected": "Tilslut",
        "footer.connectButtonConnected": "Tilsluttet",
        "footer.connectButtonConnecting": "Tilslutter",
        "footer.disconnectButton": "Frakobl",
        "footer.heading": "TILSLUT DIN MICRO:BIT",
        "footer.helpHeader": "Live graf",
        "footer.helpContent": "Når du har forbundet en Micro:Bit kan du live se Accelerometer-data for alle tre akser på denne graf. Prøv at bevæge din forbundet Micro:bit for at se, hvordan den data der produceres af bevægelser ser ud for computeren!",
        //DATA MENU:
        "menu.data.helpHeading": "Data",
        "menu.data.helpBody": "For at træne en model til at genkende forskellige bevægelser, skal vi have gode eksempler på 'god opførsel, som vi kan vise træneren. Her kan i oprette klasser (en type bevægelse) og optage eksempler til hver klasse. Der skal være mindst 2 klasser med hver 3 eksempler før træneren kan træne en model.",
        "menu.data.examples": "eksempler",
        // TRAINER MENU:
        "menu.trainer.helpHeading": "Træner",
        "menu.trainer.helpBody": "Træneren kigger på på eksemplerne i klasserne og forsøger at 'lære', hvordan de forskellige klasser kan genkendes. Her kan i træne en model der kan genkende forskellige bevægelser. Derudover kan i indstille træneren, vælge hvordan træneren skal forstå dataen og oprette test-sæt.",
        "menu.trainer.filters": "Filtre",
        "menu.trainer.filters.helpHeading": "Filtre",
        "menu.trainer.filters.isRecording": "Optager",
        "menu.trainer.filters.recording": "Optagelse",
        "menu.trainer.filters.finishedRecording": "Færdiggjorde optagelse",
        "menu.trainer.filters.helpBody": "Her bestemmer vi hvordan træneren skal se vores data (vores eksmepler). Det gør vi ved at sætte forskellige filtre på de rå optagelser. Eftersom hver optagelse består af 50 data-punkter med tre koordinater, vil mængden hurtig blive stor.",
        "menu.trainer.filters.maxButton": "Maks",
        "menu.trainer.filters.minButton": "Minimum",
        "menu.trainer.filters.deviationButton": "Afvigelse",
        "menu.trainer.filters.extremesButton": "Ekstremer",
        "menu.trainer.filters.accButton": "Acceleration",
        "menu.trainer.testCenter": "Testcenter",
        "menu.trainer.testCenter.helpHeading": "Testcenter",
        "menu.trainer.testCenter.helpBody": "Her kan du lave et test-sæt, som du kan bruge til at teste din model. Når du har trænet en model, køres alle dine optagede test igennem modellen, og du kan se, om modellen kan gnekende bevægelserne fra dit test-sæt korrrekt. Menuens parametre er indstillinger til træneren. Læringsrate beskriver hvor store ændringer træneren laver i sin 'forståelse af verden' hver gang den kigger på et nyt eksempel, og antallet af omgange fortæller noget om, hvor mange gange træneren skal kigge på hvert eksempel.",
        "menu.trainer.testCenter.learningRateSlider": "Læringsrate",
        "menu.trainer.testCenter.epochsSlider": "Omgange",
        "menu.trainer.trainModelButton": "Træn model",
        "menu.trainer.trainNewModelButton": "Træn ny model",
        "menu.trainer.isTrainingModelButton": "Træner model",
        "menu.trainer.trainModelButton.helpHeading": "Træn din model",
        "menu.trainer.trainModelButton.helpBody": "Her kan du træne din model. Træneren tage alle dine eksempler og 'træne' en model. Denne model kan bagefter bruges til at gætte hvilke bevægelser din Micro:bit bevæges i.",
        // MODEL MENU:
        "menu.model.helpHeading": "Model",
        "menu.model.helpBody": "Modellen kan bruges i et interaktivt system. Her bruger vi den trænede model til at gætte bevægelser. Du kan samtidigt forbinde endnu en Micro:Bit og få den til at reagere på de bevægelser du laver.",
        "menu.model.noModel": "Ingen model",
        "menu.model.connectOutputButton": "Tilslut",
        "menu.model.connectingOutputButton": "Tilslutter",
        "menu.model.connectedOutputButton": "Tilsluttet",
        "menu.model.disconnect": "Frakobel",
        "menu.model.helpConnectOutputButtonHeading": "Lav et output",
        "menu.model.helpConnectOutputButtonBody": "Her kan du tilslutte en Micro:Bit mere, således vi kan få din model til at gøre ting i den virkelige verden. Start dog med at træne en model inden du forbinder denne Micro:Bit.",
        //CONNECT MICROBIT POP UP:
        "popup.connectMB.alert.failToReadService": "Kunne ikke læse accelerometer! Prøv venligst igen.",
        "popup.connectMB.main.heading": "Sådan tilslutter du din micro:bit",
        "popup.connectMB.main.usb.subtitle": "Tilslut med USB",
        "popup.connectMB.main.usb.step1": "Tilslut din micro:bit med USB-kabel.",
        "popup.connectMB.main.usb.step2": "Tryk på knappen, 'find USB enhed' nedenfor.",
        "popup.connectMB.main.usb.step3": "Vælg din micro:bit og tryk 'tilslut'.",
        "popup.connectMB.main.usb.connect": "Find USB enhed",
        "popup.connectMB.main.bluetooth.subtitle": "Tilslut med Bluetooth",
        "popup.connectMB.main.bluetooth.step1": "Tilslut et batteri til din micro:bit.",
        "popup.connectMB.main.bluetooth.step2": "Tryk på knappen, 'Tilslut med bluetooth' nedenfor.",
        "popup.connectMB.main.bluetooth.connect": "Tilslut med Bluetooth",
        "popup.connectMB.main.alreadyConnected.heading": "En micro:bit er allerede forbundet",
        "popup.connectMB.main.alreadyConnected.mainText": "Vi kan se at en micro:bit allerede er forbundet. Du kan vælge at bruge den samme, eller vælge en anden.",
        "popup.connectMB.main.alreadyConnected.useSameButton": "Brug samme",
        "popup.connectMB.main.alreadyConnected.useOtherButton": "Vælg anden",
        "popup.connectMB.usb.heading": "Tilslut med USB",
        "popup.connectMB.usb.MBFound": "fundet!",
        "popup.connectMB.usb.transferring.pleaseWait": "Vent mens vi overfører programmet til micro:bitten",
        "popup.connectMB.usb.transferFinished.pleaseDisconnect": "Du kan nu frakoble USB kablet og tilslutte et batteri.",
        "popup.connectMB.usb.transferFinished.finishedProgress": "Færdig!",
        "popup.connectMB.usb.transferFinished.transferComplete": "Programmet er overført til micro:bitten.",
        "popup.connectMB.usb.transferFinishedAndDisconnected.plugInBattery": "Micro:bit frakoblet. Du kan nu tilslutte et batteri.",
        "popup.connectMB.usb.transferFinishedAndDisconnected.pleaseConnect": "Tryk på tilslut, når du er klar til at fortsætte.",
        "popup.connectMB.usb.connect": "Tilslut",
        "popup.connectMB.usb.haveNoBatteryQuestion": "Har du ikke noget batteri?",
        "popup.connectMB.usb.continueWithoutBattery": "Fortsæt uden batteri ved at trykke",
        "popup.connectMB.usb.continueWithoutBatteryButton": "her.",
        "popup.connectMB.bluetooth.heading": "Tilslut med Bluetooth",
        "popup.connectMB.bluetooth.subtitle": "Sådan gør du",
        "popup.connectMB.bluetooth.step1": "Tegn mønstret du ser på micro:bit'en",
        "popup.connectMB.bluetooth.step2": "Tryk på knappen nedenfor.",
        "popup.connectMB.bluetooth.step3": "Vælg din micro:bit og tryk 'tilslut'.",
        "popup.connectMB.bluetooth.wrongPattern": "Forkert mønster!",
        "popup.connectMB.bluetooth.connect": "Tilslut",
        // COOKIE BANNER
        "cookies.banner.title": "Cookie politik",
        "cookies.banner.subtitle": "Forbrug og analyse",
        "cookies.banner.text.pleaseHelp": "Hjælp os med at forbedre siden! Tillad cookies for at hjælpe os.",
        "cookies.banner.text.description": "Her hos ML-Machine bruger vi cookies for at indsamle information omkring forbrug af siden. Uden disse cookies er vi ikke i stand til at se hvordan vores side bliver brugt.",
        "cookies.banner.text.readMore": "Du kan læse mere omkring vores cookie politik ",
        "cookies.banner.text.readMore.here": "her",
        "cookies.banner.buttons.reject": "Afvis",
        "cookies.banner.buttons.accept": "Accepter",
        "cookies.overlay.title": "Cookie politik",
        "cookies.overlay.question.whatAreCookies": "Hvad er cookies?",
        "cookies.overlay.question.ourReasoning": "Hvad bruger vi cookies til?",
        "cookies.overlay.question.storageDuration": "Hvor lang tid gemmer vi cookies?",
        "cookies.overlay.question.deleting": "Hvordan sletter man cookies?",
        "cookies.overlay.question.consentChange": "Hvordan ændre jeg mit samtykke?",
        "cookies.overlay.answer.whatAreCookies": "Cookies er små filer med informationer, som der bliver gemt på den enhed du bruger til at browse med. Det er ikke programmer, som kan indeholder malware eller vira.",
        "cookies.overlay.answer.ourReasoning": "Vi bruger cookies for at indsamle information omkring forbrug af siden. Uden disse cookies er vi ikke i stand til at se hvordan vores side bliver brugt. Du gør derfor os en stor tjeneste ved at give samtykke til cookies.",
        "cookies.overlay.answer.storageDuration": "Det varier fra side til side og udnyttelsen af cookie'en. Nogle cookies bliver gemt indtil du forlader siden, andre bliver gemt i længere perioder. Alle vores cookies bliver gemt i et år.",
        "cookies.overlay.answer.deleting": "Det er forskelligt fra browser til browser. Her er dog en liste af manualer til de mest populære browsere.",
        "cookies.overlay.answer.consentChange": "På nuværende tidspunkt har du endnu ikke givet samtykke, men når du gør kan du blot slette din cookie, hvis du ønsker at trække samtykke tilbage eller på andenvis ombestemmer dig.",
        "cookies.overlay.table.title": "Cookies som vi bruger",
        "cookies.overlay.table.header.description": "Beskrivelse",
        "cookies.overlay.table.row.ai_user.description": "Brugt af Microsoft Application Insights til at indsamle statistisk data omkring forbrug. Den gemmer derudover et unik identifikationsnummer, som bruges til at genkende dig næste gang du amkommer på siden. Den gemmer ikke noget personligt omkring dig, og genererer et identifikationsnummer tilfældigt.",
        "cookies.overlay.table.row.ai_session.description": "Gemmer dit nuværende besøg, så vi kan genkende dig på tværs af vores sider.",
        "cookies.overlay.table.row.cookie_consent.description": "Gemmer dine samtykkevalg af vores cookie politik.",
        // COMPATIBILITY WARNING
        "popup.compatibility.bluetooth.header": "Browser ikke understøttet!",
        "popup.compatibility.bluetooth.explain": "Din nuværende browser understøtter ikke bluetooth. Bluetooth bruges til at drive siden. Uden det virker den ikke.",
        "popup.compatibility.bluetooth.advice": "Sikre at din browser er opdateret. Ellers kan du vælge en af de nedestående browsere, som understøtter bluetooth.",
        "popup.connectMB.USBCompatibility.header": "USB ikke understøttet!",
        "popup.connectMB.USBCompatibility.subtitle": "Overfør programmet til micro:bitten manuelt",
        "popup.connectMB.USBCompatibility.continue": "fortsæt",
        "popup.connectMB.USBCompatibility.information.title": "Understøttede browsere",
        "popup.connectMB.USBCompatibility.information.note": "NB: Specifikke browsere versioner virker ikke. Chrome v105 er kendt ikke at virke på Chromebooks / ChromeOS",
        "popup.connectMB.USBCompatibility.versionStep.transferManually": "Din browser understøtter ikke USB! Du bliver nødt til at hente og overføre firmware selv.",
        "popup.connectMB.USBCompatibility.versionStep.return.p1": "Klik ",
        "popup.connectMB.USBCompatibility.versionStep.return.p2": "her",
        "popup.connectMB.USBCompatibility.versionStep.return.p3": " for at gå tilbage, hvis du allerede har gjort det..",
        "popup.connectMB.USBCompatibility.versionStep.microbitVersion": "Ønsker du at hente og overføre firmware manuelt, skal du vælge hvilken version af micro:bit du vil bruge.",
        "popup.connectMB.USBCompatibility.versionStep.microbitVersion.advice": "Du kan læse version nummeret på bagsiden, ellers kan du se på indhak i bunden. Har den disse indhak, vil det være en version 2 micro:bit.",
        "popup.connectMB.USBCompatibility.connectStep.useCable": "Brug et USB kabel, som passer til micro:bitten til at tilslutte din micro:bit til computeren.",
        "popup.connectMB.USBCompatibility.downloadStep.clickDownload": "Tryk download for at hente firmwaren.",
        "popup.connectMB.USBCompatibility.downloadStep.clickContinue": "Tryk fortsæt når du har hentet firmwaren.",
        "popup.connectMB.USBCompatibility.transferStep.step1": "Åben placering af den firmware fil du lige har downloadet. Den findes oftest i din download mappe.",
        "popup.connectMB.USBCompatibility.transferStep.step2": "Træk filen over i micro:bitten gennem din computers stifinder.",
        "popup.connectMB.USBCompatibility.transferStep.step3": "Når overførslen er færdig, kan du tilslutte din micro:bit med Bluetooth.",
        "popup.connectMB.USBCompatibility.transferStep.finish": "Færdig",
        "popup.connectMB.USBCompatibility.flashFail.header": "Overførsel fejlede",
        "popup.connectMB.USBCompatibility.flashFail.transferText": "Der gik noget galt med overførslen. Overfør venligst programmet til din micro:bit manuelt."
    },
    en: {
        // ALERTS:
        //In micro.js
        "alert.micro.inputDetached": "Input Micro:bit disconnected",
        "alert.micro.isConnectedInform": "If you experience issues: Disconnect first",
        "alert.micro.isConnectedAlert": "You are already connected!",
        "alert.micro.noBluetoothAlert": "You browser does not support Bluetooth...",
        "alert.micro.GATTserverInform": "Connecting to GATT server...",
        "alert.micro.microBitServiceInform": "Connecting to Micro:bit services...",
        "alert.micro.gettingDataInform": "Getting data",
        "alert.micro.nowConnectedInform": "Micro:bit is now connected",
        //In gesture.svelte
        "alert.data.classNameLengthAlert": "Names must consists of maximum 25 characters.",
        //In input.svelte
        "alert.data.error.deleting": "Your gestures were not deleted.",
        //In output.js
        "alert.output.outputDetached": "Output Micro:bit disconnected",
        "alert.output.isConnectedInform": "If you experience issues: Disconnect first!",
        "alert.output.isConnectedAlert": "You are already connected!",
        "alert.output.noBluetoothAlert": "You browser does not support Bluetooth...",
        "alert.output.GATTserverInform": "Connecting to GATT server...",
        "alert.output.microBitServiceInform": "Connecting to Micro:bit services...",
        "alert.output.connectingToComponents": "Establishing connections to separate components...",
        "alert.output.nowConnectedInform": "Micro:bit is connected",
        "alert.output.isDisconnectedInform": "No Micro:bit is connected",
        //In common.js
        "alert.isRecording": "You are currently recording!",
        "alert.isTesting": "You are currently recording!",
        "alert.isTraining": "You are currently training a model!",
        "alert.isNotConnected": "Your Micro:bit should be connected!",
        "alert.localDataInform": "There was an error in the local data and they have been deleted :)",
        "alert.localData.Alert": "Something failed when reading local files",
        "alert.clearGesturesConfirm": "Are you sure you want to delete your examples?",
        "alert.clearGesturesAlert": "Did not delete examples",
        "placeholder.data.inactive": "Inactive",
        "placeholder.data.circle": "Circle",
        //In filemanagement.ts
        "alert.confirmOverwirte": "Do you want to overwrite current examples?",
        "alert.interruptUpload": "Interrupting uploading of file",
        "alert.wrongFilmeFormat": "The files is not in the correct file format",
        "alert.updateGestures": "Examples have been updated",
        "alert.noGesturesToSave": "No examples to save",
        "alert.savingProject": "Saving your project",
        //In ml.ts
        "alert.beginModelSetup": "Initiating model setup",
        "alert.trainingModel": "Training the model",
        "alert.twoGestures": "You need at least two classes",
        "alert.oneDataRepresentation": "You need at least one data representation",
        "alert.recordingsPerGesture": "You need at least three examples per class",
        // HOME PAGE:
        "content.index.heading": "Do It Yourself Machine Learning",
        "content.index.howHeading": "How It Works",
        "content.index.howBody": "This website enables interaction with a Machine Learning (ML) algorithm, by allowing the user to record his own gestures using the Micro:Bit's accelerometer and bluetooth. A ML model is then trained to recognize these gestures. In addition, a second Micro:bit can be connected, which can then be set up to respond to these gestures, when they are recognized.",
        "content.index.setupHeading": "Now Supporting micro:bit V1",
        "content.index.setupBody1": "ml-machine.org now supports micro:bit version 1! The two versions are identical except that sounds will play from the computer with v1 while they play from the micro:bit with v2 (there is a built-in speaker in v2). Connect your micro:bit with USB and click 'Connect' to begin.",
        "content.index.setupBody2": "The website utilizes the bluetooth, accelerometer, LEDs, buttons, and sounds from the Micro:bit. Subsequetnly, you can connect the micro:bit to your computer via bluetooth without using the USB cable.",
        "content.index.v1": "V1",
        "content.index.v2": "V2",
        "content.index.contactHeading": "Contact",
        "content.index.contactBody": "If you find errors and omissions on this platform, please feel free to write us. If you have any other requests, ideas or general interests, please feel free to contact us. The creators of this platform can be contacted at keb@cs.au.dk.",
        "content.index.byline": "Created by CEED, Aarhus University",
        // DATA PAGE:
        "content.data.header.delete": "Delete examples",
        "content.data.header.save": "Save examples",
        "content.data.header.upload": "Upload examples",
        "content.data.header.shortcutHeading": "Recording Shortcut:",
        "content.data.header.shortcutHelpBody": "This shortcut allows you to activate the recording process by pressing buttons A or B. First you select the class you want to record to, and then you can easily record examples by pressing buttons on your micro:bit",
        "content.data.header.shortcutOptionNone": "None",
        "content.data.header.shortcutOptionA": "Button A",
        "content.data.header.shortcutOptionB": "Button B",
        "content.data.header.shortcutOptionAB": "Button A & B",
        "content.data.classPlaceholderNewClass": "Press here to change name",
        "content.data.select": "Select",
        "content.data.selected": "Selected",
        "content.data.record": "Record",
        "content.data.delete": "Delete",
        "content.data.classHelpHeader": "Class",
        "content.data.classHelpBody": "A class describes a type of gesture. By recording examples of a certain type of gesture and putting the examples in the same class, the trainer can 'train' a model that can recognize this class of gestures. Multiple examples will typically result in a better model, and consider how many different ways a gesture can be performed.",
        // TRAINER PAGE:
        "content.trainer.noModel": "No model",
        "content.trainer.filters.recordExamplesFirstHeading": "Record some examples first!",
        "content.trainer.filters.recordExamplesFirstBody": "Go to the data page",
        "content.trainer.filters.header.recordExampleButton": "Record example",
        "content.trainer.filters.header.focus": "Focus on:",
        "content.trainer.filters.max.heading": "Max values",
        "content.trainer.filter.max.helpBody": "The tallest point among all datapoints in a gesture.",
        "content.trainer.filters.min.heading": "Minimum values",
        "content.trainer.filters.min.helpBody": "The lowest point among all datapoints in a gesture.",
        "content.trainer.filters.standardDev.heading": "Standard deviation",
        "content.trainer.filter.standardDev.helpBody": "The average deviation from 0 among all datapoints in a gesture.",
        "content.trainer.filter.extremes.heading": "Number of extremes",
        "content.trainer.filter.extremes.helpBody": "The number of extremes among all datapoints in a gesture.",
        "content.trainer.filter.acc.heading": "Total acceleration",
        "content.trainer.filter.acc.helpBody": "The sum of acceleration for the whole gesture, calculated in absolute values, so that minus counts as plus. Good for understanding the amount of movement.",
        "content.trainer.filter.recordExampleButton": "Record example",
        "content.trainer.filter.recordingHeading": "Recording",
        "content.trainer.testcenter.header.deleteTestsButton": "Delete all tests",
        "content.trainer.testcenter.header.recordingShortcut": "Recording shortcut:",
        "content.trainer.testcenter.header.recordingShortcut.helpHeading": "Recording shortcut",
        "content.trainer.testcenter.header.recordingShortcut.helpBody": "With this shortcut you can record by pressing the A or B buttons. First, you select the class you want to record examples for, and then you can record gestures by pressing the button on your Micro:bit",
        "content.trainer.testcenter.header.recordingOptionNone": "None",
        "content.trainer.testcenter.header.recordingOptionA": "Button A",
        "content.trainer.testcenter.header.recordingOptionAB": "Button A & B",
        "content.trainer.testcenter.recordFirst": "Create some classes first!",
        "content.trainer.testcenter.GoToDataPage": "Go to the Data Page",
        "content.trainer.testcenter.testOf": "Test of",
        "content.trainer.testcenter.chooseButton": "Select",
        "content.trainer.testcenter.chosenButton": "Selected",
        "content.trainer.testcenter.recordButton": "Record",
        "content.trainer.testcenter.helpHeading": "Test-ometer",
        "content.trainer.testcenter.helpBody": "Here you can do tests for your different classes. For each test you record, the box under the name will be split into parts. Each part represents one of your tests. When you train your model, these divisions will light up in green if the model guesses right and red if it guesses wrong. You can use this to investigate which movements your model has difficulty recognizing.",
        "content.trainer.testCenter.alert.deleteTests": "Are you sure you want to delete your tests?",
        "content.trainer.testCenter.alert.deleteTests.failed": "Something went wrong. Your tests were not deleted.",
        "content.trainer.testCenter.alert.recordingFinished": "Recording finished.",
        "content.trainer.testCenter.alert.disconnectDuringRecording": "The micro:bit was disconnected during recording",
        // MODEL PAGE:
        "content.model.trainModelFirstHeading": "Train the model first!",
        "content.model.trainModelFirstBody": "Go to the 'Trainer' page",
        "content.model.header.automaticOn": "Automatic on",
        "content.model.header.automaticOff": "Automatic off!",
        "content.model.header.automatic.helpHeading": "Classification",
        "content.model.header.automatic.helpBody": "When the model guesses what gesture the Micro:bit is moving in, we say that it's making a classification. Here you can specify when your system should make a classification. Automatic means it constantly classifies. If you turn it off, it only makes a classification when you press button A or B.",
        "content.model.classification.helpHeading": "Probabil-ometer",
        "content.model.classification.helpBody": "For each classification you make, the model will produce a percentage that describes how confident the model is in its prediction. This meter goes from 0% to 100%. With the slider next to it, you can determine the limit of how certain the model should be in its classification to make an output",
        "content.model.output.helpHeading": "Set Output",
        "content.model.output.helpBody": "Here you can choose what output the system should have. You can set the LED display on the Micro:Bit, make it play a sound, or turn on a pin. This signal will be sent to your output Micro:Bit if the model makes a guess for this class.",
        "content.model.output.affect": "Affect",
        "content.model.output.optionPin": "Pin",
        "content.model.output.optionSound": "Sound",
        "content.model.output.pinOn": "Turn on pin",
        "content.model.output.playSound": "Play",
        "content.model.output.soundOptionCongratulations": "Congratulations",
        "content.model.output.soundOptionHighPitchBeep": "High pitch beep",
        "content.model.output.soundOptionLowPitchBeep": "Low pitch beep",
        "content.model.output.soundOptionLoser": "Loser",
        "content.model.output.soundOptionMistake": "Mistake",
        "content.model.output.soundOptionHugeMistake": "Huge Mistake",
        // FOOTER:
        "footer.connectButtonNotConnected": "Connect",
        "footer.connectButtonConnected": "Connected",
        "footer.connectButtonConnecting": "Connecting",
        "footer.disconnectButton": "Disconnect",
        "footer.heading": "CONNECT YOUR MICRO:BIT",
        "footer.helpHeader": "Live graph",
        "footer.helpContent": "Once you have connected a Micro:Bit you can watch the accelerometer data for all three axes on this graph in real time. Try moving your connected Micro:bit to see what the data looks like to your computer!",
        //DATA MENU:
        "menu.data.helpHeading": "Data",
        "menu.data.helpBody": "In order to train a model to recognize different movements, we need good examples of 'good behavior' that we can show the Trainer. Here you can create classes (types of gestures) and record examples for each class. There must be at least 2 classes with 3 examples each before the trainer can train a model.",
        "menu.data.examples": "examples",
        // TRAINER MENU:
        "menu.trainer.helpHeading": "Trainer",
        "menu.trainer.helpBody": "The Trainer looks at the examples in each of the classes and tries to 'learn' how the different classes can be recognized. Here you can train a model to recognize different gestures. In addition, you can configure the trainer, choose how the trainer should interpret the data and create test sets.",
        "menu.trainer.filters": "Filters",
        "menu.trainer.filters.helpHeading": "Filters",
        "menu.trainer.filters.isRecording": "Recording",
        "menu.trainer.filters.recording": "Recording",
        "menu.trainer.filters.finishedRecording": "Finished recording",
        "menu.trainer.filters.helpBody": "Here we decide how the Trainer will view our data (our examples). We do this by putting different filters over the raw examples. Since each recording consists of 50 data points with three coordinates, the amount of data will quickly become large.",
        "menu.trainer.filters.maxButton": "Maximum",
        "menu.trainer.filters.minButton": "Minimum",
        "menu.trainer.filters.deviationButton": "Deviation",
        "menu.trainer.filters.extremesButton": "Extremes",
        "menu.trainer.filters.accButton": "Acceleration",
        "menu.trainer.testCenter": "Test Center",
        "menu.trainer.testCenter.helpHeading": "Test Center",
        "menu.trainer.testCenter.helpBody": "Here you can make a test set that you can use to test your model. Once you have trained a model, all your recorded tests are run through the model and you can see if the model can detect the gestures in your test set correctly. The parameters in the menu are settings for the Trainer. Learning rate describes how large changes the Trainer makes in its 'understanding of the world' each time it looks at a new example, and the number of epochs says something about how many times the Trainer has to look at each example.",
        "menu.trainer.testCenter.learningRateSlider": "Learning Rate",
        "menu.trainer.testCenter.epochsSlider": "Epochs",
        "menu.trainer.trainModelButton": "Train model",
        "menu.trainer.trainNewModelButton": "Train new model",
        "menu.trainer.isTrainingModelButton": "Training model",
        "menu.trainer.trainModelButton.helpHeading": "Train your model",
        "menu.trainer.trainModelButton.helpBody": "Here you can train your model. The Trainer takes all your examples and 'trains' a model. This model can then be used to guess which gesture your Micro:bit is doing.",
        // MODEL MENU:
        "menu.model.helpHeading": "Model",
        "menu.model.helpBody": "The model can be used in an interactive system. Here we use the trained model to guess gestures. You can simultaneously connect another Micro:Bit and make it respond to the gestures you make.",
        "menu.model.noModel": "No model",
        "menu.model.connectOutputButton": "Connect",
        "menu.model.connectingOutputButton": "Connecting",
        "menu.model.connectedOutputButton": "Connected",
        "menu.model.disconnect": "Disconnect",
        "menu.model.helpConnectOutputButtonHeading": "Make an output",
        "menu.model.helpConnectOutputButtonBody": "Here you can connect another Micro: Bit to make your model do things in the real world. However, start by training a model before connecting this Micro:Bit.",
        //CONNECT MICROBIT POP UP:
        "popup.connectMB.alert.failToReadService": "Could not read accelerometer! Please try again.",
        "popup.connectMB.main.heading": "Connecting to your micro:bit",
        "popup.connectMB.main.usb.subtitle": "Connect using USB",
        "popup.connectMB.main.usb.step1": "Plug your micro:bit into your computer, using a USB cable.",
        "popup.connectMB.main.usb.step2": "Press the 'Find USB device' button below.",
        "popup.connectMB.main.usb.step3": "Select your micro:bit, and press the 'connect' button.",
        "popup.connectMB.main.usb.connect": "Find USB device",
        "popup.connectMB.main.bluetooth.subtitle": "Connect using Bluetooth",
        "popup.connectMB.main.bluetooth.step1": "Plug a battery pack into your micro:bit.",
        "popup.connectMB.main.bluetooth.step2": "Press the 'Connect with Bluetooth' button below.",
        "popup.connectMB.main.bluetooth.connect": "Connect with Bluetooth",
        "popup.connectMB.main.alreadyConnected.heading": "A micro:bit is already connected",
        "popup.connectMB.main.alreadyConnected.mainText": "We have found a micro:bit, that is already connected. Do you wish to use the same, or choose another micro:bit?",
        "popup.connectMB.main.alreadyConnected.useSameButton": "Use same",
        "popup.connectMB.main.alreadyConnected.useOtherButton": "Use other",
        "popup.connectMB.usb.heading": "Connect using USB",
        "popup.connectMB.usb.MBFound": "found!",
        "popup.connectMB.usb.transferring.pleaseWait": "Please wait while we transfer the program onto the micro:bit.",
        "popup.connectMB.usb.transferFinished.pleaseDisconnect": "You can now disconnect the micro:bit from your computer, and plug a battery pack into the micro:bit.",
        "popup.connectMB.usb.transferFinished.finishedProgress": "Finished!",
        "popup.connectMB.usb.transferFinished.transferComplete": "The program has been transferred to the micro:bit.",
        "popup.connectMB.usb.transferFinishedAndDisconnected.plugInBattery": "Micro:bit disconnected. You may now plug in a battery pack into the micro:bit.",
        "popup.connectMB.usb.transferFinishedAndDisconnected.pleaseConnect": "Press 'Connect' below, once you are ready to continue.",
        "popup.connectMB.usb.connect": "Connect",
        "popup.connectMB.usb.haveNoBatteryQuestion": "Have no battery pack?",
        "popup.connectMB.usb.continueWithoutBattery": "To continue without a battery pack, click",
        "popup.connectMB.usb.continueWithoutBatteryButton": "here.",
        "popup.connectMB.bluetooth.heading": "Connect using Bluetooth",
        "popup.connectMB.bluetooth.subtitle": "How to connect",
        "popup.connectMB.bluetooth.step1": "Draw the pattern as displayed on the micro:bit",
        "popup.connectMB.bluetooth.step2": "Press the 'Connect' button below.",
        "popup.connectMB.bluetooth.step3": "Select your micro:bit and press 'Connect'.",
        "popup.connectMB.bluetooth.wrongPattern": "Wrong pattern!",
        "popup.connectMB.bluetooth.connect": "Connect",
        // COOKIE BANNER
        "cookies.banner.title": "Cookie policy",
        "cookies.banner.subtitle": "Performance & analytics",
        "cookies.banner.text.pleaseHelp": "Please help us make it better by keeping the cookies enabled.",
        "cookies.banner.text.description": "Here at ML-Machine we use cookies to collect information on the usage and performance of the website. Without these cookies, we will not be able to collect vital information on the performance of the site.",
        "cookies.banner.text.readMore": "You can read more about it ",
        "cookies.banner.text.readMore.here": "here",
        "cookies.banner.buttons.reject": "Reject",
        "cookies.banner.buttons.accept": "accept",
        "cookies.overlay.title": "Cookie policy",
        "cookies.overlay.question.whatAreCookies": "What are cookies?",
        "cookies.overlay.question.ourReasoning": "What do we use cookies for?",
        "cookies.overlay.question.storageDuration": "For how long are cookies stored?",
        "cookies.overlay.question.deleting": "How do i delete cookies?",
        "cookies.overlay.question.consentChange": "How do i change my consent?",
        "cookies.overlay.answer.whatAreCookies": "Cookies are small data files stored on the device you are using to browse websites. They are not programs, that contain harmful malware or viruses.",
        "cookies.overlay.answer.ourReasoning": "We use cookies to collect information on the usage and performance of the website. Without these cookies, we will not be able to collect vital information on the performance of the site.",
        "cookies.overlay.answer.storageDuration": "It varies from site to site and the usage of the cookie. Some may be stored for only the current session, while others may be stored for days. Our cookies are stored for one year.",
        "cookies.overlay.answer.deleting": "It varies from browser to browser. However here are some of the manuals for some of the most commonly used browsers.",
        "cookies.overlay.answer.consentChange": "Currently, you have not rejected nor agreed to our cookie policy. But once you do, you will be able to delete cookies to change your consent at any time.",
        "cookies.overlay.table.title": "The cookies we use",
        "cookies.overlay.table.header.description": "Description",
        "cookies.overlay.table.row.ai_user.description": "Used by Microsoft Application Insights software to collect statistical usage and telemetry information. The cookie stores a unique identifier to recognise users on returning visits over time.",
        "cookies.overlay.table.row.ai_session.description": "Preserves users states across page requests.",
        "cookies.overlay.table.row.cookie_consent.description": "Stores the terms to which you have given consent to in regards to our cookie policy.",
        // COMPATIBILITY WARNING
        "popup.compatibility.bluetooth.header": "Bluetooth incompatible browser!",
        "popup.compatibility.bluetooth.explain": "The browser you are currently using does not support bluetooth.",
        "popup.compatibility.bluetooth.advice": "Please update the browser or use another one from our supported browsers list below.",
        "popup.connectMB.USBCompatibility.header": "USB incompatible browser!",
        "popup.connectMB.USBCompatibility.subtitle": "Transfer the program to the micro:bit manually",
        "popup.connectMB.USBCompatibility.continue": "Continue",
        "popup.connectMB.USBCompatibility.information.title": "Compatible browsers",
        "popup.connectMB.USBCompatibility.information.note": "Note: Certain browser versions do not work. Chrome v105 is known not to work on Chromebooks/ChromeOS.",
        "popup.connectMB.USBCompatibility.versionStep.transferManually": "Your browser does not support USB! You need to download and transfer the micro:bit firmware manually.",
        "popup.connectMB.USBCompatibility.versionStep.return.p1": "Click ",
        "popup.connectMB.USBCompatibility.versionStep.return.p2": "here",
        "popup.connectMB.USBCompatibility.versionStep.return.p3": " to return, if you have already done so.",
        "popup.connectMB.USBCompatibility.versionStep.microbitVersion": "If you wish to download and transfer the firmware manually, which version of micro:bit you will be using?",
        "popup.connectMB.USBCompatibility.versionStep.microbitVersion.advice": "You can tell which on the backside, or by the serrations by the pins. If it has serrations it will be a v2 micro:bit",
        "popup.connectMB.USBCompatibility.connectStep.useCable": "Use a USB cable that fits the micro:bit and plug it into your computer.",
        "popup.connectMB.USBCompatibility.downloadStep.clickDownload": "Click and download the micro:bit firmware.",
        "popup.connectMB.USBCompatibility.downloadStep.clickContinue": "Click continue when the firmware has been downloaded.",
        "popup.connectMB.USBCompatibility.transferStep.step1": "Open the location to which the firmware was downloaded. Most commonly found in your download folder.",
        "popup.connectMB.USBCompatibility.transferStep.step2": "Drag the file onto the micro:bit on your computer's file explorer.",
        "popup.connectMB.USBCompatibility.transferStep.step3": "Once the file has finished transferring, the micro:bit can be connected using Bluetooth.",
        "popup.connectMB.USBCompatibility.transferStep.finish": "Finish",
        "popup.connectMB.USBCompatibility.flashFail.header": "Flash failed",
        "popup.connectMB.USBCompatibility.flashFail.transferText": "Something went wrong during the transfer process. Please transfer the firmware manually."
    }
};
