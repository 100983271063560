import { derived, writable } from "svelte/store";
import translations from "./translations";
const lang = determineInitialLang();
window.localStorage.setItem("lang", lang);
export const locale = writable(lang);
locale.subscribe(l => window.localStorage.setItem("lang", l));
export const locales = Object.keys(translations);
function translate(locale, key, vars) {
    // Let's throw some errors if we're trying to use keys/locales that don't exist.
    // We could improve this by using Typescript and/or fallback values.
    if (!key)
        throw new Error("no key provided to $t()");
    if (!locale)
        throw new Error(`no translation for key "${key}"`);
    // Grab the translation from the translations object.
    let text = translations[locale][key];
    if (!text)
        throw new Error(`no translation found for ${locale}.${key}`);
    // Replace any passed in variables in the translation string.
    Object.keys(vars).map((k) => {
        const regex = new RegExp(`{{${k}}}`, "g");
        text = text.replace(regex, vars[k]);
    });
    return text;
}
export const t = derived(locale, ($locale) => (key, vars = {}) => translate($locale, key, vars));
function determineInitialLang() {
    const savedLang = window.localStorage.getItem("lang");
    if (savedLang)
        return savedLang;
    const urlParams = new URLSearchParams(window.location.search);
    const urlLang = urlParams.get("lang");
    if (urlLang === "da" || urlLang === "en")
        return urlLang;
    return "da";
}
