import { get, writable } from "svelte/store";
import { t } from "../i18n";
import CompatibilityChecker from "./compatibility/CompatibilityChecker";
let text;
t.subscribe(t => text = t);
export const livedata = writable({});
export const prediction = writable([]);
export const hexFiles = {
    1: "firmware/ml-microbit-cpp-version-combined.hex",
    2: "firmware/MICROBIT.hex"
};
const initialSettings = {
    duration: 1200,
    numEpochs: 80,
    numSamples: 50,
    minSamples: 40,
    updatesPrSecond: 4,
    learningRate: 0.5,
    includedAxes: [true, true, true],
    includedParameters: [true, true, true, true, true],
    gestureChosen: undefined,
    preferableButton: "AB",
    automaticClassification: true,
    // classificationTrigger: 'auto',
    output: true
    //language: "en"
};
// Store with ML-Algorithm settings
export const settings = writable(initialSettings);
// Assess whether parameters for ml algorithm are legal
export function isParametersLegal() {
    const s = get(settings);
    return s.includedAxes.reduce((sum, x) => sum || x) && s.includedParameters.reduce((sum, x) => sum || x);
}
export var DeviceRequestStates;
(function (DeviceRequestStates) {
    DeviceRequestStates[DeviceRequestStates["NONE"] = 0] = "NONE";
    DeviceRequestStates[DeviceRequestStates["INPUT"] = 1] = "INPUT";
    DeviceRequestStates[DeviceRequestStates["OUTPUT"] = 2] = "OUTPUT";
})(DeviceRequestStates || (DeviceRequestStates = {}));
export const compatibility = writable(CompatibilityChecker.checkCompatibility());
// Store current state to prevent error prone actions
export const state = writable({
    isRequestingDevice: DeviceRequestStates.NONE,
    isFlashingDevice: false,
    isTesting: false,
    isRecording: false,
    isTraining: false,
    isConnected: false,
    isPredicting: false,
    isOutputting: false
});
// Store for bt device names along with method to call once name has been entered
export const btConnection = writable({
    inputMb: undefined,
    outputMb: undefined,
    outputName: "",
    inputName: "",
    outputVersion: 0
});
// Store for training state. Used to radiate current epoch state.
export const trainingState = writable({
    percentage: 0,
    loss: 0,
    epochs: 0
});
export const buttonPressed = writable({ buttonA: 0, buttonB: 0 });
// Assess whether an action is allowed. Alert user if not
export function notReady(bool = true) {
    const status = assessStateStatus(bool);
    if (status) {
        alertUser(status);
        return true;
    }
    return false;
}
// Assess status and return message to alert user.
function assessStateStatus(bool = true) {
    const currentState = get(state);
    if (currentState.isRecording)
        return text("alert.isRecording");
    if (currentState.isTesting)
        return text("alert.isTesting");
    if (currentState.isTraining)
        return text("alert.isTraining");
    if (!currentState.isConnected && bool)
        return text("alert.isNotConnected");
    return false;
}
// Outputbluetooth
export const outputBluetooth = writable(undefined);
const dataExample = [
    {
        name: text("placeholder.data.inactive"),
        ID: 1637677921852,
        recordings: [],
        output: {}
    },
    {
        name: text("placeholder.data.circle"),
        ID: 1637677924329,
        recordings: [],
        output: {}
    }
];
// Store for current gestures
export const gestures = writable([]);
// export const gestures = writable(JSON.parse(dataExample).data);
export const loadGesturesLocally = () => {
    const information = window.localStorage.getItem("project");
    let parsedInformation = [];
    if (!information) {
        parsedInformation = dataExample;
    }
    else {
        parsedInformation = parseAndAssessGestures(information);
    }
    if (parsedInformation === false) {
        informUser(text("alert.localDataInform"));
        alertUser(text("alert.localData.Alert"));
        window.localStorage.removeItem("project");
        parsedInformation = dataExample;
    }
    // Remove confidence-level
    parsedInformation.forEach((g) => {
        g.confidence = undefined;
    });
    gestures.set(parsedInformation);
};
function parseAndAssessGestures(information) {
    var _a, _b;
    let parsedInformation = {};
    // Try parsing
    try {
        parsedInformation = JSON.parse(information);
    }
    catch (error) {
        // Error handle. Remove faulty data.
        console.error(error);
        return false;
    }
    // Assess whether gestures are faulty
    if (typeof parsedInformation !== "object" || ((_a = parsedInformation === null || parsedInformation === void 0 ? void 0 : parsedInformation.gestures) === null || _a === void 0 ? void 0 : _a.forEach) === undefined) {
        return false;
    }
    let shouldReturn = false;
    parsedInformation.gestures.forEach((g) => {
        if (typeof g !== "object" ||
            !g.name ||
            (g.data &&
                (typeof g.data !== "object" ||
                    g.data.length === undefined)))
            shouldReturn = true;
    });
    if (shouldReturn) {
        return false;
    }
    if ((_b = parsedInformation === null || parsedInformation === void 0 ? void 0 : parsedInformation.settings) === null || _b === void 0 ? void 0 : _b.minSamples) { // Checks if user have the old version of the settings without minSamples
        settings.set(parsedInformation.settings);
    }
    return parsedInformation.gestures;
}
const storeGestureDelayer = writable(undefined);
export const storeGesturesLocally = (information, extra) => {
    const prevTimeout = get(storeGestureDelayer);
    if (prevTimeout)
        clearTimeout(prevTimeout);
    const timeout = setTimeout(saveGesturesToLocalStore, 2000); // Delay 2 sek to avoid spam.
    storeGestureDelayer.set(timeout);
};
function saveGesturesToLocalStore() {
    const local = {
        gestures: get(gestures),
        settings: get(settings)
    };
    window.localStorage.setItem("project", JSON.stringify(local));
    storeGestureDelayer.set(undefined);
}
export const clearGestures = () => {
    if (notReady(false))
        return;
    if (!window.confirm(text("alert.clearGesturesConfirm"))) {
        alertUser(text("alert.clearGesturesAlert"));
        return;
    }
    // Remove both localstore and storeGestureDelayer
    settings.set(initialSettings);
    gestures.set([]);
    window.localStorage.removeItem("project");
    storeGestureDelayer.set(undefined);
};
// Store for components to assess model status
export const model = writable(undefined);
// Stores and manages previous 120 data-elements. Used for classifying current gesture
export const prevData = writable([50]);
let liveDataIndex = 0;
livedata.subscribe(data => {
    prevData.update((arr) => {
        arr[liveDataIndex] = data;
        return arr;
    });
    liveDataIndex++;
    if (liveDataIndex >= get(settings).numSamples)
        liveDataIndex = 0;
});
export function getPrevData() {
    const data = get(prevData);
    const x = [];
    const y = [];
    const z = [];
    for (let i = liveDataIndex; i < data.length; i++) {
        x.push(data[i].accelX);
        y.push(data[i].accelY);
        z.push(data[i].accelZ);
    }
    for (let i = 0; i < liveDataIndex; i++) {
        x.push(data[i].accelX);
        y.push(data[i].accelY);
        z.push(data[i].accelZ);
    }
    return { x, y, z };
}
// Message store to propagate allow all components to inform users.
export const message = writable({
    warning: false,
    text: ""
});
// Message store to propagate allow all components to inform users.
export const outputting = writable({ text: "" });
// Inform user sets current message to text
export function informUser(text) {
    message.set({
        warning: false,
        text
    });
}
// Alert user sets current message to text and hightlights it.
export function alertUser(text) {
    message.set({
        warning: true,
        text
    });
}
export const lossGraphStore = writable(undefined);
export const classificationStore = writable({ lastRecording: undefined, recordingTime: undefined });
