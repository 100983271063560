import modalManager from "./modal";
import BluetoothIncompatibilityWarning from "../../components/BluetoothIncompatibilityWarning.svelte";
export function openBluetoothIncompatibilityWarning() {
    // Open Modal
    modalManager.openModal({
        component: BluetoothIncompatibilityWarning,
        onClose
    });
}
function onClose() {
}
