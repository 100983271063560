<script lang="ts">
	import { state } from "../../script/common";
</script>

<!-- Show a model logo when the model is predicting -->
{#if $state.isPredicting}
	<div
		class="w-full items-center mb-2"
	>
		<img
			src="model.svg"
			alt="Model Icon"
			class="w-12 m-auto animate-spin animate-duration-[10s]"
		/>
	</div>
{/if}