<!--
  Creates a selector to use for the output menu
-->
<script lang="ts">
  export let options = undefined;
  export let chosen = undefined;
  export let title = "";
</script>

<main class="flex content-center mr-4 ml-4">
  <div class="flex-col flex text-center">
    <label for="option">{title}</label>
    <select
      bind:value={chosen}
      name="option"
      id="option"
      class="bg-gray-100 mt-2 text-sm py-1 px-2 rounded border border-gray-400"
    >
      {#each options as option}
        <option value={option}>{option.name}</option>
      {/each}
    </select>
  </div>
</main>
