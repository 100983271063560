<script>
	import { alertUser, gestures, notReady, settings } from "../script/common";
	import Controlbar from "../components/Controlbar.svelte";
	import ControlbarBox from "../components/ControlbarBox.svelte";
	import TestGesture from "../components/TestGesture.svelte";
	import { get } from "svelte/store";
	import { t } from "../i18n";
	import Information from "../components/information/Information.svelte";

	$: hidePage = ($gestures.length === 0);

	function clearTests() {
		if (notReady()) return;
		if (
			get(gestures).length > 0 &&
			!window.confirm($t("content.trainer.testCenter.alert.deleteTests"))
		) {
			alertUser($t("content.trainer.testCenter.alert.deleteTests.failed"));
			return;
		}

		gestures.update((g) => {
			g.forEach((gesture) => {
				gesture.tests = [];
			});
			return g;
		});
	}

</script>

<!-- Top Controlbar -->
<Controlbar>
	<ControlbarBox>
		<button
			class="bg-gray-100 text-sm hover:bg-gray-300 py-1 px-2 rounded btn transition ease border border-gray-400"
			on:click={clearTests}
		>
			{$t("content.trainer.testcenter.header.deleteTestsButton")}
		</button>
	</ControlbarBox>
	<ControlbarBox>
		<label class="mr-2 ml-2" for="recordActivation">{$t("content.trainer.testcenter.header.recordingShortcut")}</label>
		<select
			bind:value={$settings.preferableButton}
			class="bg-gray-100 text-sm py-1 px-2 rounded btn transition ease border border-gray-400"
			id="recordActivation"
			name="recordActivation"
		>
			<option value="none">{$t("content.trainer.testcenter.header.recordingOptionNone")}</option>
			<option value="A">{$t("content.trainer.testcenter.header.recordingOptionA")}</option>
			<option value="AB">{$t("content.trainer.testcenter.header.recordingOptionAB")}</option>
		</select>
	</ControlbarBox>
	<div class="relative float-right">
		<Information
			boxOffset={{x:-20,y:20}}
			iconOffset={{x:0,y:-12}}
			text={$t("content.trainer.testcenter.header.recordingShortcut.helpBody")}
			title={$t("content.trainer.testcenter.header.recordingShortcut.helpHeading")}
			width={20}
		/>
	</div>
</Controlbar>

<!-- In case of no gestures, advise user to record some -->
{#if hidePage}
	<div
		class="absolute z-20"
		style="width: calc(100vw - 20rem); height: calc(100vh - 12.2rem)"
	>
		<div class="flex flex-col h-100 justify-center">
			<p class="text-center font-medium text-3xl">{$t("content.trainer.testcenter.recordFirst")}</p>
			<p class="text-center">{$t("content.trainer.testcenter.GoToDataPage")}</p>
		</div>
	</div>
{/if}
<!-- All test gestures -->
<main class="mt-13 pl-4 h-full">
	<div class="p-4 overflow-auto scrollable h-full">
		{#each $gestures as gesture}
			<TestGesture bind:gesture />
		{/each}
	</div>
</main>
