/**
 * Document contains a bunch of data functions used for the ml.ts document
 * These merely support the ml-algorithm extracting data from data captured over time
 */
function average(data) {
    const sum = data.reduce(function (sum, value) {
        return sum + value;
    }, 0);
    const avg = sum / data.length;
    return avg;
}
export function standardDeviation(values) {
    const avg = average(values);
    const squareDiffs = values.map(function (value) {
        const diff = value - avg;
        const sqrDiff = diff * diff;
        return sqrDiff;
    });
    const avgSquareDiff = average(squareDiffs);
    const stdDev = Math.sqrt(avgSquareDiff);
    return stdDev;
}
export function totalAcceleration(data) {
    return data.reduce((sum, val) => sum + Math.abs(val));
}
// peak detection from https://stackoverflow.com/questions/22583391/peak-signal-detection-in-realtime-timeseries-data/57889588#57889588
function sum(a) {
    return a.reduce((acc, val) => acc + val);
}
function mean(a) {
    return sum(a) / a.length;
}
function stddev(arr) {
    const arr_mean = mean(arr);
    const r = function (acc, val) {
        return acc + (val - arr_mean) * (val - arr_mean);
    };
    return Math.sqrt(arr.reduce(r, 0.0) / arr.length);
}
export function peaks(y, params) {
    const p = params || {};
    // init cooefficients
    const lag = p.lag || 5;
    const threshold = p.threshold || 3.5;
    const influence = p.influece || 0.5;
    let peaksCounter = 0;
    if (y === undefined || y.length < lag + 2) {
        throw ` ## y data array to short(${y.length}) for given lag of ${lag}`;
    }
    // console.log(`lag, threshold, influence: ${lag}, ${threshold}, ${influence}`)
    // init variables
    const signals = Array(y.length).fill(0);
    const filteredY = y.slice(0);
    const lead_in = y.slice(0, lag);
    // console.log("1: " + lead_in.toString())
    const avgFilter = [];
    avgFilter[lag - 1] = mean(lead_in);
    const stdFilter = [];
    stdFilter[lag - 1] = stddev(lead_in);
    // console.log("2: " + stdFilter.toString())
    for (let i = lag; i < y.length; i++) {
        // console.log(`${y[i]}, ${avgFilter[i-1]}, ${threshold}, ${stdFilter[i-1]}`)
        // added in Math.abs(y[i] - avgFilter[i - 1]) > 0.1 to account for peak detection when differences are very small
        if (Math.abs(y[i] - avgFilter[i - 1]) > 0.1 &&
            Math.abs(y[i] - avgFilter[i - 1]) > threshold * stdFilter[i - 1]) {
            if (y[i] > avgFilter[i - 1]) {
                signals[i] = +1; // positive signal
                if (i - 1 > 0 && signals[i - 1] == 0) {
                    peaksCounter++;
                }
            }
            else {
                signals[i] = -1; // negative signal
            }
            // make influence lower
            filteredY[i] = influence * y[i] + (1 - influence) * filteredY[i - 1];
        }
        else {
            signals[i] = 0; // no signal
            filteredY[i] = y[i];
        }
        // adjust the filters
        const y_lag = filteredY.slice(i - lag, i);
        avgFilter[i] = mean(y_lag);
        stdFilter[i] = stddev(y_lag);
    }
    return { numPeaks: peaksCounter, results: signals };
}
