<script lang="ts">
	import InputMicrobit from "./components/bottom/InputMicrobit.svelte";
	import GestureMenu from "./menus/GestureMenu.svelte";
	import MachineMenu from "./menus/MachineMenu.svelte";
	import ModelView from "./menus/Output/ModelView.svelte";
	import OutputMenu from "./menus/Output/OutputMenu.svelte";
	import HomePage from "./pages/Homepage.svelte";
	import { onMount, SvelteComponent } from "svelte";
	import {
		compatibility,
		gestures,
		loadGesturesLocally,
		message,
		settings,
		storeGesturesLocally
	} from "./script/common";
	import { t } from "./i18n";
	import type Navigation from "./script/Navigation";
	import ConnectionBehaviours from "./script/connection-behaviours/ConnectionBehaviours";
	import InputBehaviour from "./script/connection-behaviours/InputBehaviour";
	import OutputBehaviour from "./script/connection-behaviours/OutputBehaviour";
	import Base from "./menus/Base.svelte";
	import { openBluetoothIncompatibilityWarning } from "./script/modal/bluetoothIncompatibilityWarning";
	import OverlayView from "./components/OverlayView.svelte";

	ConnectionBehaviours.setInputBehaviour(new InputBehaviour());
	ConnectionBehaviours.setOutputBehaviour(new OutputBehaviour());

	if (!$compatibility.bluetooth) openBluetoothIncompatibilityWarning();

	// Menus no the left
	let menus: Navigation.MenuProperties[];

	t.subscribe((text) => {
		menus = [
			{
				id: 1,
				name: text("menu.data.helpHeading"),
				icon: "fas fa-record-vinyl",
				desc: text("menu.data.helpBody"),
				menu: GestureMenu
			},
			{
				id: 2,
				name: text("menu.trainer.helpHeading"),
				icon: "fas fa-server",
				desc: text("menu.trainer.helpBody"),
				menu: MachineMenu
			},
			{
				id: 3,
				name: text("menu.model.helpHeading"),
				icon: "fas fa-link",
				desc: text("menu.model.helpBody"),
				special: ModelView,
				menu: OutputMenu
			}
		];
	});

	let currentMenu: Navigation.MenuProperties = null;
	let currentCanvas: typeof SvelteComponent = HomePage;

	function changeMenu(e) {
		currentMenu = e.detail.menu;
		openMenuHeight = menuDiv.offsetHeight - 285;
	}

	function changeCanvas(e) {
		currentCanvas = e.detail.canvas;
	}

	// Get height of openDiv. Depends on screen size
	let menuDiv: HTMLDivElement;
	let openMenuHeight: number;
	onMount(() => {
		openMenuHeight = menuDiv.offsetHeight - 285;
		if (300 < openMenuHeight) openMenuHeight = 300;
	});

	// Helps show error messages on top of page
	let latestMessage: string = "",
		showLatestMessage: boolean = false,
		latestMessageTimeout: NodeJS.Timeout = undefined;

	// In case of a message. Show the message at the top of screen
	// After 3 seconds. Hide the message again
	$: showMessage($message);

	function showMessage(msg): void {
		if (!msg?.warning) return;

		latestMessage = msg.text;
		showLatestMessage = true;

		if (latestMessageTimeout) {
			clearTimeout(latestMessageTimeout);
		}
		latestMessageTimeout = setTimeout(() => {
			showLatestMessage = false;
		}, 3000);
	}

	// Store gestures locally
	$: storeGesturesLocally($gestures, $settings);
	onMount(loadGesturesLocally);

	// Function for going to home page
	function goToHomePage(): void {
		currentMenu = null;
		currentCanvas = HomePage;
	}
</script>

<main class="h-screen w-screen m-0 bg-true-gray-100">
	<OverlayView /> <!-- overlay items -->

	<!-- POP UP MESSAGES FOR USERS todo: Put in OverlayView -->
	{#if showLatestMessage}
		<div class="absolute mt-2 w-full z-100 textAnimation">
			<div class="flex justify-center">
				<p
					class="text-red-600 shadow-lg text-2xl h-12 p-2 w-max bg-white rounded border border-solid border-warm-gray-400"
				>
					{latestMessage}
				</p>
			</div>
		</div>
	{/if}

	<div class="h-full flex">
		<div
			bind:this={menuDiv}
			class="h-full flex min-w-72 max-w-72 border-r border-solid border-white border-opacity-40 shadow shadow-warm-gray-800"
			style="background-image: linear-gradient(339deg, rgb(30, 58, 138), rgb(7, 77, 77));">
			<div class="flex flex-col w-full p-5 pl-6 pr-6">
				{#each menus as menu, index}
					<Base
						on:changeMenu={changeMenu}
						on:changeCanvas={changeCanvas}
						{menu}
						{currentMenu}
					/>
					{#if menus.length !== menu.id}
						<div class="text-center mb-1 mt-1">
							<i
								class="text-white text-opacity-60 fas fa-long-arrow-alt-down fa-2x"
							/>
						</div>
					{/if}
				{/each}
				<!-- Menu at the bottom of the sidebar. Allow users to go to the Home-Page -->
				<div class="relative ml-auto mr-auto mt-auto bottom-4">
					<button
						class="rounded hover:bg-white hover:bg-opacity-10 duration-100 {currentCanvas ===
            HomePage
              ? 'text-blue-400'
              : 'text-gray-100 hover:text-gray-300'} outline-transparent"
						on:click={goToHomePage}
					>
						<i class="fas fa-home text-3xl" />
					</button>
				</div>
			</div>
		</div>
		<div class="h-full overflow-x-hidden overflow-y-hidden flex flex-col bg-blue-200">
			<div class="flex-grow overflow-y-scroll bg-true-gray-100 flex-row">
				<svelte:component this={currentCanvas} />
			</div>
			<div class="flex h-40">
				<InputMicrobit />
			</div>
		</div>
	</div>
</main>

<style global windi:preflights:global windi:safelist:global>
    .textAnimation {
        animation: 3s textAni ease;
    }

    @font-face {
        font-family: ultrabit;
        src: url(/ultrabit/fonts/ultrabit.ttf) format('truetype');
    }

    .font-ultrabit {
        font-family: "ultrabit", serif;
    }

    @keyframes textAni {
        0% {
            opacity: 0;
        }
        3% {
            opacity: 1;
        }
        97% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
</style>
