<script lang="ts">
    import ModelPage from "../pages/ModelPage.svelte"
    import Test from "../pages/Test.svelte";
    import Representation from "../pages/Representation.svelte";
    import {settings, state, trainingState} from "../script/common";
    import {trainModel} from "../script/ml";
    import {get} from "svelte/store";
    import {createEventDispatcher, onMount} from "svelte";
    import {t} from "../i18n"
    import Information from "../components/information/Information.svelte";

    const dispatch = createEventDispatcher();

    // Start without canvas
    onMount(() => {
        dispatch("changeCanvas", {canvas: ModelPage});
    });

    // Names of parameters
    const parameterNames = [
        $t("menu.trainer.filters.maxButton"),
        $t("menu.trainer.filters.minButton"),
        $t("menu.trainer.filters.deviationButton"),
        $t("menu.trainer.filters.extremesButton"),
        $t("menu.trainer.filters.accButton"),
    ];

    // MachineMenu has two subpages. Ship event to change canvas (page)
    function changeCanvas(canvas) {
        dispatch("changeCanvas", {canvas});
        lastSetCanvas = canvas;
    }

    // Variable for keeping track of current page
    let lastSetCanvas;

    // Progression of training ML algorithm. Used to illustrate for the user
    $: progression = $trainingState.epochs / get(settings).numEpochs;
</script>

<main>
    <div class="flex-1 flex-grow flex-col">
        <!-- Representation Menu -->
        <div class="flex w-full flex-row justify-center">
            <button
                    on:click={(e) => {
        changeCanvas(Representation);
      }}
                    class="mt-4 {lastSetCanvas === Representation
        ? 'border-opacity-40 cursor-default h-34 bg-white bg-opacity-10'
        : 'border-dashed border border-opacity-50 bg-white hover:bg-opacity-10 bg-opacity-0 h-12'} border-2 border-white focus:outline-transparent text-sm py-1 px-2 rounded w-9/10"
            >
                <!-- InformationBoxStore -->
                <div class="relative float-right right-2">
                    <Information
                            text={$t("menu.trainer.filters.helpBody")}
                            title={$t("menu.trainer.filters.helpHeading")}
                            boxOffset={{x:-170, y:26}}
                            iconOffset={{x:-10, y:0}}
                            light
                            width={17}
                    />
                </div>
                {$t("menu.trainer.filters")}
                {#if lastSetCanvas === Representation}
                    <div class="flex flex-wrap">
                        {#each $settings.includedParameters as parameter, i}
                            {#if parameter}
                                <div
                                        class="border border-white border-opacity-30 m-1 p-1 text-xs bg-white bg-opacity-10"
                                >
                                    {parameterNames[i]}
                                </div>
                            {/if}
                        {/each}
                    </div>
                {/if}
            </button>
        </div>
        <!-- Test Menu -->
        <div class="flex w-full flex-row justify-center flex-grow">

            <button
                    on:click={(e) => {
        changeCanvas(Test);
      }}
                    class="mt-4 {lastSetCanvas === Test
        ? 'border-opacity-40 cursor-default h-34 bg-white bg-opacity-10'
        : 'border-dashed border border-opacity-50 bg-white hover:bg-opacity-10 bg-opacity-0 h-12'} border-2 border-white focus:outline-transparent text-sm py-1 px-2 rounded w-9/10"
            >
                {$t("menu.trainer.testCenter.helpHeading")}
                <div class="relative float-right right-2">
                    <Information
                            text={$t("menu.trainer.testCenter.helpBody")}
                            title={$t("menu.trainer.testCenter.helpHeading")}
                            boxOffset={{x:-170, y:26}}
                            iconOffset={{x:-10, y:0}}
                            light
                            width={22}
                    />
                </div>
                {#if lastSetCanvas === Test}
                    <div class="flex flex-col items-center">
                        <label for="learningRate" class="text-sm text-warm-gray-200"
                        >{$t("menu.trainer.testCenter.learningRateSlider")} {$settings.learningRate}</label>

                        <div class="h-6 w-35">
                            <input
                                    id="learningRate"
                                    type="range"
                                    class="w-full"
                                    min="0.01"
                                    max="1"
                                    step="0.01"
                                    bind:value={$settings.learningRate}
                                    disabled={$state.isTraining}
                            />
                        </div>

                        <label for="numOfEpochs" class="text-sm text-warm-gray-200"
                        >{$t("menu.trainer.testCenter.epochsSlider")} {$settings.numEpochs}</label
                        >

                        <div class="h-6 w-35">
                            <input
                                    id="numOfEpochs"
                                    class="w-full"
                                    type="range"
                                    min="10"
                                    max="150"
                                    step="5"
                                    bind:value={$settings.numEpochs}
                                    disabled={$state.isTraining}
                            />
                        </div>
                    </div>
                {/if}
            </button>
        </div>
    </div>

    <!-- Menu for ML controls and visual feedback-->
    <div class="flex w-full flex-col justify-center">

        <div class="text-center flex-1 flex-row mt-7">
            <div class="relative float-right right-5">
                <Information
                        text={$t("menu.trainer.trainModelButton.helpBody")}
                        title={$t("menu.trainer.trainModelButton.helpHeading")}
                        boxOffset={{x:-170, y:26}}
                        iconOffset={{x:-5, y:0}}
                        light
                        width={22}
                />
            </div>
            <button
                class="{$state.isTraining ? 'bg-blue-500' : 'bg-lime-600 animate-bounce focus:animate-none hover:bg-lime-700 '}
                focus:bg-blue-400 text-m text-light-100 shadow-lg
                py-2 px-4 rounded btn transition ease"
                on:mouseleave={(e) => {
                    const target = e.target
                    target.blur();
                }}
                on:click={(e) => {
                    trainModel();
                    e.target.blur();
                }}>
                {$state.isTraining
                    ? $t("menu.trainer.isTrainingModelButton")
                    : $state.isPredicting
                        ? $t("menu.trainer.trainNewModelButton")
                        : $t("menu.trainer.trainModelButton")}
            </button>
        </div>

        <div class="flex-1 flex-row mt-1">
            {#if $state.isTraining}
                <div
                        class="bg-red-600 h-1"
                        style="width: {progression*100}%"
                />
            {:else }
                <div
                        class="bg-red-600 h-1"
                        style="width: 0"
                />
            {/if}
        </div>

    </div>

</main>
