<script>
	import NewGesture from "../components/NewGesture.svelte";
	import Gesture from "../components/Gesture.svelte";
	import { alertUser, gestures, notReady, settings, state } from "../script/common";
	import { get } from "svelte/store";
	import Controlbar from "../components/Controlbar.svelte";
	import ControlbarBox from "../components/ControlbarBox.svelte";
	import { downloadClickEvent, uploadClickEvent } from "../script/filemanagement";
	import { t } from "../i18n";
	import Information from "../components/information/Information.svelte";

	// Method for creating a gesture.
	function createGesture(e) {
		const name = e.detail.name;
		gestures.update((arr) => {
			return [
				...arr,
				{
					name,
					ID: Date.now(),
					recordings: [],
					output: {}
				}
			];
		});
		$state.isPredicting = false;
	}

	// Method for removing a gesture.
	function removeGesture(gesture) {
		// Update current gesture array with new gesture
		gestures.update((arr) => {
			const index = arr.indexOf(gesture);
			if (index > -1) {
				arr.splice(index, 1);
			}
			return arr;
		});
		$state.isPredicting = false;
	}

	let uploadButton; // Invisible button. When clicked. Data is uploaded
	// Remove all gestures. Ask user for premission
	function clearGestures() {
		if (notReady(false)) return;
		if (
			get(gestures).length > 0 &&
			!window.confirm($t("alert.clearGesturesConfirm"))
		) {
			alertUser($t("alert.data.error.deleting"));
			return;
		}

		gestures.set([]);
	}
</script>


<!-- Controlbar -->
<Controlbar>
	<!-- Clear all gestures -->
	<ControlbarBox>
		<button
			class="bg-gray-100 text-sm hover:bg-gray-300 py-1 px-2 rounded btn transition ease border border-gray-400"
			on:click={() => {
        clearGestures();
      }}
		>
			{$t("content.data.header.delete")}
		</button>
	</ControlbarBox>

	<!-- Download gestures -->
	<ControlbarBox>
		<button
			class="bg-gray-100 text-sm hover:bg-gray-300 py-1 px-2 rounded btn transition ease border border-gray-400"
			on:click={downloadClickEvent()}
		>
			{$t("content.data.header.save")}
		</button>
	</ControlbarBox>

	<!-- Upload gestures -->
	<ControlbarBox>
		<div>
			<button
				class="bg-gray-100 text-sm hover:bg-gray-300 py-1 px-2 rounded btn transition ease border border-gray-400"
				for="files"
				on:click={() => {
          uploadButton.click();
        }}>{$t("content.data.header.upload")}</button
			>
			<input
				bind:this={uploadButton}
				id="files"
				on:change={uploadClickEvent}
				style="visibility:hidden; position: absolute;"
				type="file"
			/>
			<!-- /* Ændrer og tjek om ændring er blevet til undefined*/ -->
		</div>
	</ControlbarBox>

	<!-- Choose trigger on Micro:bit to activate recording-->
	<ControlbarBox>
		<label class="mr-2 ml-2" for="recordActivation">{$t("content.data.header.shortcutHeading")}</label>
		<select
			bind:value={$settings.preferableButton}
			class="bg-gray-100 text-sm py-1 px-2 rounded btn transition ease border border-gray-400"
			id="recordActivation"
			name="recordActivation"
		>
			<option value="none">{$t("content.data.header.shortcutOptionNone")}</option>
			<option value="A">{$t("content.data.header.shortcutOptionA")}</option>
			<option value="B">{$t("content.data.header.shortcutOptionB")}</option>
			<option value="AB">{$t("content.data.header.shortcutOptionAB")}</option>
		</select>
	</ControlbarBox>
	<div class="relative float-right">
		<Information
			boxOffset={{x:-20, y:20}}
			iconOffset={{x:0, y:-12}}
			text={$t("content.data.header.shortcutHelpBody")}
			title={$t("content.data.header.shortcutHeading")}
			width={20}
		/>
	</div>
</Controlbar>

<!-- Main pane -->
<main class="pl-2 mt-14 w-full">
	<!-- Display all gestures -->
	{#each $gestures as gesture}
		<Gesture
			bind:name={gesture.name}
			bind:recordings={gesture.recordings}
			on:removeGesture={(e) => {
          removeGesture(e.detail.gesture);
        }}
			bind:gesture
		/>
	{/each}

	<!-- Button for adding a new gesture -->
	<NewGesture on:createGesture={createGesture} />
</main>
