<script>
    import {createEventDispatcher} from "svelte";
    import RecordingGraph from "./graphs/RecordingGraph.svelte";

    // get recording from mother prop
    export let recording;
    export let showDeleteButton = true;

    // Hack for rebuilding graph once recording finish
    $: updateGraph(recording);

    let hide = false;
    let id = false;
    let rebuildGraph = false;

    let isHovering = false;

    function updateGraph(e) {
        const lastId = id;
        id = e.id;
        if (lastId === id || lastId === false) return;

        rebuildGraph = true;
        setTimeout(() => {
            rebuildGraph = false;
        }, 25);
    }

    // Enables dispatching events from component
    const dispatch = createEventDispatcher();

    // Method for propagating deletion of recording
    function deleteClicked(e) {
        if (hide) return;

        hide = true;
        setTimeout(() => {
            hide = false;
            dispatch("delete", {recording});
        }, 450);
    }
</script>

<main
        class="h-26 w-40 pr-3 pt-1 cursor-pointer bg-white"
        on:click={deleteClicked}
>
    <div class="relative">
        <div
                on:mouseenter={() => isHovering = true}
                on:mouseleave={() => {isHovering = false;}}
                class="absolute h-26 w-40 transition ease {hide
      ? 'bg-white duration-500'
      : 'bg-transparent'}"></div>
    </div>
    <!-- Deleting button -->
    <div class="relative">
        {#if showDeleteButton}
            <button class="absolute pl-3">
                <i class="far fa-times-circle fa-lg transition ease {isHovering?'isHovered':'text-light-800'} "/>
            </button>
        {/if}
    </div>
    <!-- Graph for recording data -->
    {#if !rebuildGraph}
        <RecordingGraph data={recording.data}/>
    {/if}
</main>

<style>
    .isHovered {
        color: red;
        transform: scale(1.2);
    }
</style>