<script lang="ts">
	import type MicrobitUSB from "../../script/microbit-interfacing/MicrobitUSB";
	import MainPrompt from "./MainPrompt.svelte";
	import USBPrompt from "./USBPrompt.svelte";
	import type MicrobitBluetooth from "../../script/microbit-interfacing/MicrobitBluetooth";
	import BluetoothPrompt from "./BluetoothPrompt.svelte";
	import { DeviceRequestStates, state } from "../../script/common";
	import ConnectionBehaviours from "../../script/connection-behaviours/ConnectionBehaviours";
	import { createEventDispatcher } from "svelte";
	import ManualTransferPrompt from "./manual-transfer/ManualTransferPrompt.svelte";

	enum States {
		MAIN, // main prompt
		USB, // usb prompt
		MANUAL_TRANSFER, // usb incompatible / manual prompt
		BLUETOOTH, // bluetooth prompt
	}

	// Callback functions
	export let onBluetoothConnected: () => void;
	export let onFindUSB: () => void = undefined;
	export let deviceRequestState:
		DeviceRequestStates.NONE
		| DeviceRequestStates.INPUT
		| DeviceRequestStates.OUTPUT;
	export let promptState: States = States.MAIN;

	let flashFailedStatus = false;
	let disableUsb = false;

	let usbDevice = undefined;
	const dispatchStateChangeEvent = createEventDispatcher<{
		stateChange: { blockClosing: boolean };
	}>();

	const findUSBClicked = () => {
		if (onFindUSB) {
			onFindUSB();
		}
	};

	const findUSBClickedButIncompatible = () => {
		promptState = States.MANUAL_TRANSFER;
	};

	const microbitUSBFound = (device: MicrobitUSB) => {
		usbDevice = device;
		promptState = States.USB;
	};

	const bluetoothConnected = (btDevice: MicrobitBluetooth, name: string) => {
		if (deviceRequestState == DeviceRequestStates.INPUT) {
			ConnectionBehaviours.getInputBehaviour().bluetoothConnect(btDevice, name);
		}
		if (deviceRequestState == DeviceRequestStates.OUTPUT) {
			ConnectionBehaviours.getOutputBehaviour().bluetoothConnect(
				btDevice,
				name
			);
		}
		onBluetoothConnected();
	};

	const bluetoothError = (error) => {
		if (deviceRequestState == DeviceRequestStates.INPUT) {
			ConnectionBehaviours.getInputBehaviour().bluetoothError(error);
		}
		if (deviceRequestState == DeviceRequestStates.OUTPUT) {
			ConnectionBehaviours.getOutputBehaviour().bluetoothError(error);
		}
	};

	const flashBegin = () => {
		state.update((s) => {
			s.isFlashingDevice = true;
			return s;
		});
	};

	const flashFailed = () => {
		promptState = States.MANUAL_TRANSFER;
		flashFailedStatus = true;
	};

	const flashEnd = () => {
		state.update((s) => {
			s.isFlashingDevice = false;
			return s;
		});
	};

	const connectWithBluetoothClicked = () => {
		promptState = States.BLUETOOTH;
	};

	const onTransferringChanged = (event) => {
		dispatchStateChangeEvent("stateChange", {
			blockClosing: event.detail.isTransferring
		});
	};
</script>

<div class="w-240">
	{#if promptState === States.MAIN}
		<MainPrompt
			disableUsb={disableUsb}
			onFindUSB={findUSBClicked}
			onUSBIncompatible={findUSBClickedButIncompatible}
			onConnectWithBluetooth={connectWithBluetoothClicked}
			onMicrobitUSBFound={(device) => {
        microbitUSBFound(device);
      }}
			{onBluetoothConnected}
		/>
	{/if}
	{#if promptState === States.USB}
		<USBPrompt
			onFlashBegin={flashBegin}
			onFlashEnd={flashEnd}
			onFlashFailed={flashFailed}
			onBluetoothConnected={bluetoothConnected}
			onBluetoothError={bluetoothError}
			microbitUsbDevice={usbDevice}
			on:transferringStateChanged={onTransferringChanged}
		/>
	{/if}
	{#if promptState === States.BLUETOOTH}
		<BluetoothPrompt
			onBluetoothConnected={bluetoothConnected}
			onBluetoothError={bluetoothError}
		/>
	{/if}
	{#if promptState === States.MANUAL_TRANSFER}
		<ManualTransferPrompt flashFailed={flashFailedStatus}
													backToMainPrompt={() => {promptState = States.MAIN; disableUsb = true;}} />
	{/if}
</div>
