<script lang="ts">
  import Input from "../pages/Input.svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { gestures } from "../script/common";
  import { t } from "../i18n";
  const dispatch = createEventDispatcher();


  // Change canvas to Gesture page.
  onMount(() => {
    dispatch("changeCanvas", { canvas: Input });
  });

  // Count of amount of recordings
  $: numberOfRecodings = $gestures.reduce(
    (sum, val) => (sum += val.recordings.length),
    0
  );
</script>

<div class="w-full text-center justify-center py-10">
  <p class="text-9xl">{numberOfRecodings}</p>
  <p class="text-xl">{$t("menu.data.examples")}</p>
</div>
