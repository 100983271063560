<script lang="ts">
	import { createEventDispatcher } from "svelte";
	import type Navigation from "../script/Navigation";
	import Information from "../components/information/Information.svelte";

	export let menu: Navigation.MenuProperties;
	export let currentMenu: Navigation.MenuProperties;
	$: isCurrentMenu = menu === currentMenu;

	const dispatch = createEventDispatcher();

	function changeMenu(e) {
		if (isCurrentMenu) return;

		dispatch("changeMenu", { menu });
	}

	function changeCanvas(e) {
		dispatch("changeCanvas", { canvas: e.detail.canvas });
	}
</script>
<div>
	<div class="rounded border bg-white
            bg-opacity-15 border-solid
            text-warm-gray-50 border-white
            border-opacity-30 shadow select-none
            transition duration-300
            {isCurrentMenu ? '' : 'hover:bg-border-opacity-30  hover:bg-opacity-30  cursor-pointer'}"
			 on:click={changeMenu}>

		<div class="relative float-right">
			<Information
				boxOffset={{x: -204, y: 80}}
				iconOffset={{x: -20, y: 0}}
				light
				text={menu.desc}
				title={menu.name}
				width={20}
			/>
		</div>

		<!-- Title -->
		<div class="text-xl font-medium flex justify-center" class:h-17={!isCurrentMenu}>
			<p class="flex-row self-center">{menu.name}</p>
		</div>
		<div>
			<!-- If menu has special look when closed. Demonstrate that -->
			{#if !isCurrentMenu && menu.special}
				<svelte:component this={menu.special} />
			{/if}

			<!-- If it is current menu. Show the menu-->
			{#if isCurrentMenu}
				<svelte:component this={menu.menu} on:changeCanvas={changeCanvas} />
			{/if}
		</div>
	</div>
</div>
<style>
    div {
        transition: max-height 0.3s ease;
    }
</style>
