import Cookies from 'js-cookie';
/**
 * Handles the storage and access of cookie information in a GDPR-compliant manner
 */
class CookieManager {
    static isComplianceSet() {
        return Cookies.get(this.complianceCookieName) !== undefined;
    }
    static getComplianceChoices() {
        if (!this.isComplianceSet()) {
            return {
                necessary: true,
                analytics: false,
            };
        }
        return JSON.parse(Cookies.get(this.complianceCookieName));
    }
    static setComplianceChoices(complianceChoices) {
        Cookies.set(this.complianceCookieName, JSON.stringify(complianceChoices), { expires: 365 });
        document.dispatchEvent(this.complianceCookieChangeEvent);
    }
}
CookieManager.complianceCookieName = "cookie_consent";
CookieManager.complianceCookieChangeEvent = new Event("complianceCookieChange");
export default CookieManager;
