<script>
  // IMPORT AND DEFAULTS
  import { get } from "svelte/store";
  import {
    settings,
    livedata,
    state,
    informUser,
    alertUser,
    buttonPressed,
    notReady,
    model,
    gestures,
  } from "../script/common";
  import { makeInputs } from "../script/ml";
  import Recording from "./Recording.svelte";
  import InformationBoxStore from "./information/Information.svelte";
  import { t } from "../i18n"
  import Information from "./information/Information.svelte";

  // Variables for component
  export let gesture = null;
  let iAmRecording = false;
  $: setupTestArray(gesture);
  // Add tests array to gesture
  function setupTestArray(gesture) {
    if (!gesture.tests) gesture.tests = [];
  }

  // method for recording gesture for that specific gesture
  function recordClicked(e) {
    if (notReady()) return;

    informUser("Optager");
    iAmRecording = true;
    $state.isTesting = true;

    // Get duration
    const duration = get(settings).duration;

    // New array for data
    let newData = { x: [], y: [], z: [] };

    // Set timeout to allow recording in 1s
    const unsubscribe = livedata.subscribe((data) => {
      newData.x.push(data.accelX);
      newData.y.push(data.accelY);
      newData.z.push(data.accelZ);
    });

    // Once duration is over (1000ms default), stop recording
    setTimeout(() => {
      iAmRecording = false;
      $state.isTesting = false;
      unsubscribe();
      if (48 <= newData.x.length) {
        newRecording(newData);
        informUser($t("content.trainer.testCenter.alert.recordingFinished"));
      } else {
        alertUser($t("content.trainer.testCenter.alert.disconnectDuringRecording"));
      }
    }, duration);
  }

  // Once finished recording
  // Add new recording to recordings
  function newRecording(data) {
    const recording = { id: Date.now(), data };
    gesture.tests = [...gesture.tests, recording];

    calculateGuesses($state.isPredicting);
  }

  // Delete recording from recordings array
  function deleteRecording(recording) {
    if (notReady(false)) return;
    gesture.tests.splice(gesture.tests.indexOf(recording), 1);
    gesture.tests = gesture.tests;

    calculateGuesses($state.isPredicting);
  }

  // Selecting this gesture for recording. Updates settings accordingly
  // If gesture is already selected, the selection is removed.
  function selectClicked(e) {
    settings.update((obj) => {
      if (obj.gestureChosen === gesture) obj.gestureChosen = undefined;
      else obj.gestureChosen = gesture;
      return obj;
    });
  }

  // When microbit buttons are pressed, this is called
  // Assess whether settings match with button-clicked.
  // If so, the gesture calls the recording function.
  function triggerButtonsClicked(buttons) {
    const set = get(settings);
    if (set.gestureChosen !== gesture) return;
    if (
      (set.preferableButton === "AB") ||
      (buttons.buttonA && set.preferableButton === "A") ||
      (set.preferableButton === "B" && buttons.buttonB)
    )
      recordClicked(undefined);
  }

  // Make function depend on buttonsPressed store.x½
  let declaring = true;
  $: {
    if (!declaring) {
      // Do not call when component is mounted
      triggerButtonsClicked($buttonPressed);
    } else {
      declaring = false;
    }
  }

  // Variables for storing amount of correct tests
  let isClassifying = false;
  let correct;

  // Whenever the state changes. Consider updating score of tests
  $: calculateGuesses($state.isPredicting, $state.isTesting);

  // Calculates how many correct in the given test
  function calculateGuesses(isPredicting, isTesting) {
    // If not predicting, is recording a test or there is not tests. Don't run function
    if (!isPredicting || isTesting || gesture.tests.length === 0) {
      correct = undefined;
      return;
    }

    // If the function is already running. This hack prevents software from classifying twice
    if (isClassifying) {
      return;
    }
    isClassifying = true;
    setTimeout(() => {
      isClassifying = false;
    }, 100);

    // Set all variables for rests
    correct = 0;
    const amountOfGestures = get(gestures).length;
    const threshold = 1 / amountOfGestures;
    const leading = 0.2 / amountOfGestures;

    // push data from data-points
    gesture.tests.forEach((test) => {
      const { x, y, z } = test.data;

      // Turn the data into an object of up to 12 parameters
      const input = makeInputs(x, y, z);

      // Pass parameters to model to classify
      get(model).classify(input, (error, result) => {
        if (error) {
          alertUser(error);
          console.error(error);
          return;
        }

        // Find string of ID
        const me = String(gesture.ID);

        // Create variable to put confidence of algorithm
        let myConfidence = 0;

        // Find the confidence and store it.
        for (const confidenceObject of result) {
          if (confidenceObject.label === me) {
            myConfidence = confidenceObject.confidence;
            break;
          }
        }

        // If confidence is below threshold. Do not add to correct.
        if (myConfidence < threshold + leading) return;

        // If confidence is not leading over other gestures by a certain amount
        // Do not add to correct.
        for (const confidenceObject of result) {
          if (confidenceObject.label === me) continue;
          if (myConfidence < confidenceObject.confidence + leading) return;
        }

        // If passed all tests. Add to correct.
        correct++;
      });
    });
  }
</script>

<main class="relative">
  <!-- Red bar displays when recording. Helps users register that the recording is taking place -->
  <div
    class="bg-red-600 h-0.5 absolute mt-30"
    style={iAmRecording
      ? "transition: 1s linear; width: calc(100vw - 22em);"
      : "width: 0;"}
  />
  <div
    class="h-30 transition min-w-full w-min items-center flex border border-solid border-gray-200 mb-2 p-2 rounded"
    style="background-color: white; background-image: url('./src/v2.svg')"
  >
    <!-- Name and Test-meter -->
    <div class="flex flex-col">
      <!-- Naming -->
      <div
        class="w-32 text-center font-semibold transition ease rounded px-1 py-1 border border-gray-300 border-solid mr-2 bg-white break-words"
      >
        <h3
          on:keypress={(e) => {
            if (e.code === "Enter") {
              e.preventDefault();
              e.target.blur();
            }
          }}
        >
          <span class="font-normal">{$t("content.trainer.testcenter.testOf")}</span>
          {gesture.name}
        </h3>
      </div>

      <!-- Test-meter -->
      <div
        class="w-32 mt-2 h-5 relative overflow-hidden text-center font-semibold rounded border border-gray-300 border-solid mr-2 bg-white"
      >
        <!-- Only display if there are any tests -->
        {#if 0 < gesture.tests?.length}
          {#each Array(gesture.tests.length - 1) as x, i}
            <div
              class="h-5 w-1px bg-gray-200 absolute z-10"
              style="margin-left: {((i + 1) * 128) / gesture.tests.length}px;"
            />
          {/each}

          <!-- Depending on amount of correct. Make bar, that lights up red/green -->
          {#if !isClassifying && correct !== undefined}
            <div class="h-5 bg-green-400 absolute w-32">
              <div
                class="h-5 bg-red-400 w-32"
                style="margin-left: {(correct / gesture.tests.length) * 128}px;"
              />
            </div>
          {/if}
        {/if}
      </div>
    </div>

    <!-- Buttons -->
    <div class="flex flex-col mr-2 min-w-20">
      <button
        on:click={selectClicked}
        class="bg-blue-200 text-sm hover:bg-blue-300 py-1 w-20 rounded mb-2 transition ease"
        class:chosenGesture={$settings.gestureChosen === gesture}
        >{$settings.gestureChosen === gesture ? $t("content.trainer.testcenter.chosenButton") : $t("content.trainer.testcenter.chooseButton")}</button
      >
      <button
        class="bg-green-200 text-sm hover:bg-green-300 py-1 w-20 rounded transition ease"
        on:click={recordClicked}>{$t("content.trainer.testcenter.recordButton")}</button
      >
    </div>

    <div class="relative float-left">
      <Information
        text={$t("content.trainer.testcenter.helpBody")}
        title={$t("content.trainer.testcenter.helpHeading")}
        boxOffset={{x:-20, y:20}}
        iconOffset={{x:-55, y:-56}}
        width={17}
      />
    </div>

    <!-- If there are tests. Display them -->
    {#if gesture.tests}
      {#each gesture.tests as recording}
        <Recording
          {recording}
          on:delete={(e) => {
            deleteRecording(e.detail.recording);
          }}
        />
      {/each}
    {/if}
  </div>
</main>

<style>
  .chosenGesture {
    background-color: rgb(59, 130, 246) !important;
    color: white !important;
  }
</style>
