<script>
    // IMPORT AND DEFAULTS
    import OutputDecision from "./output/OutputDecision.svelte";
    import Matrix from "./output/Matrix.svelte";
    import InformationBoxStore from "./information/Information.svelte";
    import {settings} from "../script/common"
    import {t} from "../i18n"
    import Information from "./information/Information.svelte";


    // Variables for component
    export let gesture = null;
    let triggered = false;
    let triggerFunctions = [];

    let requiredConfidenceLevel = 80;
    $: confidenceLevel = gesture?.confidence;

    $: if (shouldTrigger(requiredConfidenceLevel, confidenceLevel, triggered)) triggerComponnets();

    function shouldTrigger(rc, c, oldT) {
        triggered = rc <= c * 100;
        if (!triggered) return false;
        if (!$settings.automaticClassification) return true;
        if (oldT) return false;
        return true;
    }

    const triggerComponnets = () => triggerFunctions.forEach(triggerFunc => triggerFunc());
</script>

<main class="mb-2 justify-center layout-grid">
    <!-- NAMES AND CONFIDENCE METER -->
    <div
            class="h-30 items-center flex border border-solid border-gray-200 p-2 bg-white rounded"
    >
        <div
                class="w-32 text-center font-semibold rounded px-1 py-1 border border-gray-300 border-solid mr-2 break-words"
        >
            <h3>{gesture.name}</h3>
        </div>

        <!-- Confidence trigger -->
        <input
                class="h-full"
                type="range"
                orient="vertical"
                name=""
                min="10"
                max="90"
                id=""
                bind:value={requiredConfidenceLevel}
        />

        <!-- METER -->
        <div class="w-4 h-full relative">
            <div
                    class="w-4 h-full absolute rounded border border-solid border-gray-400 overflow-hidden"
            >
                <div
                        class="absolute w-5 {triggered
            ? 'bg-blue-500'
            : 'bg-gray-400'} z-index: -10"
                        style="height: {100 * confidenceLevel}px; margin-top: {100 -
            100 * confidenceLevel}px;"
                />
                <div
                        class="absolute w-5 bg-blue-500"
                        style="height: 1px; margin-top: {6.5 -
            0.068 * requiredConfidenceLevel}rem;"
                />
                <div class="absolute">
                    {#each [75, 50, 25] as line}
                        <div class="w-5 bg-gray-300 mt-6" style="height: 1px;">
                            <p
                                    class="absolute text-xs"
                                    style="margin-top: -8px; margin-left: 18px;"
                            >
                                {line}%
                            </p>
                        </div>
                    {/each}
                </div>
                <div/>
            </div>
        </div>
        <div class="relative float-right">
            <Information
                text={$t("content.model.classification.helpBody")}
                title={$t("content.model.classification.helpHeading")}
                width={17}
                boxOffset={{x:-20,y:20}}
                iconOffset={{x:4,y:-50}}/>
        </div>
    </div>

    <!-- ARROW -->
    <div class="flex items-center justify-center separator w-12">
        <i
                class="fas fa-long-arrow-alt-right fa-2x {triggered
        ? 'text-blue-400'
        : 'text-gray-300'} "
        />
    </div>

    <!-- OUTPUT SETTINGS -->
    <div
            class="flex items-center h-30 border border-solid border-gray-200 p-2 bg-white rounded"
    >
        <div class="relative float-left">
            <Information
                    text={$t("content.model.output.helpBody")}
                    title={$t("content.model.output.helpHeading")}
                    iconOffset={{x:-4, y:-50}}
                    boxOffset={{x:-20,y:20}}
                    width={17}
            />
        </div>
        <Matrix bind:trigger={triggerFunctions[0]} {gesture}/>
        <OutputDecision bind:trigger={triggerFunctions[1]} {gesture}/>
    </div>
</main>

<style>
    .layout-grid {
        display: grid;
        grid-template-columns: 13rem 3rem 1fr;
    }

    input[type="range"][orient="vertical"] {
        writing-mode: bt-lr; /* IE */
        -webkit-appearance: slider-vertical; /* WebKit */
        width: 20px;
    }
</style>
