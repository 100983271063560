<script lang="ts">
	import StdButton from "../../../stdbutton/StdButton.svelte";
	import { t } from "../../../../i18n";
	import { fade } from "svelte/transition";

	export let continueClicked: () => void;

	let pulse = true;
</script>

<div>

	<div class="mb-3">
		<div class="flex">
			<div>
				<p>1. {$t("popup.connectMB.USBCompatibility.transferStep.step1")}</p>
				<p>2. {$t("popup.connectMB.USBCompatibility.transferStep.step2")}</p>
				<p>3. {$t("popup.connectMB.USBCompatibility.transferStep.step3")}</p>
			</div>
			<div>
				<!-- Todo: replace with loader skeleton -->
				{#if pulse}
					<div class="animate-pulse bg-gray-200 rounded w-80 h-30" transition:fade>
						<img alt="Transferring the firmware"
								 class="w-80 shadow-md"
								 on:load={() => pulse = false}
								 src="transfer_firmware.gif" />
					</div>
				{:else }
					<div class="w-80 h-30" transition:fade>
						<img alt="Transferring the firmware"
								 class="w-80 shadow-md"
								 on:load={() => pulse = false}
								 src="transfer_firmware.gif" />
					</div>
				{/if}
			</div>
		</div>
	</div>
	<div class="w-34 m-auto">
		<StdButton onClicked={continueClicked}>{$t("popup.connectMB.USBCompatibility.transferStep.finish")}</StdButton>
	</div>
</div>