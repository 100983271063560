<script lang="ts">
	import InformationBoxStore from "./InformationBoxStore";
	import InformationIcon from "./InformationIcon.svelte";

	export let text: string | string[] = "";
	export let boxOffset: { x: number, y: number } = { x: 0, y: 0 };
	export let iconOffset: { x: number, y: number } = { x: 0, y: 0 };
	export let light = false;
	export let width: number = 10;
	export let title: string;

	const onMouseEnter = (iconElement) => {
		InformationBoxStore.setText(text);
		InformationBoxStore.title.set(title);
		InformationBoxStore.isLightTheme.set(light);
		InformationBoxStore.open();
		let iconBounds = iconElement.getBoundingClientRect();
		InformationBoxStore.position.set({ x: iconBounds.x, y: iconBounds.y });
		InformationBoxStore.offset.set(boxOffset);
		InformationBoxStore.boxWidth.set(width);
	};

	const onMouseLeave = () => {
		InformationBoxStore.close();
	};

</script>

<InformationIcon light={light}
								 offset={iconOffset}
								 onMouseEnter={onMouseEnter}
								 onMouseLeave={onMouseLeave} />
