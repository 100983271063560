import modalManager from "./modal";
import CookieDescriptionOverlay from "../../components/cookie-bannner/CookieDescriptionOverlay.svelte";
export function openCookieDescription() {
    // Open Modal
    modalManager.openModal({
        component: CookieDescriptionOverlay,
        onClose
    });
}
function onClose() {
}
