<script lang="ts">
  import type MicrobitUSB from "../../script/microbit-interfacing/MicrobitUSB";
  import { alertUser, hexFiles } from "../../script/common";
  import type MicrobitBluetooth from "../../script/microbit-interfacing/MicrobitBluetooth";
  import { t } from "../../i18n";
  import StdButton from "../stdbutton/StdButton.svelte";
  import { createEventDispatcher } from "svelte";
  import EventEmittingMicrobitBluetooth from "../../script/microbit-interfacing/EventEmittingMicrobitBluetooth";
  import EventEmittingMicrobitUSB from "../../script/microbit-interfacing/EventEmittingMicrobitUSB";

  // callbacks
  export let onUSBDisconnect: (event: USBConnectionEvent) => void = () => {};
  export let onBluetoothConnected: (
    device: MicrobitBluetooth,
    name: string
  ) => void;
  export let onBluetoothError: (error: Error) => void;
  export let onFlashBegin: () => void;
  export let onFlashEnd: () => void;
  export let onFlashFailed: () => void;

  // Guard clauses
  if (!onBluetoothConnected)
    throw new Error("onBluetoothConnected callback is not defined");
  export let microbitUsbDevice: EventEmittingMicrobitUSB;
  if (!microbitUsbDevice)
    // Must be set upstream.
    throw new Error("No microbit USB device assigned");

  let name = undefined;
  let isTransferring: boolean = true;
  let transferProgressLabel: string = "loading please wait.";
  let transferProgress: number = 0; // Between 0 and 1.
  let isConnected: boolean = true;
  let isAlreadyConnected: boolean = undefined;

  const dispatchTransferringStateEvent = createEventDispatcher<{
    transferringStateChanged: { isTransferring: boolean };
  }>();

  function changeTransferringState(newState: boolean): void {
    isTransferring = newState;
    dispatchTransferringStateEvent("transferringStateChanged", {
      isTransferring: newState,
    });
  }

  const subTitle = name
    ? "Micro:bit " + name + " " + $t("popup.connectMB.usb.MBFound")
    : "";
  const hexFile: string = hexFiles[microbitUsbDevice.getModelNumber()]; // from common.ts
  // fetch the name of the micro:bit from the device

  // Todo: refactor and move into microbit behaviours instead
  const flashFailed = () => {
    onFlashFailed();
  }

  const flashHex = () => {
    microbitUsbDevice
      .flashHex(hexFile, (progress) => {
        transferProgress = progress;
        transferProgressLabel = Math.round(progress * 100).toString() + "%";
      }).then(() => {
        transferProgressLabel = $t("popup.connectMB.usb.transferFinished.finishedProgress");
        changeTransferringState(false);
        onFlashEnd();
      }).catch((err) => {
        flashFailed();
        changeTransferringState(false);
    });
  };

  microbitUsbDevice.getFriendlyName().then((friendlyName) => {
    name = friendlyName;
    isAlreadyConnected = false;
    changeTransferringState(true);
    onFlashBegin();
    flashHex();
  }).catch(() => {
    flashFailed();
    changeTransferringState(false);
  });

  // Listen for the disconnect event
  navigator.usb.addEventListener("disconnect", (event: USBConnectionEvent) => {
    onUSBDisconnect(event);
    isConnected = false;
  });

  const connectButtonClicked = async () => {
    try {
      const btDevice = await EventEmittingMicrobitBluetooth.eventEmittingRequestDevice(name);
      if (!btDevice) return;

      const mb = await EventEmittingMicrobitBluetooth.createEventEmittingConnection(
        btDevice,
        void 0,
        undefined,
        onBluetoothError
      );
      onBluetoothConnected(mb, name);
    } catch (e) {
      alertUser(e);
    }
  };
</script>

<!---->
<div class="p-6">
  {#if name !== undefined}
    <!-- TITLE -->
    <div class="pb-0.5 mb-0.5 border-solid border-b-1">
      <p class="text-2xl font-bold text-red-500">
        {$t("popup.connectMB.usb.heading")}
      </p>
    </div>

    <!-- CONTENT -->
    <div class="flex">
      <!-- USB -->
      <div class="flex-1 mr-2">
        <div class="text-blue-500 mb-1 text-xl text-bold">
          <p>{subTitle}</p>
        </div>

        {#if isAlreadyConnected}
          <!--Todo: This is temporarily disabled, also translate when enabling again-->
          <p>Denne enhed er allerede forbundet.</p>
          <div class="flex justify-center">
            <StdButton onClicked={connectButtonClicked}>
              <p>{$t("popup.connectMB.usb.connect")}</p>
            </StdButton>
          </div>
        {/if}
        {#if !isAlreadyConnected}
          {#if isTransferring}
            <p class="text-xl mb-3 text-center">
              {$t("popup.connectMB.usb.transferring.pleaseWait")}
            </p>
          {/if}
          {#if !isTransferring}
            <p class="text-xl mb-3 text-center">
              {transferProgressLabel}
            </p>
          {/if}
          <div class="flex justify-center mb-2">
            <div class="flex-1 mr-2">
              <div class="w-full h-2 bg-gray-200 rounded">
                <div
                  class="h-2 bg-green-400 rounded"
                  style="width: {transferProgress * 100}%;"
                />
              </div>
              {#if isTransferring}
                <p>{transferProgressLabel}</p>
              {/if}
            </div>
          </div>
          {#if !isTransferring && isConnected}
            <p class="text-center text-xl">
              {$t("popup.connectMB.usb.transferFinished.transferComplete")}
              <br />
              {$t("popup.connectMB.usb.transferFinished.pleaseDisconnect")}
            </p>
            <p class="text-center text-xl">
              {$t("popup.connectMB.usb.haveNoBatteryQuestion")}<br />
              {$t("popup.connectMB.usb.continueWithoutBattery")}
              <a
                href="/"
                on:click={(e) => {
                  e.preventDefault();
                  connectButtonClicked();
                }}
                class="text-blue-400 cursor-pointer underline"
                >{$t("popup.connectMB.usb.continueWithoutBatteryButton")}</a
              >
            </p>
          {/if}
          {#if !isTransferring && !isConnected}
            <p class="text-center text-xl">
              {$t(
                "popup.connectMB.usb.transferFinishedAndDisconnected.plugInBattery"
              )}
              <br />
              {$t(
                "popup.connectMB.usb.transferFinishedAndDisconnected.pleaseConnect"
              )}
            </p>
            <div class="flex justify-center">
              <StdButton onClicked={connectButtonClicked}>
                <p>{$t("popup.connectMB.usb.connect")}</p>
              </StdButton>
            </div>
          {/if}
        {/if}
      </div>
    </div>
  {/if}
</div>
