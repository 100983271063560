<!--
In order to avoid weird z-index behaviour we place this at the top of the DOM.
Then we populate it with data and move it as necessary.
The position is computed relative to the component that populates it with data.
-->
<script lang="ts">
	import InformationBoxStore from "./InformationBoxStore";


	let light = false;
	InformationBoxStore.isLightTheme.subscribe(value => light = value)
	let width: number = 10;
	InformationBoxStore.boxWidth.subscribe(value => width = value)
	let pos: {x: number, y: number} = {x:0, y:0};
	InformationBoxStore.position.subscribe(value => pos = value)
	let offset: {x: number, y: number} = {x:0, y:0};
	InformationBoxStore.offset.subscribe(value => offset = value)
	let title = undefined;
	InformationBoxStore.title.subscribe(value => title=value)
	let text = undefined;
	InformationBoxStore.text.subscribe(value => text=value)
	let isOpen = false;
	InformationBoxStore.isOpen.subscribe(value => isOpen = value)
</script>

<div
	class:hidden={!isOpen}
	class:bg-warm-gray-200={light}
	class:bg-warm-gray-600={!light}
	class:text-black={light}
	class:text-white={!light}
	class="absolute z-3 p-4 rounded-md text-sm"
	style="width: {width}rem; transform:translate({offset.x}px, {offset.y}px); left:{pos.x}px;top:{pos.y}px">

	{#if title}
		<span class="font-bold">{title}</span><br />
	{/if}
	{#if text}
		{#each text as t}
			<p>{t}</p>
		{/each}
	{/if}
</div>
