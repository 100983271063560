<script>
	// IMPORT AND DEFAULTS
	import { createEventDispatcher } from "svelte";
	import { get } from "svelte/store";
	import { alertUser, buttonPressed, informUser, livedata, notReady, settings, state } from "../script/common";
	import Recording from "./Recording.svelte";
	import { t } from "../i18n";
	import Information from "./information/Information.svelte";

	const dispatch = createEventDispatcher();
	const defaultNewName = $t("content.data.classPlaceholderNewClass");
	const recordingDuration = get(settings).duration;

	// Variables for component
	export let name = "";
	export let gesture = null;
	export let recordings = [];
	let iAmRecording = false;

	// When title is clicked. Remove name
	function titleClicked(e) {
		if (name === defaultNewName) {
			name = "";
		}
	}

	// Remove gesture when clicked
	let hide = false;

	function removeClicked(e) {
		if (notReady(false) || hide) return;
		if (!window.confirm("Are you sure you want to delete: " + gesture.name))
			return;
		hide = true;

		setTimeout(() => {
			hide = false;
			dispatch("removeGesture", { gesture });
		}, 450);
	}

	// method for recording gesture for that specific gesture
	function recordClicked(e) {
		if (notReady()) return;

		$state.isRecording = true;
		informUser("Optager");
		iAmRecording = true;

		// New array for data
		let newData = { x: [], y: [], z: [] };

		// Set timeout to allow recording in 1s
		const unsubscribe = livedata.subscribe((data) => {
			newData.x.push(data.accelX);
			newData.y.push(data.accelY);
			newData.z.push(data.accelZ);
		});

		// Once duration is over (1000ms default), stop recording
		setTimeout(() => {
			$state.isRecording = false;
			iAmRecording = false;
			unsubscribe();
			if (get(settings).minSamples <= newData.x.length) {
				newRecording(newData);
				informUser("Færdiggjort optagelse");
			} else {
				alertUser("Micro:Bit frakoblede under optagelse");
			}
		}, recordingDuration);
	}

	// Once finished recording
	// Add new recording to recordings
	function newRecording(data) {
		const recording = { id: Date.now(), data };
		recordings = [...recordings, recording];
	}

	// Delete recording from recordings array
	function deleteRecording(recording) {
		if (notReady(false)) return;
		$state.isPredicting = false;

		recordings.splice(recordings.indexOf(recording), 1);
		recordings = recordings;
	}

	// Selecting this gesture for recording. Updates settings accordingly
	// If gesture is already selected, the selection is removed.
	function selectClicked(e) {
		settings.update((obj) => {
			if (obj.gestureChosen === gesture) obj.gestureChosen = undefined;
			else obj.gestureChosen = gesture;
			return obj;
		});
	}

	// When microbit buttons are pressed, this is called
	// Assess whether settings match with button-clicked.
	// If so, the gesture calls the recording function.
	function triggerButtonsClicked(buttons) {
		const set = get(settings);
		if (set.gestureChosen !== gesture) return;
		if (
			(set.preferableButton === "AB") ||
			(buttons.buttonA && set.preferableButton === "A") ||
			(set.preferableButton === "B" && buttons.buttonB)
		)
			recordClicked(undefined);
	}

	// Make function depend on buttonsPressed store.x½
	let declaring = true;
	$: {
		if (!declaring) {
			// Do not call when component is mounted
			triggerButtonsClicked($buttonPressed);
		} else {
			declaring = false;
		}
	}

	let informationAboutGesture = $t("content.data.classHelpBody");
</script>

<main
	class="transition ease flex-row flex {hide
    ? 'duration-500 opacity-0'
    : 'opacity-100'}"
>
	<!-- Recordingbar to show recording-progress -->

	<div
		class="bg-red-600 h-0.5 absolute mt-30"
		style={iAmRecording
      ? "transition: " + /* TODO: Clean this up! : */ (recordingDuration/1000).toString() +"s linear; width: calc(100vw - 22em);"
      : "width: 0;"}></div>
	<div class="relative">
		<Information
			boxOffset={{x:-20, y:20}}
			iconOffset={{x:6, y:4}}
			text={informationAboutGesture}
			title={$t("content.data.classHelpHeader")}
			width={20} />
	</div>
	<div
		class="items-center flex border border-solid border-gray-200 mb-2 p-2 bg-white rounded"
	>
		<!-- Title of gesture-->
		<div
			class="min-w-32 max-w-32 text-center font-semibold transition ease rounded border border-gray-300 border-solid hover:bg-gray-100"
		>
			<h3
				bind:textContent={name}
				contenteditable
				on:click={titleClicked}
				on:keypress={(e) => {
          if (name.length > 25) {
            e.preventDefault();
            alertUser($t("alert.data.classNameLengthAlert"));
          }
          if (e.code === "Enter") {
            e.preventDefault();
            e.target.blur();
          }
        }}
			/>
		</div>

		<!-- Buttons for editing gesture -->
		<div class="flex flex-col pl-2 pr-2 min-w-20">
			<button
				class="bg-blue-200 text-sm hover:bg-blue-300 py-1 w-20 rounded mb-2 transition ease"
				class:chosenGesture={$settings.gestureChosen === gesture}
				on:click={selectClicked}
			>{$settings.gestureChosen === gesture ? $t("content.data.selected") : $t("content.data.select")}</button
			>
			<button
				class="bg-green-200 text-sm hover:bg-green-300 py-1 w-20 rounded transition ease"
				on:click={recordClicked}>{$t("content.data.record")}</button
			>
			<button
				class="bg-red-200 text-sm hover:bg-red-300 py-1 w-20 rounded mt-2 transition ease"
				on:click={removeClicked}>{$t("content.data.delete")}</button
			>
		</div>

		<!-- Show recording for each recording -->
		{#each recordings as recording}
			<Recording
				{recording}
				on:delete={(e) => {
          deleteRecording(e.detail.recording);
        }}
			/>
		{/each}
	</div>
</main>

<style>
    .chosenGesture {
        background-color: rgb(59, 130, 246) !important;
        color: white !important;
    }
</style>
