<script lang="ts">
	import VersionStep from "./tutorial-steps/VersionStep.svelte";
	import ConnectStep from "./tutorial-steps/ConnectStep.svelte";
	import DownloadStep from "./tutorial-steps/DownloadStep.svelte";
	import TransferStep from "./tutorial-steps/TransferStep.svelte";
	import { t } from "../../../i18n";

	export let backToMainPrompt: () => void;
	export let flashFailed: boolean;

	enum ManualSteps {
		VERSION,
		CONNECT,
		DOWNLOAD,
		TRANSFER
	}

	let currentStep = ManualSteps.VERSION;
	let targetVersion = 0; // 0 signals unknown yet.

	const changeTargetVersion = (version: number) => {
		targetVersion = version;
		nextStep();
	};

	const nextStep = () => {
		currentStep = currentStep + 1;
	};
</script>

<div class="flex flex-col gap-2 mr-4 justify-between">
	<div>
		<div class="text-blue-500 mb-2 text-xl text-bold">
			<p>{$t("popup.connectMB.USBCompatibility.subtitle")}</p>
		</div>
		{#if currentStep === ManualSteps.VERSION}
			<VersionStep flashFailed={flashFailed} backToMainPrompt={backToMainPrompt}
									 versionClicked={(v) => changeTargetVersion(v)} />
		{/if}
		{#if currentStep === ManualSteps.CONNECT}
			<ConnectStep continueClicked={() => nextStep()} />
		{/if}
		{#if currentStep === ManualSteps.DOWNLOAD}
			<DownloadStep targetVersion={targetVersion} continueClicked={() => nextStep()} />
		{/if}
		{#if currentStep === ManualSteps.TRANSFER}
			<TransferStep continueClicked={backToMainPrompt} />
		{/if}
	</div>
</div>