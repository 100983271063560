import Bowser from "bowser";
import { SemVerImpl, WebBluetoothCompatibility as BTComp, WebUSBCompatibility as USBComp } from "./CompatibilityList";
class CompatibilityChecker {
    static checkCompatibility() {
        if (localStorage.getItem("isTesting")) {
            return { bluetooth: true, usb: true };
        }
        const browser = Bowser.getParser(window.navigator.userAgent);
        const browserName = browser.getBrowser().name;
        const osName = browser.getOS().name;
        const browserVersion = browser.getBrowserVersion();
        if (!browserVersion)
            return { bluetooth: false, usb: false };
        const majorVersion = browser.getBrowserVersion().split(".")[0];
        const minorVersion = browser.getBrowserVersion().split(".")[1];
        const semVer = new SemVerImpl(majorVersion, minorVersion);
        const isBluetoothSupported = BTComp.isVersionSupported(browserName, semVer, osName);
        const isUsbSupported = USBComp.isVersionSupported(browserName, semVer, osName);
        return {
            bluetooth: isBluetoothSupported,
            usb: isUsbSupported
        };
    }
}
export default CompatibilityChecker;
