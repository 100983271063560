import modalManager from "./modal";
import ConnectionPrompt from "../../components/connect-prompt/ConnectionPrompt.svelte";
import { DeviceRequestStates, state } from "../common";
import { get } from "svelte/store";
import ConnectionBehaviours from "../connection-behaviours/ConnectionBehaviours";
import connectionBehaviours from "../connection-behaviours/ConnectionBehaviours";
export function openConnectionPromptModal(newRequestState) {
    setRequestingDeviceState(newRequestState);
    if (newRequestState == DeviceRequestStates.INPUT) {
        ConnectionBehaviours.getInputBehaviour().openPrompt();
    }
    else {
        connectionBehaviours.getOutputBehaviour().openPrompt();
    }
    // Open Modal
    modalManager.openModal({
        component: ConnectionPrompt,
        props: {
            onBluetoothConnected: modalManager.closeModal,
            deviceRequestState: get(state).isRequestingDevice
        },
        onClose
    });
}
function onClose() {
    setRequestingDeviceState(DeviceRequestStates.NONE);
}
function setRequestingDeviceState(newRequestState) {
    state.update(currentState => {
        currentState.isRequestingDevice = newRequestState;
        return currentState;
    });
}
