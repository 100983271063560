<script lang="ts">
    import { t } from "./../i18n";
    import { compatibility, state } from "../script/common";
    import { openBluetoothIncompatibilityWarning } from "../script/modal/bluetoothIncompatibilityWarning";

    export let onConnectClick;
	export let onDisconnectClick;
</script>
<main>
	<!-- Button for connecting to Micro:Bit-->
	{#if $compatibility.bluetooth}
		<button class="mt-5 {!$state.isConnected
        ? 'bg-blue-500'
        : 'bg-lime-500'} text-white font-bold py-2 px-4 rounded"
						class:animate-bounce={!$state.isConnected && !$state.isRequestingDevice}
						class:hover:bg-blue-700={!$state.isConnected}
						on:click={() => {onConnectClick();}}
		>
			{$state.isConnected
				? $t("footer.connectButtonConnected")
				: $state.isRequestingDevice
					? $t("footer.connectButtonConnecting")
					: $t("footer.connectButtonNotConnected")}


		</button>
	{:else}
		<button class="mt-5 bg-gray-600 text-white font-bold py-2 px-4 rounded"
						on:click={() => {openBluetoothIncompatibilityWarning()}}
		>
			{$t("footer.connectButtonNotConnected")}

		</button>
	{/if}
	<!-- If the Micro:Bit is connected. Show a disconnect button -->
	{#if $state.isConnected}
		<button
			class="mt-5 bg-gray-300 hover:bg-gray-400 font-bold py-2 px-4 rounded"
			on:click={onDisconnectClick}>{$t("footer.disconnectButton")}</button>
	{/if}
</main>