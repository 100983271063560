<script lang="ts">
	import Output from "../../pages/Output.svelte";
	import { alertUser, DeviceRequestStates, informUser, state } from "../../script/common";
	import { createEventDispatcher, onMount } from "svelte";
	import { t } from "../../i18n";
	import { get } from "svelte/store";
	import ConnectionBehaviours from "../../script/connection-behaviours/ConnectionBehaviours";
	import { openConnectionPromptModal } from "../../script/modal/connectionPrompt";
	import Information from "../../components/information/Information.svelte";

	const dispatch = createEventDispatcher();

	// Change main canvas when this menu is open
	onMount(() => {
		dispatch("changeCanvas", { canvas: Output });
	});

	const connectButtonPressed = () => {
		if (get(state).isOutputting) {
			informUser($t("alert.output.isConnectedInform"));
			alertUser($t("alert.output.isConnectedAlert"));
			return;
		}

		if (!navigator.bluetooth) {
			alertUser($t("alert.output.noBluetoothAlert"));
			return;
		}

		openConnectionPromptModal(DeviceRequestStates.OUTPUT);
	};
</script>

<main>
	<div class="flex w-full flex-col justify-center">

		<!-- Display model SVG when user has trained a model -->
		{#if $state.isPredicting}
			<div>
				<img
					src="model.svg"
					alt="Model Icon"
					class="m-auto animate-spin animate-duration-[10s]"
				/>
			</div>
		{:else}
			<div
				class="h-34 w-34 m-auto border-2 border-white border-opacity-30 rounded-lg border-dashed font-bold text-warm-gray-300"
			>
				<div class="flex h-full">
					<div class="m-auto">
						{$t("menu.model.noModel")}
					</div>
				</div>
			</div>
		{/if}
	</div>
	<div class="flex w-full flex-col justify-center">
		<!-- Button for connecting to the output micro:bit -->

		{#if $state.isPredicting}
			<div class="flex flex-col">
				<div class="flex flex-row">
					<div class="m-auto">
						<button
							class="{$state.isOutputting
          ? 'bg-lime-600 hover:bg-green-500'
          : $state.isRequestingDevice !== DeviceRequestStates.NONE ?
           'bg-blue-400'
          : 'bg-blue-500 hover:bg-blue-600 animate-bounce'} text-white font-medium mt-3 mb-3 py-2 px-4 rounded btn transition ease"
							on:click={connectButtonPressed}
						>
							{$state.isOutputting
								? $t("menu.model.connectedOutputButton")
								: $state.isRequestingDevice !== DeviceRequestStates.NONE ?
									$t("menu.model.connectingOutputButton")
									: $t("menu.model.connectOutputButton")}
						</button>
					</div>

					{#if !$state.isOutputting}
						<div class="relative float-right right-5 top-3">
							<Information
								text={$t("menu.model.helpConnectOutputButtonBody")}
								title={$t("menu.model.helpConnectOutputButtonHeading")}
								boxOffset={{x:-50, y:20}}
								light
								width={17}
							/>
						</div>
					{/if}
				</div>

				<!-- If user is connected to output micro:bit. Show disconnect button -->
				{#if $state.isOutputting}
					<div class="m-auto flex-row">
						<button
							class="bg-warm-gray-400 hover:bg-warm-gray-500 font-bold py-2 px-4 mx-8 mb-3 rounded"
							on:click={() => {
                            ConnectionBehaviours.getOutputBehaviour().bluetoothDisconnect(true)
                        }}>{$t("menu.model.disconnect")}</button>
					</div>
				{/if}
			</div>

			<!-- Show grayed out connect button, when no model is present -->
		{:else}
			<div class="flex">
				<div class="m-auto">
					<button
						class="bg-gray-400 text-white font-medium rounded px-4 py-2 my-2 cursor-default focus:outline-transparent"
					>{$t("menu.model.connectOutputButton")}</button>
				</div>

				<div class="relative float-right right-10">
					<Information
						text={$t("menu.model.helpConnectOutputButtonBody")}
						title={$t("menu.model.helpConnectOutputButtonHeading")}
						iconOffset={{x:-15, y:17}}
						boxOffset={{x:-130, y:20}}
						light
						width={17}
					/>
				</div>
			</div>
		{/if}
	</div>
</main>
