<script lang="ts">
	import { onDestroy, onMount, type SvelteComponent } from "svelte";
	import { fly } from "svelte/transition";
	import type { ModalStateChangedEvent, Props } from "../../script/modal/modal";
	import modalManager from "../../script/modal/modal";

	let component: typeof SvelteComponent;
	let props: Props;
	let title: string = undefined;
	let modalIsOpen: boolean = false;
	let blockClosingModal: boolean = false;

	/**
	 * Overwrite from store rather than listening.
	 * If we listen straight to the store, then Svelte crashes, as the transition:fly cause the component to live
	 * half a second beyond the component being set to undefined when closing the modal.
	 */
	modalManager.getModalStore().subscribe((newModal) => {
		if (newModal == undefined) {
			modalIsOpen = false;
			return;
		}
		component = newModal.component;
		props = newModal.props;
		title = newModal.title;
		modalIsOpen = true;
		blockClosingModal = false;
	});

	/**
	 * Ensure that component is unmounted.
	 * Then we're sure that onDestroy works for components.
	 */
	onDestroy(() => {
		component = undefined;
		title = undefined;
		props = undefined;
	});

	/**
	 * Enable users to close modals by pressing the Escape-key
	 */
	function closeModalOnEspaceKeyEvent(event: KeyboardEvent): void {
		if (event.key !== "Escape") return;

		modalManager.closeModal();
	}

	/**
	 * Add and remove eventlistener as modal opens and closes
	 */
	onDestroy(() => {
		document.removeEventListener("keydown", closeModalOnEspaceKeyEvent);
	});
	onMount(() => {
		document.addEventListener("keydown", closeModalOnEspaceKeyEvent);
	});

	/**
	 * State changes
	 */
	const onStateChange = (event: ModalStateChangedEvent) => {
		modalManager.updateModalState({
			blockClosing: event.detail.blockClosing
		});
	};
</script>

{#if modalIsOpen}
	<div
		transition:fly
		class="absolute bg-blend-darken w-screen h-screen z-2 bg-black/50 flex justify-center items-center"
		on:click={modalManager.closeModal}
	>
		<div
			on:click|stopPropagation
			class="w-min h-min border-gray-200 border border-solid relative bg-white rounded-lg p-6"
		>
			<!-- TITLE of block -->
			{#if title != undefined}
				<p class="text-lg mb-2 font-semibold">
					{title}
				</p>
			{/if}

			<!-- CLOSE BUTTON -->
			<div class="absolute right-4 top-4  svelte-1rnkjvh">
				<button
					class="hover:bg-gray-100 rounded outline-transparent w-8 svelte-1rnkjvh"
					on:click={modalManager.closeModal}
				>
					<i
						class="fas fa-plus text-lg text-gray-600 hover:text-gray-800 duration-75 svelte-1rnkjvh"
						style="transform: rotate(45deg);"
					/>
				</button>
			</div>

			<!-- MODAL CONTENT -->
			<svelte:component
				this={component}
				{...props}
				on:stateChange={onStateChange}
			/>
		</div>
	</div>
{/if}
