<script>
    import Selector from "./Selector.svelte";
    import {t} from "../../i18n"
    import OutputBehaviour from "../../script/connection-behaviours/OutputBehaviour";

    // Generate array with 10 pins.
    const pins = () => {
        const arr = [];
        for (let i = 0; i < 10; i++) {
            arr.push({name: i});
        }
        return arr;
    };

    /** Save details about output */
    export let gesture = undefined;
    const pinsArr = pins();
    let chosenValue = gesture.output?.ioPinVal || 0;
    let chosenPin = pinsArr[gesture.output?.ioPin || 0];
    $: gesture.output.ioPinVal = chosenValue;
    $: gesture.output.ioPin = chosenPin.name;

    export function trigger() {
        OutputBehaviour.sendToPin([{pin: chosenPin.name, on: true}]);
        const cP = chosenPin.name;
        setTimeout(
            () => OutputBehaviour.sendToPin([{pin: cP, on: false}]),
            1000);
    }

</script>

<main class="flex">
    <Selector options={pinsArr} title={$t("content.model.output.pinOn")} bind:chosen={chosenPin}/>
</main>
