<script>
  import { createEventDispatcher } from "svelte";
  import { notReady } from "../script/common";
  import { t } from "../i18n";

  const dispatch = createEventDispatcher();
  const defaultNewName = $t("content.data.classPlaceholderNewClass");

  // When gesture is clicked. Dispatch createGesture
  function clicked(e) {
    if (notReady(false)) return;
    dispatch("createGesture", { name: defaultNewName });
  }
</script>

<main
  class="h-30 relative cursor-pointer transition ease w-96 items-center flex border border-solid border-gray-200 mb-2 p-2 hover:bg-gray-200 bg-white rounded"
  on:click={clicked}
>
  <div class="w-32 text-center">
    <i class="fas fa-plus fa-3x text-gray-500 transition ease " />
  </div>
  <div
    class="h-30 ml-32 w-64 absolute"
    style="    background-image: linear-gradient(
  90deg
  , rgba(246, 246, 246,0), rgb(246, 246, 246));"
  />
</main>

<style>
  main:hover > div > i {
    color: rgb(75, 85, 99) !important;
  }
</style>
