<script>
  import { onMount } from "svelte";

  export let classes, dataRep, color, highlight, doNotHighlight, id;

  let notMountedYet = true;
  $: drawParallelPlot(dataRep, classes, plot);

  let plot;

  // set the dimensions and margins of the graph
  const margin = { top: 30, right: 10, bottom: 10, left: 0 },
    width = 700 - margin.left - margin.right,
    height = 200 - margin.top - margin.bottom;

  const opacity = d3.scaleOrdinal().domain(classes).range([0.5, 0.5, 0.5, 0.5]);

  const strokeWidth = d3.scaleOrdinal().domain(classes).range([4, 4, 4]);

  onMount(() => {
    notMountedYet = false;

    // append the svg object for plot
    plot = d3
      .select("#parallel-plot-" + id)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    drawParallelPlot(dataRep, classes, plot);
  });

  function drawParallelPlot(data, classes, plot) {
    if (notMountedYet) return;
    // Delete existing plot
    if (plot) plot.selectAll("*").remove();

    // Extract the list of dimensions we want to keep in the plot.
    const dimensions = d3.keys(data[0]).filter(function (d) {
      return d != "class";
    });

    // For each dimension, I build a linear scale. I store all in a y object
    let y = {};
    for (let i in dimensions) {
      let name = dimensions[i];
      y[name] = d3
        .scaleLinear()
        .domain(
          d3.extent(data, function (d) {
            return +d[name];
          })
        )
        .range([height, 0]);
    }

    // Build the X scale -> it find the best position for each Y axis
    const x = d3.scalePoint().range([0, width]).padding(1).domain(dimensions);

    // The path function take a row of the csv as input, and return x and y coordinates of the line to draw for this raw.
    function path(d) {
      return d3.line()(
        dimensions.map(function (p) {
          return [x(p), y[p](d[p])];
        })
      );
    }

    // Draw the lines
    plot
      .selectAll() // black magic
      .data(data)
      .enter()
      .append("path")
      .attr("class", function (d) {
        return "line " + d.class;
      }) // 2 class for each line: 'line' and the group name
      .attr("d", path)
      .style("fill", "none")
      .style("stroke", function (d) {
        return color(d.class);
      })
      .style("opacity", function (d) {
        return opacity(d.class);
      })
      .style("stroke-width", function (d) {
        return strokeWidth(d.class);
      })
      .on("mouseover", highlight)
      .on("mouseleave", doNotHighlight);

    // Draw the axis:
    plot
      .selectAll()
      // For each dimension of the dataset I add a 'g' element:
      .data(dimensions)
      .enter()
      .append("g")
      .attr("class", "axis")
      // I translate this element to its right position on the x axis
      .attr("transform", function (d) {
        return "translate(" + x(d) + ")";
      })
      // And I build the axis with the call function
      .each(function (d) {
        d3.select(this).call(d3.axisLeft().ticks(4).scale(y[d]));
      })
      // Add axis title
      .append("text")
      .style("text-anchor", "middle")
      .attr("y", -9)
      .text(function (d) {
        return d;
      })
      .style("fill", "black");
  }
</script>

<div id={"parallel-plot-" + id} class="relative -left-35" />
