<script lang="ts">
	import StdButton from "../../../stdbutton/StdButton.svelte";
	import { hexFiles } from "../../../../script/common";
	import { t } from "../../../../i18n";

	export let continueClicked: () => void;
	export let targetVersion: number;

	if (!targetVersion) { // 0 = false
		throw Error("Unknown target version given in DownloadStep");
	}

	const hexFile = hexFiles[targetVersion];
</script>

<div>
	<div>
		<p>{$t("popup.connectMB.USBCompatibility.downloadStep.clickDownload")}</p>
	</div>
	<div class="w-34 m-auto">
		<StdButton download="firmware.hex" href={hexFile}>Download</StdButton>
	</div>
	<div class="my-3">
		<p>{$t("popup.connectMB.USBCompatibility.downloadStep.clickContinue")}</p>
	</div>
	<div class="w-34 m-auto">
		<StdButton onClicked={continueClicked}>{$t("popup.connectMB.USBCompatibility.continue")}</StdButton>
	</div>
</div>