import { writable } from "svelte/store";
import { isArray } from "chart.js/helpers";
/**
 * Data container for information box
 */
class InformationBoxStore {
    static open() {
        this.isOpen.set(true);
    }
    static close() {
        this.isOpen.set(false);
    }
    /**
     * Sets the text store. Utility to allow for non-array parameter.
     * @param {string | string[]} newText As string or array of string
     */
    static setText(newText) {
        if (!isArray(newText))
            newText = [newText];
        this.text.set(newText);
    }
}
InformationBoxStore.title = writable(undefined);
InformationBoxStore.text = writable(undefined);
InformationBoxStore.isLightTheme = writable(false);
InformationBoxStore.boxWidth = writable(10);
InformationBoxStore.position = writable({ x: 0, y: 0 });
InformationBoxStore.offset = writable({ x: 0, y: 0 });
InformationBoxStore.isOpen = writable(false);
export default InformationBoxStore;
