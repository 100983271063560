import { alertUser, btConnection, DeviceRequestStates, informUser, outputBluetooth, outputting, settings, state } from "../common";
import { t } from "../../i18n";
import MBSpecs from "../microbit-interfacing/MBSpecs";
import { get, writable } from "svelte/store";
import connectionBehaviours from "./ConnectionBehaviours";
let text;
t.subscribe(t => text = t);
class OutputBehaviour {
    // Writing to sound
    static sendToSound(sound) {
        this.sendToUART("s", sound).then(void 0);
    }
    // Writing to matrix-display on output Microbit
    static setMatrixTo(matrix) {
        const services = this.getBt();
        if (!services || !(get(settings).output))
            return;
        const view = new DataView(new ArrayBuffer(5));
        for (let i = 0; i < 5; i++) {
            view.setUint8(i, this.subarray(matrix, (0 + i * 5), (5 + i * 5)).reduce((byte, bool) => byte << 1 | (bool ? 1 : 0), 0));
        }
        outputting.set({ text: "Update matrix" });
        this.addToQueue(services.matrix, view);
    }
    // Writing to pins.
    static async sendToPin(data) {
        const services = this.getBt();
        if (!services || !(get(settings).output))
            return;
        const view = new DataView(new ArrayBuffer(data.length * 2));
        data.forEach((point, index) => {
            view.setInt8(index * 2 + 0, point.pin);
            view.setInt8(index * 2 + 1, point.on ? 1 : 0);
            outputting.set({ text: `Turn pin ${point.pin} ${point.on ? "on" : "off"}` });
        });
        this.addToQueue(services.io, view);
    }
    // Add to queue. Both service and output
    static addToQueue(service, view) {
        this.queue.update(update => {
            update.queue.push({ service, view });
            return update;
        });
        this.process();
    }
    static process() {
        if (!get(state).isOutputting)
            return;
        if (get(this.queue).busy || get(this.queue).queue.length == 0)
            return;
        get(this.queue).busy = true;
        const { service, view } = get(this.queue).queue.shift();
        if (!service) {
            console.log("NO SERVICE WAS FOUND");
            return;
        }
        service.writeValue(view).then(() => {
            get(this.queue).busy = false;
            this.process();
        }).catch(err => {
            // Catches a characteristic not found error, preventing further output.
            // Why does this happens is not clear.
            // console.log(err);
            get(this.queue).busy = false;
            this.process();
        });
    }
    static subarray(arr, start, end) {
        const newArr = [];
        for (let i = start; i < end; i++) {
            newArr.push(arr[i]);
        }
        return newArr;
    }
    // Return bluetooth
    static getBt() {
        const bt = get(outputBluetooth);
        if (bt === undefined)
            return false;
        return bt;
    }
    // Writing to UART
    static async sendToUART(type, value) {
        const services = this.getBt();
        if (!services || !(get(settings).output))
            return;
        const view = new DataView(new ArrayBuffer(2 + value.length));
        view.setUint8(0, type.charCodeAt(0));
        for (let i = 0; i < value.length; i++) {
            view.setUint8(i + 1, value.charCodeAt(i));
        }
        view.setUint8(1 + value.length, "#".charCodeAt(0));
        this.addToQueue(services.uart, view);
    }
    async bluetoothConnect(microbitBluetooth, name) {
        informUser(text("alert.output.GATTserverInform"));
        btConnection.update(btC => {
            btC.outputMb = microbitBluetooth;
            btC.outputName = name;
            btC.outputVersion = microbitBluetooth.getVersion();
            return btC;
        });
        const btServices = {};
        btServices.device = microbitBluetooth.getDevice();
        informUser(text("alert.output.microBitServiceInform"));
        const ioService = await microbitBluetooth.getIOService();
        const matrixService = await microbitBluetooth.getLEDService();
        const uartService = await microbitBluetooth.getUARTService();
        informUser(text("alert.output.connectingToComponents"));
        btServices.io = await ioService.getCharacteristic(MBSpecs.Characteristics.IO_DATA);
        btServices.matrix = await matrixService.getCharacteristic(MBSpecs.Characteristics.LED_MATRIX_STATE);
        btServices.uart = await uartService.getCharacteristic(MBSpecs.Characteristics.UART_DATA_RX);
        outputBluetooth.set(btServices);
        state.update((s) => {
            s.isOutputting = true;
            s.isRequestingDevice = DeviceRequestStates.NONE;
            return s;
        });
        microbitBluetooth.listenForDisconnect(OutputBehaviour.dcListener);
        informUser(text("alert.output.nowConnectedInform"));
    }
    bluetoothDisconnect(manual) {
        if (!OutputBehaviour.getBt()) {
            alertUser(text("alert.output.isDisconnectedInform"));
            return;
        }
        // If the micro:bit is disconnected fully, it will override this with a sad smiley.
        // Only applies when the same micro:bit is used for both output/input
        this.setGladSmiley().then(void 0);
        const btCon = get(btConnection);
        btCon.outputMb.removeDisconnectListener(OutputBehaviour.dcListener);
        if (btCon.outputName !== btCon.inputName) {
            if (btCon.outputMb) {
                btCon.outputMb.disconnect();
            }
        }
        btConnection.update(btC => {
            btC.outputMb = undefined;
            btC.outputName = "";
            return btC;
        });
        outputBluetooth.set(undefined);
        // Ensure state is updated
        state.update((s) => {
            s.isOutputting = false;
            return s;
        });
        // Disconnect and remove object. Removes all trails of previous connection.
        alertUser(text("alert.output.outputDetached"));
    }
    bluetoothError(error) {
        if (error)
            alertUser(error);
        outputBluetooth.set(undefined);
        state.update((s) => {
            s.isOutputting = false;
            return s;
        });
    }
    openPrompt() {
    }
    usbConnect(microbitUSB) {
    }
    async setGladSmiley() {
        const mb = get(btConnection).outputMb;
        if (!mb)
            return;
        if (!mb.isConnected())
            return;
        try {
            await mb.setLEDMatrix([
                [0, 0, 0, 0, 0],
                [0, 1, 0, 1, 0],
                [0, 0, 0, 0, 0],
                [1, 0, 0, 0, 1],
                [0, 1, 1, 1, 0]
            ]);
        }
        catch (e) {
            console.log(e);
        }
    }
}
// Writable for the queue
OutputBehaviour.queue = writable({
    busy: false,
    queue: []
});
OutputBehaviour.dcListener = () => {
    alertUser(text("alert.output.outputDetached"));
    connectionBehaviours.getOutputBehaviour().bluetoothError(undefined);
};
export default OutputBehaviour;
