<script>
	import { fly, slide } from "svelte/transition";
	import StdButton from "../stdbutton/StdButton.svelte";
	import CookieManager from "../../script/CookieManager";
	import { t } from "../../i18n";
	import { openCookieDescription } from "../../script/modal/cookieDescription";

	let enabled = !CookieManager.isComplianceSet();
	let cookieOverlayEnabled = false;

	const acceptClicked = () => {
		CookieManager.setComplianceChoices({
			necessary: true,
			analytics: true
		});
		enabled = false;
	};
	const rejectClicked = () => {
		CookieManager.setComplianceChoices({
			necessary: true,
			analytics: false
		});
		enabled = false;
	};
</script>

{#if enabled}
	<div out:slide class="absolute z-1 w-full h-full bg-black/50">
		<!-- <CookieDescriptionOverlay isEnabled={cookieOverlayEnabled} onClose={() =>cookieOverlayEnabled=false} /> -->
		<div out:fly class="fixed bottom-0 w-full bg-white flex">
			<div class="flex w-full">
				<div class="flex w-8/10 flex-col">
					<div class="ml-auto mr-auto mt-2">
						<p class="font-bold text-red-400 text-2xl">{$t("cookies.banner.title")}</p>
					</div>
					<div class="mr-20 ml-20 mt-auto mb-auto">
						<p class="font-bold text-xl mb-2">
							{$t("cookies.banner.subtitle")}
						</p>
						<p>
							{$t("cookies.banner.text.pleaseHelp")}
						</p>
						<p>
							{$t("cookies.banner.text.description")}
						</p>
						<div class="mb-2"></div>
						<p>
							{$t("cookies.banner.text.readMore")}<a href="/"
																										 on:click={(e) => {e.preventDefault(); openCookieDescription(); /*cookieOverlayEnabled=true*/}}
																										 class="text-blue-500 font-bold">{$t("cookies.banner.text.readMore.here")}</a>.
						</p>
						<div class="mb-2"></div>
					</div>
				</div>
				<div class="flex w-1/4 flex-row">
					<div class="flex-1 flex-col m-auto pl-4 pr-4">
						<StdButton variant="green" pingable={true} onClicked={acceptClicked}>
							<p>{$t("cookies.banner.buttons.accept")}</p></StdButton>
					</div>
					<div class="flex-1 flex-col m-auto pl-4 pr-4">
						<StdButton variant="white" pingable={true} onClicked={rejectClicked}>
							<p>{$t("cookies.banner.buttons.reject")}</p></StdButton>
					</div>
				</div>
			</div>
		</div>
	</div>
{/if}