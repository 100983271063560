<script>
    //export let triggered;
    import OutputBehaviour from "../../script/connection-behaviours/OutputBehaviour";

    // TODO: Generalize such that it becomes ConnectionBehaviour.setMatrixTo() instead
    export const trigger = () => OutputBehaviour.setMatrixTo(matrix);

    /** Save details about output */
    export let gesture = undefined;

    let matrix = gesture.output?.matrix || [];

    // If matrix is new. Create empty matrix
    if (matrix.length < 25) {
        for (let i = 0; i < 25; i++) {
            matrix.push(false);
        }
    }

    // Save matrix to output
    $: gesture.output.matrix = matrix;

    // Variable for saving the current type-of-click
    // This helps when users drag to draw on the 5x5 grid
    let setElementTo = true;

    // When clicked. Use setElementTo to remember what elements
    // Should be set to
    function elementClick(i) {
        setElementTo = !matrix[i];
        elementHover(i);
    }

    // When user hovers over a box. If user is clicking:
    // Set that matrix to true/false depending on last
    // click
    function elementHover(i, e = undefined) {
        if (e) {
            if (e.buttons !== 1) return;
        }
        matrix[i] = setElementTo;
    }

    // $: act(triggered);
</script>

<main class="buttonGrid h-24 w-24 select-none ml-4">
    <!-- Draw all 25 boxes -->
    {#each matrix as button, i}
        <div
                class="{button ? 'bg-blue-400' : 'bg-gray-300'} rounded transition ease"
                class:turnedOn={button}
                class:turnedOff={!button}
                on:mousedown={() => {
        elementClick(i);
      }}
                on:mouseenter={(e) => {
        elementHover(i, e);
      }}
        />
    {/each}
</main>

<style>
    .buttonGrid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        gap: 2px 2px;
        grid-template-areas:
      ". . . . ."
      ". . . . ."
      ". . . . ."
      ". . . . ."
      ". . . . .";
    }

    .turnedOn {
        animation: turnOn 0.3s ease;
    }

    .turnedOff {
        animation: turnOff 0.3s ease;
    }

    @keyframes turnOn {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.15);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes turnOff {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(0.8);
        }
        100% {
            transform: scale(1);
        }
    }
</style>
