<script>
    import { onMount } from "svelte";

    export let config = undefined;

    export let chart = undefined;
    let canvas;
    onMount(() => {
        chart = new Chart(canvas.getContext("2d"), config);
    });
</script>

<canvas bind:this={canvas} id="myChart" />
