import { get } from 'svelte/store';
import { alertUser, gestures, informUser, notReady, settings } from './common';
import { t } from "../i18n";
let text;
t.subscribe(t => text = t);
const MLcode = '8439cc83-e931-4f51-919c-a420ca514314';
export function uploadClickEvent(e) {
    if (notReady(false))
        return;
    if (get(gestures).length !== 0) {
        if (!window.confirm(text("alert.confirmOverwirte"))) {
            alertUser(text("alert.interruptUpload"));
            return;
        }
    }
    // start reading file. Once read call loadDataToGestures
    const reader = new FileReader();
    reader.readAsText(e.target.files[0]);
    reader.onload = loadDataToGestures;
}
// Function loads output of fileReader and adds to gestures.
export function loadDataToGestures(e) {
    const upload = JSON.parse(e.target.result);
    // Is upload file an array of gestures. Ensure that the file is from this website
    if (upload.id !== MLcode || !upload.data) {
        informUser(text("alert.wrongFilmeFormat"));
        return;
    }
    // Remove previous confidence levels
    upload.data.forEach((gesture) => {
        if (gesture.confidence) {
            delete gesture.confidence;
        }
    });
    if (upload === null || upload === void 0 ? void 0 : upload.settings) {
        settings.set(upload.settings);
    }
    // Overwrite current gestures
    gestures.set(upload.data);
    informUser(text("alert.updateGestures"));
}
// Returns an eventhandler handling downloading of gestures
export function downloadClickEvent() {
    return (e) => {
        // Make object with MLCode (UUID that indicates that this is gestures)
        const data = get(gestures);
        if (data.length === 0) {
            informUser(text("alert.noGesturesToSave"));
            return;
        }
        informUser(text("alert.savingProject"));
        const object = {
            id: MLcode,
            data,
            settings: get(settings),
        };
        // Make element with downloadlink
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(object)));
        element.setAttribute('download', 'data.txt');
        element.style.display = 'none';
        document.body.appendChild(element);
        // Click element to trigger downloadlink
        element.click();
        document.body.removeChild(element);
    };
}
