import MicrobitUSB from "./MicrobitUSB";
import MBSpecs from "./MBSpecs";
export class MicrobitUSBEvents {
}
MicrobitUSBEvents.microbitUSBDeviceFound = new Event("microbitUSBDeviceFound");
MicrobitUSBEvents.microbitUSBConnected = (microbitUSB) => {
    return new CustomEvent("microbitUSBConnected", {
        detail: { microbitUSB: microbitUSB }
    });
};
MicrobitUSBEvents.microbitUSBFlashBegin = (microbitUSB) => {
    return new CustomEvent("microbitUSBFlashBegin", {
        detail: { microbitUSB: microbitUSB }
    });
};
MicrobitUSBEvents.microbitUSBFlashEnd = (microbitUSB) => {
    return new CustomEvent("microbitUSBFlashEnd", {
        detail: { microbitUSB: microbitUSB }
    });
};
/**
 * A USB connection to a micro:bit.
 */
class EventEmittingMicrobitUSB extends MicrobitUSB {
    constructor(usbDevice) {
        super(usbDevice);
        document.dispatchEvent(MicrobitUSBEvents.microbitUSBConnected(this));
    }
    /**
     * Open prompt for USB connection.
     * @returns {Promise<MicrobitUSB>} A promise that resolves to a new MicrobitUSB object.
     */
    static async eventEmittingRequestConnection() {
        const requestOptions = {
            filters: [
                {
                    vendorId: MBSpecs.USBSpecs.VENDOR_ID,
                    productId: MBSpecs.USBSpecs.PRODUCT_ID
                }
            ]
        };
        try {
            const device = await navigator.usb.requestDevice(requestOptions);
            document.dispatchEvent(MicrobitUSBEvents.microbitUSBDeviceFound);
            return new EventEmittingMicrobitUSB(device);
        }
        catch (e) {
            console.log(e);
            return undefined;
        }
    }
    /**
     * Flashes a .hex file to the micro:bit.
     * @param {string} hex The hex file to flash. (As a link)
     * @param {(progress: number) => void} progressCallback A callback for progress.
     * @returns Whether the flash was successful or not.
     */
    async flashHex(hex, progressCallback) {
        document.dispatchEvent(MicrobitUSBEvents.microbitUSBFlashBegin(this));
        const result = await super.flashHex(hex, progressCallback);
        document.dispatchEvent(MicrobitUSBEvents.microbitUSBFlashEnd(this));
        return result;
    }
}
export default EventEmittingMicrobitUSB;
