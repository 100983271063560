<script lang="ts">
	import StdButton from "../../../stdbutton/StdButton.svelte";
	import { t } from "../../../../i18n";

	export let continueClicked: () => void;

</script>

<div>
	<div class="flex-row">
		<div class="flex">
			<div>
				<p>{$t("popup.connectMB.USBCompatibility.connectStep.useCable")}</p>
			</div>
			<div class="m-auto w-32 justify-center content-center">
				<i class="fab fa-usb w-full text-center text-gray-600 fa-align-center"
					 style="font-size: 70px"></i>
			</div>
		</div>
		<div class="flex-row flex-1">
			<div class="w-34 m-auto">
				<StdButton onClicked={continueClicked} pingable>{$t("popup.connectMB.USBCompatibility.continue")}</StdButton>
			</div>
		</div>
	</div>
</div>