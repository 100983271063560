<script lang="ts">
	import type MicrobitUSB from "../../script/microbit-interfacing/MicrobitUSB";
	import { t } from "../../i18n";
	import { btConnection, compatibility, DeviceRequestStates, state } from "../../script/common";
	import ConnectionBehaviours from "../../script/connection-behaviours/ConnectionBehaviours";
	import type ConnectionBehaviour from "../../script/connection-behaviours/ConnectionBehaviour";
	import StdButton from "../stdbutton/StdButton.svelte";
	import EventEmittingMicrobitUSB from "../../script/microbit-interfacing/EventEmittingMicrobitUSB";

	// Callback functions
	export let onFindUSB: () => void = undefined;
	export let onMicrobitUSBFound: (device: MicrobitUSB) => void = undefined;
	export let onConnectWithBluetooth: () => void = undefined;
	export let onBluetoothConnected: () => void;
	export let onUSBIncompatible: () => void;
	export let disableUsb: boolean = false;

	let isUSBConnected: boolean = undefined; // Leave it undefined to differentiate 'not-known' from disconnected

	let isSomeDeviceAlreadyConnected: boolean =
		$state.isOutputting || $state.isConnected;
	let showUsingSameDeviceDialog: boolean = isSomeDeviceAlreadyConnected;

	const isUSBCompatible = $compatibility.usb;

	// Events
	const microbitUSBFound = (device: MicrobitUSB) => {
		if (onMicrobitUSBFound) {
			onMicrobitUSBFound(device);
		}
	};

	const getBehaviour = (
		currentState: DeviceRequestStates
	): ConnectionBehaviour => {
		if (currentState == DeviceRequestStates.INPUT) {
			return ConnectionBehaviours.getInputBehaviour();
		}
		if (currentState == DeviceRequestStates.OUTPUT) {
			return ConnectionBehaviours.getOutputBehaviour();
		}
		return undefined;
	};

	const useSameButtonClicked = async () => {
		const otherMb = () => {
			switch ($state.isRequestingDevice) {
				case DeviceRequestStates.INPUT:
					return $btConnection.outputMb;
				case DeviceRequestStates.OUTPUT:
					return $btConnection.inputMb;
			}
		};
		const otherName = () => {
			switch ($state.isRequestingDevice) {
				case DeviceRequestStates.INPUT:
					return $btConnection.outputName;
				case DeviceRequestStates.OUTPUT:
					return $btConnection.inputName;
			}
		};
		const behaviour = getBehaviour($state.isRequestingDevice);
		behaviour.bluetoothConnect(otherMb(), otherName());
		onBluetoothConnected();
	};

	const findUSBClicked = () => {
		if (!isUSBCompatible) {
			onUSBIncompatible();
			return;
		}
		if (onFindUSB) {
			onFindUSB();
		}
		EventEmittingMicrobitUSB.eventEmittingRequestConnection()
			.then((device: MicrobitUSB) => {
				if (device) {
					microbitUSBFound(device);
					getBehaviour($state.isRequestingDevice).usbConnect(device);
				}
			})
			.catch((error: Error) => {
				console.log(error);
			});
	};

	const connectWithBluetoothClicked = () => {
		if (onConnectWithBluetooth) {
			onConnectWithBluetooth();
		}
	};
</script>

<div class="p-6">
	<!-- TITLE -->
	<div class="pb-0.5 mb-0.5 border-solid border-b-1">
		<p class="text-2xl font-bold text-red-500">
			{$t("popup.connectMB.main.heading")}
		</p>
	</div>

	<!-- CONTENT -->
	{#if showUsingSameDeviceDialog}
		<div class="flex">
			<div class="flex-1 mr-2 mb-2">
				<div class="text-blue-500 mb-2 text-xl text-bold">
					<p>{$t("popup.connectMB.main.alreadyConnected.heading")}</p>
				</div>
				<p>{$t("popup.connectMB.main.alreadyConnected.mainText")}</p>
			</div>
		</div>
		<div class="flex">
			<div class="flex-1 mr-2">
				<div class="flex flex-row justify-center align-middle">
					<StdButton onClicked={useSameButtonClicked}>
						<p>{$t("popup.connectMB.main.alreadyConnected.useSameButton")}</p>
					</StdButton>
				</div>
			</div>
			<div class="flex-1 mr-2">
				<div class="flex flex-row justify-center align-middle">
					<StdButton
						onClicked={() => {
              showUsingSameDeviceDialog = false;
            }}
					>
						<p>{$t("popup.connectMB.main.alreadyConnected.useOtherButton")}</p>
					</StdButton>
				</div>
			</div>
		</div>
	{/if}
	{#if !showUsingSameDeviceDialog}
		<div class="flex">
			<!-- USB -->
			{#if !disableUsb}
				<div class="flex flex-col gap-2 mr-4 justify-between">
					<div>
						<div class="text-blue-500 mb-2 text-xl text-bold">
							<p>{$t("popup.connectMB.main.usb.subtitle")}</p>
						</div>
						<table>
							<tr>
								<td> 1.</td>
								<td>
									{$t("popup.connectMB.main.usb.step1")}
								</td>
							</tr>
							<tr>
								<td> 2.</td>
								<td>
									{$t("popup.connectMB.main.usb.step2")}
								</td>
							</tr>
							<tr>
								<td> 3.</td>
								<td>
									{$t("popup.connectMB.main.usb.step3")}
								</td>
							</tr>
						</table>
					</div>
					<div class="flex flex-row justify-center align-middle">
						<StdButton onClicked={findUSBClicked}>
							<p>{$t("popup.connectMB.main.usb.connect")}</p>
						</StdButton>
					</div>
				</div>
			{/if}
			<!-- BLUETOOTH -->
			{#if !isUSBConnected}
				<div class="flex flex-col gap-2 justify-between">
					<div>
						<div class="text-blue-500 mb-2 text-xl text-bold">
							<p>{$t("popup.connectMB.main.bluetooth.subtitle")}</p>
						</div>
						<table>
							<tr>
								<td> 1.</td>
								<td>
									{$t("popup.connectMB.main.bluetooth.step1")}
								</td>
							</tr>
							<tr>
								<td> 2.</td>
								<td>
									{$t("popup.connectMB.main.bluetooth.step2")}
								</td>
							</tr>
						</table>
					</div>
					<div class="flex flex-row justify-center align-middle">
						<StdButton onClicked={connectWithBluetoothClicked}>
							<p>{$t("popup.connectMB.main.bluetooth.connect")}</p>
						</StdButton>
					</div>
				</div>
			{/if}
		</div>
	{/if}
</div>
