<script>
	import { fade } from "svelte/transition";

	export let expanded = false;
</script>

<main
	class="fixed z-1 h-12 shadow-dark-800 shadow-lg flex min-w-full grow items-center bg-warm-gray-100"
>
	<div class="w-10">
		<i
			aria-hidden="true"
			class="{expanded ? 'fa fa-times' : 'fa fa-bars'} ml-4 cursor-pointer"
			on:click={() => (expanded = !expanded)}
			transition:fade
		/>
	</div>
	{#if expanded}
		<main class="flex items-center" transition:fade>
			<slot />
		</main>
	{/if}
</main>
