<script lang="ts">
	import Controlbar from "../components/Controlbar.svelte";
	import ControlbarBox from "../components/ControlbarBox.svelte";
	import { locale, locales, t } from "../i18n";
</script>

<!-- Controlbar -->
<Controlbar expanded="true">
	<ControlbarBox>
		<select bind:value={$locale} class="bg-warm-gray-100">
			{#each locales as l}
				<option value={l}>{l}</option>
			{/each}
		</select>
	</ControlbarBox>
</Controlbar>
<main
	class="p-20 pt-5 scrollable mt-10"
>
	<!-- TITLE -->
	<h1 class="font-semibold text-3xl mb-4">
		{$t("content.index.heading")}
	</h1>
	<div class="grid-container">
		<div
			class="a border-gray-200 border border-solid bg-white bg-opacity-75 rounded-lg p-6"
		>
			<p class="text-lg mb-2 font-semibold">
				{$t("content.index.setupHeading")}
			</p>
			<p>
				{$t("content.index.setupBody1")}
			</p>
			<div class="a flex justify-around gap-1 mt-6 mçb-6">
				<div class="flex-col">
					<img
						alt="microbit version 1"
						src="microbitV1.svg"
						width="200px"
					/>
					<p class="text-center">
						{$t("content.index.v1")}
					</p>
				</div>
				<div class="flex-col">
					<img
						alt="microbit version 2"
						src="microbitV2.svg"
						width="200px"
					/>
					<p class="text-center">
						{$t("content.index.v2")}
					</p>
				</div>
			</div>
			<p>
				{$t("content.index.setupBody2")}
			</p>
		</div>

		<div
			class="b border-gray-200 border border-solid bg-white bg-opacity-75 rounded-lg p-6"
		>
			<p class="text-lg mb-2 font-semibold">
				{$t("content.index.howHeading")}
			</p>
			<p>{$t("content.index.howBody")}</p>
		</div>
		<div
			class="c border-gray-200 border border-solid bg-white bg-opacity-75 rounded-lg p-6"
		>
			<p class="text-lg mb-2 font-semibold">
				{$t("content.index.contactHeading")}
			</p>
			<p>{$t("content.index.contactBody")}</p>
		</div>
	</div>

	<!-- SUBTITLE -->
	<div class="mt-4 w-full text-right">
		<p>
			{$t("content.index.byline")} -
			<a class="text-blue-400 underline" href="https://cctd.au.dk/"
			>cctd.dk/</a
			>
		</p>
	</div>
</main>

<style>
    .grid-container {
        display: grid;
        gap: 1rem 1rem;
        grid-template-areas:
            "a b"
            "a c";
    }

    .a {
        grid-area: a;
    }

    .b {
        grid-area: b;
    }

    .c {
        grid-area: c;
    }

</style>
