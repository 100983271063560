<script>
    import ParallelCPlotD3 from "../components/graphs/ParallelCPlotD3.svelte";
    import {alertUser, gestures, informUser, livedata, notReady, settings, state,} from "../script/common";
    import {get} from "svelte/store";
    import {peaks, standardDeviation, totalAcceleration,} from "../script/datafunctions";
    import Controlbar from "../components/Controlbar.svelte";
    import ControlbarBox from "../components/ControlbarBox.svelte";
    import Recording from "../components/graphs/RecordingGraph.svelte";
    import InformationBoxStore from "../components/information/Information.svelte";
    import {onDestroy, onMount} from "svelte";
    import {t} from "../i18n";
    import Information from "../components/information/Information.svelte";

    $: hidePage = isGesturesEmpty($gestures);

    function isGesturesEmpty(gestures) {
        if (gestures.length === 0) return true;

        let isEmpty = true;
        gestures.forEach((g) => {
            if (g?.recordings?.length !== 0) {
                isEmpty = false;
            }
        });

        return isEmpty;
    }

    let iAmRecording = false;
    let hasRecorded = false;
    let recording;

    function max(array) {
        return Math.max(...array);
    }

    function min(array) {
        return Math.min(...array);
    }

    function numPeaks(array) {
        return peaks(array).numPeaks;
    }

    let filters;

    t.subscribe((text) => {
        filters = [
            {
                name: text("content.trainer.filters.max.heading"),
                key: "maxValues",
                method: max,
                desc: text("content.trainer.filter.max.helpBody"),
            },
            {
                name: text("content.trainer.filters.min.heading"),
                key: "minValues",
                method: min,
                desc: text("content.trainer.filters.min.helpBody"),
            },
            {
                name: text("content.trainer.filters.standardDev.heading"),
                key: "standardDeviation",
                method: standardDeviation,
                desc: text("content.trainer.filter.standardDev.helpBody"),
            },
            {
                name: text("content.trainer.filter.extremes.heading"),
                key: "extremes",
                method: numPeaks,
                desc: text("content.trainer.filter.extremes.helpBody"),
            },
            {
                name: text("content.trainer.filter.acc.heading"),
                key: "acceleration",
                method: totalAcceleration,
                desc: text("content.trainer.filter.acc.helpBody"),
            },
        ];
    });

    let dataRep = {
        maxValues: [],
        minValues: [],
        standardDeviation: [],
        extremes: [],
        acceleration: [],
    };
    let classes = [];

    get(gestures).forEach((gestureClass) => {
        classes.push(gestureClass.name);
        gestureClass.recordings.forEach((recording) => {
            filters.forEach((filter) => {
                let gestureRep = {class: gestureClass.name};
                ["x", "y", "z"].forEach((axis) => {
                    gestureRep[axis] = filter.method(recording.data[axis]);
                });
                dataRep[filter.key].push(gestureRep);
            });
        });
    });

    const color = d3.scaleOrdinal().domain(classes).range(d3.schemeSet2);

    // Highlight the class that is hovered
    const highlight = function (d) {
        let selected_class = d.class;

        // first every group turns grey
        d3.selectAll(".line")
            .transition()
            .duration(200)
            .style("stroke", "lightgrey")
            .style("opacity", "0.2");
        // Second the hovered class takes its color
        d3.selectAll("." + selected_class)
            .transition()
            .duration(200)
            .style("stroke", color(selected_class))
            .style("opacity", 0.5);
    };

    // Unhighlight
    const doNotHighlight = function () {
        d3.selectAll(".line")
            .transition()
            .duration(200)
            .delay(200)
            .style("stroke", function (d) {
                return color(d.class);
            })
            .style("opacity", 0.5);
    };

    //----- RECORD GESTURE -------
    function addRecording(e) {
        if (notReady()) return;

        $state.isTesting = true;
        informUser($t("menu.trainer.filters.isRecording"));
        iAmRecording = true;

        // Get duration
        const duration = get(settings).duration;

        // New array for data
        let newData = {x: [], y: [], z: []};

        // Set timeout to allow recording in 1s
        const unsubscribe = livedata.subscribe((data) => {
            newData.x.push(data.accelX);
            newData.y.push(data.accelY);
            newData.z.push(data.accelZ);
        });

        // Once duration is over (1000ms default), stop recording
        setTimeout(() => {
            $state.isTesting = false;
            iAmRecording = false;
            unsubscribe();
            // TODO: Remove the hardcoded duration (replace with config?)
            if (48 <= newData.x.length) {
                recording = null;

                setTimeout(() => {
                    recording = newData;
                }, 25);

                addToRepresentation(newData);
                informUser($t("menu.trainer.filters.finishedRecording"));
            } else {
                alertUser($t("content.trainer.testCenter.alert.disconnectDuringRecording"));
            }
        }, duration);
    }

    function addToRepresentation(gestureData) {
        if (hasRecorded) {
            filters.forEach((filter) => {
                dataRep[filter.name].pop();
            });
            classes.pop();
        } else hasRecorded = true;

        // ConvertToRepresentation and add data to plot
        filters.forEach((filter) => {
            let gestureRep = {class: $t("menu.trainer.filters.recording")};
            ["x", "y", "z"].forEach((axis) => {
                gestureRep[axis] = filter.method(gestureData[axis]);
            });
            dataRep[filter.key].push(gestureRep);
        });
        //Add classes to plot
        classes = [...classes, $t("menu.trainer.filters.recording")];

        //Hightlight
        setTimeout(() => {
            // wait 20 ms to make sure plots have been updated. Todo: Make less hacky
            highlight({class: $t("menu.trainer.filters.recording")});
            setTimeout(() => {
                doNotHighlight();
            }, 1000);
        }, 20);
    }

    // Toggles whether certain filter should be included when training model
    function toggleFilter(i) {
        if (notReady(false)) return;

        $state.isPredicting = false;

        settings.update((settings) => {
            settings.includedParameters[i] = !settings.includedParameters[i];
            return settings;
        });
    }

    let resizeInterval;
    let currentSize;
    onMount(() => {
        resizeInterval = setInterval(() => {
            currentSize = window.visualViewport.width;
        }, 500);
    });
    onDestroy(() => {
        clearInterval(resizeInterval);
    });
</script>

<!-- Top control bar -->
<Controlbar expanded={true}>
    <div
            class="bg-red-600 h-0.5 absolute"
            style="{iAmRecording
      ? 'transition: 1s linear;'
      : ''}width: calc({iAmRecording
      ? 'calc(100vw - 16rem)'
      : '0px'}); transform: translate(0rem, 1.5rem);"
    />
    <ControlbarBox>
        <button
                class="bg-gray-100 text-sm hover:bg-gray-300 py-1 px-2 rounded btn transition ease border border-gray-400"
                on:click={addRecording}>
            {$t("content.trainer.filters.header.recordExampleButton")}
        </button>
    </ControlbarBox>
    <ControlbarBox class="mr-2">
        <p>{$t("content.trainer.filters.header.focus")}</p>
    </ControlbarBox>

    {#each classes as c}
        <ControlbarBox>
            <div
                    class="py-1 px-4 rounded-md btn transition ease border select-none focusElement"
                    style="background-color: {color(c)};"
                    on:mouseenter={() => highlight({ class: c })}
                    on:mouseleave={doNotHighlight}
            >
                {c.substring(0, (currentSize - 800) / (15 * classes.length))}
            </div>
        </ControlbarBox>
    {/each}
</Controlbar>

<!-- In case of no gestures, advise user to record some -->
{#if hidePage}
    <div
            class="absolute z-20"
            style="width: calc(100vw - 20rem); height: calc(100vh - 12.2rem)"
    >
        <div class="flex flex-col h-100 justify-center">
            <p class="text-center font-medium text-3xl">
                {$t("content.trainer.filters.recordExamplesFirstHeading")}
            </p>
            <p class="text-center">
                {$t("content.trainer.filters.recordExamplesFirstBody")}
            </p>
        </div>
    </div>
{/if}

<main class="pl-7 w-full h-full gridLayout mt-13" class:opacity-15={hidePage}>
    <!-- For each filter, display box with filter possiblity -->
    {#each filters as filter, i}
        <div
                class="bg-white overflow-hidden rounded-lg m-2 relative {$settings
        .includedParameters[i]
        ? 'shadow-lg'
        : ''}"
                id={"filter-card-" + i}
        >
            <div class="filter flex justify-between">
                <div class="flex flex-row">
                    <h2
                            class="m-2 ml-10 line-through"
                            class:line-through={!$settings.includedParameters[i]}
                    >
                        {filter.name}
                        <Information
                                text={filter.desc}
                                iconOffset={{x:-20,y:-25}}
                                boxOffset={{x:-20, y:20}}
                                title={filter.name}
                                width={14}
                        />
                    </h2>
                </div>
                <!-- Deleting button -->

                <div class="mr-2 mt-2 cursor-pointer" on:click={() => toggleFilter(i)}>
                    <i
                            class="fa-lg transition ease {$settings.includedParameters[i]
              ? 'far fa-times-circle text-red-500 hover:(transform scale-150)'
              : 'fas fa-plus-circle text-lime-600 hover:(transform scale-150)'}"
                    />
                </div>
            </div>
            <div class="w-8 h-8 autoscaling"/>
            <div class="autoscaling">
                <ParallelCPlotD3
                        id={i}
                        dataRep={dataRep[filter.key]}
                        {classes}
                        {color}
                        {highlight}
                        {doNotHighlight}
                />
            </div>
            <div
                    class="absolute w-full h-full top-0 left-0 z-10 bg-gray-100 grey-shader bg-opacity-60 pointer-events-none {!$settings
          .includedParameters[i] || hidePage
          ? 'block'
          : 'hidden'}"
            />
        </div>
    {/each}

    <!-- Show recording if user has recorded-->
    <div class="bg-white overflow-hidden rounded-lg m-2 relative shadow-lg">
        {#if recording}
            <h2 class="m-2">{$t("content.trainer.filter.recordingHeading")}</h2>
            <div class="h-56 w-full">
                <Recording data={recording}/>
            </div>
        {:else}
            <div class="w-full h-full justify-center items-center flex">
                <button
                        class="bg-gray-100 text-sm hover:bg-gray-300 py-1 px-2 rounded btn transition ease border border-gray-400"
                        on:click={addRecording}
                >
                    {$t("content.trainer.filter.recordExampleButton")}
                </button>
            </div>
            <div
                    class="absolute w-full h-full top-0 left-0 z-10 bg-gray-100 grey-shader bg-opacity-60 pointer-events-none {hidePage
          ? 'block'
          : 'hidden'}"
            />
        {/if}
    </div>

    <!-- fade -->
    <div class="h-16 w-full"/>
</main>

<style>
    .focusElement:hover {
        filter: brightness(1.4);
        transform: scale(1.2);
    }

    .focusElement {
        filter: brightness(1.2);
        transition: 0.2s ease;
    }

    .gridLayout {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1rem;
    }
</style>
