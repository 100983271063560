/// <reference types="web-bluetooth" />
import Bowser from "bowser";
export class SemVerImpl {
    constructor(major, minor) {
        this.majorVersion = major;
        this.minorVersion = minor;
    }
    static compareSubVersions(subVersion1, subVersion2) {
        let subVersionNum1 = subVersion1;
        let subVersionNum2 = subVersion2;
        if (typeof subVersion1 === "string") {
            if (subVersion1 === "*")
                return 0;
            subVersionNum1 = parseInt(subVersion1);
        }
        if (typeof subVersion2 === "string") {
            if (subVersion2 === "*")
                return 0;
            subVersionNum2 = parseInt(subVersion2);
        }
        const comparison = Math.sign(subVersionNum1 - subVersionNum2);
        console.assert(!isNaN(comparison));
        return comparison;
    }
    isGreaterThan(version) {
        const compareMajor = SemVerImpl.compareSubVersions(this.majorVersion, version.majorVersion);
        const compareMinor = SemVerImpl.compareSubVersions(this.minorVersion, version.minorVersion);
        if (compareMajor < 0)
            return false;
        if (compareMajor > 0)
            return true;
        return compareMinor > 0;
    }
    isEqualTo(version) {
        const compareMajor = SemVerImpl.compareSubVersions(this.majorVersion, version.majorVersion);
        const compareMinor = SemVerImpl.compareSubVersions(this.minorVersion, version.minorVersion);
        return compareMajor == 0 && compareMinor == 0;
    }
    isLessThan(version) {
        return !this.isGreaterThan(version) && !this.isEqualTo(version);
    }
    toString() {
        const maj = parseInt(this.majorVersion.toString());
        const min = parseInt(this.minorVersion.toString());
        if (isNaN(min)) {
            return maj.toString();
        }
        return maj.toString() + "." + min.toString();
    }
}
class CompatibilityList {
    static getMinVersion(browserName) {
        return this.minVersions.find(value => value.browser.toLowerCase() === browserName.toLowerCase()).version;
    }
    static getSupportedBrowsers() {
        return this.minVersions;
    }
    static isVersionExempted(browserName, version, operatingSystem) {
        return this.exceptions
            .filter(value => value.browser.toLowerCase() == browserName.toLowerCase())
            .filter(value => value.os.toLowerCase() == operatingSystem.toLowerCase())
            .filter(value => value.version.isEqualTo(version)).length > 0;
    }
    static isVersionAtLeastMin(browserName, version) {
        return this.minVersions.filter(value => value.browser == browserName)
            .filter(value => value.version.isEqualTo(version)
            || value.version.isLessThan(version))
            .length > 0;
    }
    static isBrowserSupported(browserName) {
        return this.minVersions.filter(value => value.browser.toLowerCase() === browserName.toLowerCase()).length != 0;
    }
    static isVersionSupported(browserName, version, operatingSystem) {
        return !this.isVersionExempted(browserName, version, operatingSystem)
            && this.isVersionAtLeastMin(browserName, version);
    }
    static isSupported(browserName, version, operatingSystem) {
        throw new Error("isSupported is not implemented on this instance of CompatibilityList!");
    }
}
export class WebBluetoothCompatibility extends CompatibilityList {
    static isSupported(browserName, version, operatingSystem) {
        if (!navigator.bluetooth)
            return false;
        return this.isVersionSupported(browserName, version, operatingSystem);
    }
}
WebBluetoothCompatibility.exceptions = [];
WebBluetoothCompatibility.minVersions = [
    {
        browser: Bowser.BROWSER_MAP.chrome,
        version: new SemVerImpl(56, "*")
    }, {
        browser: Bowser.BROWSER_MAP.chromium,
        version: new SemVerImpl(56, "*")
    }, {
        browser: Bowser.BROWSER_MAP.edge,
        version: new SemVerImpl(79, "*")
    }, {
        browser: Bowser.BROWSER_MAP.opera,
        version: new SemVerImpl(43, "*")
    }, {
        browser: Bowser.BROWSER_MAP.android,
        version: new SemVerImpl(105, "*")
    }, {
        browser: Bowser.BROWSER_MAP.samsung_internet,
        version: new SemVerImpl(6, 2)
    }, {
        browser: Bowser.BROWSER_MAP.uc,
        version: new SemVerImpl(13, 4)
    }
];
export class WebUSBCompatibility extends CompatibilityList {
    static isSupported(browserName, version, operatingSystem) {
        if (!navigator.usb)
            return false;
        return this.isVersionSupported(browserName, version, operatingSystem);
    }
}
WebUSBCompatibility.exceptions = [
    {
        browser: Bowser.BROWSER_MAP.chrome,
        version: new SemVerImpl(105, "*"),
        os: Bowser.OS_MAP.ChromeOS
    }
];
WebUSBCompatibility.minVersions = [
    {
        browser: Bowser.BROWSER_MAP.chrome,
        version: new SemVerImpl(61, "*")
    }, {
        browser: Bowser.BROWSER_MAP.chromium,
        version: new SemVerImpl(61, "*")
    }, {
        browser: Bowser.BROWSER_MAP.edge,
        version: new SemVerImpl(79, "*")
    }, {
        browser: Bowser.BROWSER_MAP.opera,
        version: new SemVerImpl(48, "*")
    }, {
        browser: Bowser.BROWSER_MAP.android,
        version: new SemVerImpl(105, "*")
    }, {
        browser: Bowser.BROWSER_MAP.samsung_internet,
        version: new SemVerImpl(8, 2)
    }, {
        browser: Bowser.BROWSER_MAP.uc,
        version: new SemVerImpl(13, 4)
    }
];
