<script lang="ts">
	import ManualTransferTutorial from "./ManualTransferTutorial.svelte";
	import { t } from "../../../i18n";
	import Information from "../../information/Information.svelte";

	export let backToMainPrompt: () => void;
	export let flashFailed: boolean;

	const compatibleBrowsersInformationText = [
		"Chrome",
		"Chromium",
		"Microsoft Edge",
		"Opera",
		"Chrome for Android",
		"Samsung internet",
		"UC Browser",
		$t("popup.connectMB.USBCompatibility.information.note")
	];
	const compatibleBrowsersInformationTitle = $t("popup.connectMB.USBCompatibility.information.title");

	const header = flashFailed ? $t("popup.connectMB.USBCompatibility.flashFail.header") : $t("popup.connectMB.USBCompatibility.header");
</script>

<div class="p-6">
	<!-- TITLE -->
	<div class="pb-0.5 mb-0.5 border-solid border-b-1">
		<div>
			<p class="text-2xl font-bold text-red-500">
				{header}
			</p>
			<div class="relative">
				{#if !flashFailed}
					<Information boxOffset={{x:-20,y:20}}
											 iconOffset={{x:-20,y:-30}}
											 text={compatibleBrowsersInformationText}
											 title={compatibleBrowsersInformationTitle}
											 width={18} />
				{/if}
			</div>
		</div>

	</div>
	<ManualTransferTutorial backToMainPrompt={backToMainPrompt} flashFailed={flashFailed} />
</div>