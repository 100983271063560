import { alertUser, btConnection, buttonPressed, DeviceRequestStates, informUser, livedata, state } from "../common";
import { t } from "../../i18n";
import { get } from "svelte/store";
import MBSpecs from "../microbit-interfacing/MBSpecs";
import ConnectionBehaviours from "./ConnectionBehaviours";
let text;
t.subscribe((t) => (text = t));
/**
 * Implementation of the input ConnectionBehaviour
 */
class InputBehaviour {
    constructor() {
        this.smoothedAccelX = 0;
        this.smoothedAccelY = 0;
        this.smoothedAccelZ = 0;
    }
    async bluetoothConnect(microbitBluetooth, name) {
        informUser(text("alert.micro.GATTserverInform"));
        informUser(text("alert.micro.microBitServiceInform"));
        btConnection.update((btC) => {
            btC.inputMb = microbitBluetooth;
            btC.inputName = name;
            return btC;
        });
        informUser(text("alert.micro.gettingDataInform"));
        try {
            await microbitBluetooth.listenToAccelerometer(this.accelChanged.bind(this));
            await microbitBluetooth.listenToButton("A", this.btnChanged);
            await microbitBluetooth.listenToButton("B", this.btnChanged);
            microbitBluetooth.listenForDisconnect(InputBehaviour.dcListener);
            state.update((s) => {
                s.isConnected = true;
                s.isRequestingDevice = DeviceRequestStates.NONE;
                return s;
            });
        }
        catch (e) {
            alertUser(text("popup.connectMB.alert.failToReadService"));
        }
        informUser(text("alert.micro.nowConnectedInform"));
    }
    bluetoothDisconnect(manual) {
        const btCon = get(btConnection);
        if (btCon.outputName !== btCon.inputName) {
            if (btCon.inputMb) {
                btCon.inputMb.disconnect();
            }
        }
        if (btCon.inputMb) {
            btCon.inputMb.removeDisconnectListener(InputBehaviour.dcListener);
            if (btCon.inputMb.isConnected()) {
                btCon.inputMb.getAccelerometerService().then((service) => {
                    service
                        .getCharacteristic(MBSpecs.Characteristics.ACCEL_DATA)
                        .then((characteristic) => {
                        characteristic.stopNotifications();
                    });
                });
            }
        }
        btConnection.update((btC) => {
            btC.inputMb = undefined;
            btC.inputName = "";
            return btC;
        });
        state.update((s) => {
            s.isConnected = false;
            return s;
        });
        alertUser(text("alert.micro.inputDetached"));
    }
    bluetoothError(error) {
        if (error) {
            alertUser(text("alert.micro.inputDetached"));
        }
        state.update((s) => {
            s.isConnected = false;
            return s;
        });
    }
    openPrompt() {
    }
    usbConnect(microbitUSB) {
    }
    accelChanged(x, y, z) {
        const accelX = x / 1000.0;
        const accelY = y / 1000.0;
        const accelZ = z / 1000.0;
        this.smoothedAccelX = accelX * 0.25 + this.smoothedAccelX * 0.75;
        this.smoothedAccelY = accelY * 0.25 + this.smoothedAccelY * 0.75;
        this.smoothedAccelZ = accelZ * 0.25 + this.smoothedAccelZ * 0.75;
        livedata.set({
            accelX,
            accelY,
            accelZ,
            smoothedAccelX: this.smoothedAccelX,
            smoothedAccelY: this.smoothedAccelY,
            smoothedAccelZ: this.smoothedAccelZ
        });
    }
    btnChanged(btnState, btn) {
        if (btnState === MBSpecs.ButtonStates.Released)
            return;
        // Todo: does longpress affect anything?
        if (btn === "A") {
            buttonPressed.update((obj) => {
                obj.buttonA = 1;
                obj.buttonB = 0;
                return obj;
            });
        }
        else {
            buttonPressed.update((obj) => {
                obj.buttonA = 0;
                obj.buttonB = 1;
                return obj;
            });
        }
    }
}
InputBehaviour.dcListener = () => {
    alertUser(text("alert.micro.inputDetached"));
    ConnectionBehaviours.getInputBehaviour().bluetoothError(undefined);
};
export default InputBehaviour;
