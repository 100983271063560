import { alertUser, gestures, getPrevData, informUser, isParametersLegal, model, notReady, prediction, settings, state, trainingState } from "./common";
import { peaks, standardDeviation, totalAcceleration } from "./datafunctions";
import { get } from "svelte/store";
import { t } from "../i18n";
//import ml5 from "ml5";
let text;
t.subscribe(t => text = t);
// Whenever model is trained, the settings at the time is saved in this variable
// Such that prediction continues on with the same settings as during training
let modelSettings;
// List of parameters that may be injected based on user choice.
const learningParameters = [
    "ax_max",
    "ax_min",
    "ax_std",
    "ax_peaks",
    "ax_total",
    "ay_max",
    "ay_min",
    "ay_std",
    "ay_peaks",
    "ay_total",
    "az_max",
    "az_min",
    "az_std",
    "az_peaks",
    "az_total"
];
// Functioned called when user activates a model-training.
export function trainModel() {
    if (notReady())
        return;
    if (!isTrainingAllowed())
        return;
    informUser(text("alert.beginModelSetup"));
    // Update state to prevent other functions
    state.update(obj => {
        obj.isTraining = true;
        return obj;
    });
    // Create neural network with user-specified settings
    const nn = createModel();
    // Fetch data
    const gestureData = get(gestures);
    // Assess if any points are equal across all data
    gestureData.forEach(type => {
        const output = {
            gesture: String(type.ID)
        };
        type.recordings.forEach(recording => {
            const x = recording.data.x;
            const y = recording.data.y;
            const z = recording.data.z;
            const inputs = makeInputs(x, y, z);
            nn.addData(inputs, output);
        });
    });
    // Normalize data
    nn.normalizeData();
    // Remove faultily normalized data
    nn.data.training.forEach(obj => {
        Object.keys(obj.xs).forEach(key => {
            if (isNaN(obj.xs[key])) {
                obj.xs[key] = 0;
            }
        });
    });
    // Options for training the model
    const trainingOptions = {
        epochs: get(settings).numEpochs
        // batchSize?
    };
    informUser(text("alert.trainingModel"));
    model.set(nn);
    nn.train(trainingOptions, whileTraining, finishedTraining);
    // ML5 opens a console during training. To prevent this, it is set to display=none
    document.getElementById("tfjs-visor-container").style.display = "none";
}
// Assess whether
function isTrainingAllowed(messageUser = true) {
    const gestureData = get(gestures);
    // If less than two gestures
    if (!gestureData || gestureData.length < 2) {
        if (messageUser)
            alertUser(text("alert.twoGestures"));
        return false;
    }
    // If parameters aren't legal
    if (!isParametersLegal()) {
        if (messageUser)
            alertUser(text("alert.oneDataRepresentation"));
        return false;
    }
    // If gestures have less than three recordings per gesture.
    if (!sufficientGestureData(gestureData, messageUser))
        return false;
    return true;
}
// Assess whether each gesture has sufficient data. (Limited by three)
export function sufficientGestureData(gestureData, messageUser) {
    let sufficientData = true;
    gestureData.forEach(gesture => {
        if (gesture.recordings.length < 3) {
            if (messageUser)
                alertUser(text("alert.recordingsPerGesture"));
            sufficientData = false;
        }
    });
    return sufficientData;
}
// Returns model with the settings during initiation of training
// Saves settings to ensure future predictions fits the model.
function createModel() {
    // Save model settings at the time of training.
    modelSettings = {
        axes: get(settings).includedAxes,
        params: get(settings).includedParameters
    };
    // Options for the neural network
    const options = {
        inputs: createInputs(modelSettings),
        task: "classification",
        debug: "false",
        learningRate: get(settings).learningRate // Måske justerbart?
    };
    // Initialize neuralNetwork from ml5js library
    // @ts-ignore
    return ml5.neuralNetwork(options);
}
// Set state to not-Training and initiate prediction.
function finishedTraining() {
    state.update(obj => {
        obj.isTraining = false;
        return obj;
    });
    setupPrediction();
}
// For each epoch, whileTraining is called.
// Updates trainingState, which components can listen to.
function whileTraining(epoch, loss) {
    // shows result
    const numEpochs = get(settings).numEpochs + 1;
    trainingState.set({
        percentage: Math.round((epoch / numEpochs) * 100),
        loss: loss.val_loss,
        epochs: epoch
    });
}
// makeInput reduces array of x, y and z inputs to a single object with values.
// Depending on user settings. There will be anywhere between 1-12 parameters in
// The return object.
export function makeInputs(x, y, z) {
    const obj = {
        ax_max: undefined,
        ax_min: undefined,
        ax_std: undefined,
        ax_peaks: undefined,
        ax_total: undefined,
        ay_max: undefined,
        ay_min: undefined,
        ay_std: undefined,
        ay_peaks: undefined,
        ay_total: undefined,
        az_max: undefined,
        az_min: undefined,
        az_std: undefined,
        az_peaks: undefined,
        az_total: undefined
    };
    if (!modelSettings) {
        modelSettings = {
            axes: get(settings).includedAxes,
            params: get(settings).includedParameters
        };
    }
    if (modelSettings.axes[0]) {
        if (modelSettings.params[0]) {
            obj.ax_max = Math.max(...x);
        }
        if (modelSettings.params[1]) {
            obj.ax_min = Math.min(...x);
        }
        if (modelSettings.params[2]) {
            obj.ax_std = standardDeviation(x);
        }
        if (modelSettings.params[3]) {
            obj.ax_peaks = peaks(x).numPeaks;
        }
        if (modelSettings.params[4]) {
            obj.ax_total = totalAcceleration(x);
        }
    }
    if (modelSettings.axes[1]) {
        if (modelSettings.params[0]) {
            obj.ay_max = Math.max(...y);
        }
        if (modelSettings.params[1]) {
            obj.ay_min = Math.min(...y);
        }
        if (modelSettings.params[2]) {
            obj.ay_std = standardDeviation(y);
        }
        if (modelSettings.params[3]) {
            obj.ay_peaks = peaks(y).numPeaks;
        }
        if (modelSettings.params[4]) {
            obj.ay_total = totalAcceleration(y);
        }
    }
    if (modelSettings.axes[2]) {
        if (modelSettings.params[0]) {
            obj.az_max = Math.max(...z);
        }
        if (modelSettings.params[1]) {
            obj.az_min = Math.min(...z);
        }
        if (modelSettings.params[2]) {
            obj.az_std = standardDeviation(z);
        }
        if (modelSettings.params[3]) {
            obj.az_peaks = peaks(z).numPeaks;
        }
        if (modelSettings.params[4]) {
            obj.az_total = totalAcceleration(z);
        }
    }
    return obj;
}
// Set the global state. Telling components, that the program is prediction
function isPredicting(bool) {
    state.update(s => {
        s.isPredicting = bool;
        return s;
    });
}
// Add parameter to allow unsubscribing from store, when predicting ends.
// Prevents memory leak.
let unsubscribeFromSettings;
// Variable for accessing the predictionInterval
let predictionInterval;
// Setup prediction. Listens for user-settings (Updates pr second).
// Whenever this changes, the updatesPrSecond also changes.
function setupPrediction() {
    // Set state and fetch updatesPrSecond.
    isPredicting(true);
    const updatesPrSecond = get(settings).updatesPrSecond;
    const automatic = get(settings).automaticClassification;
    if (automatic) {
        predictionInterval = setInterval(classify, (1000 / updatesPrSecond));
    }
    else {
        predictOnButton("");
    }
    // When user changes settings
    unsubscribeFromSettings = settings.subscribe(update => {
        // Only if the updatesPrSecond changed or buttons changed
        if (update.updatesPrSecond !== updatesPrSecond || update.automaticClassification !== automatic) {
            if (predictionInterval)
                clearInterval(predictionInterval);
            predictionInterval = undefined;
            setupPrediction();
        }
    });
}
function predictOnButton(button) {
}
// Classify data
export function classify() {
    // Get currentState to check whether the prediction has been interrupted by other processes
    const currentState = get(state);
    const hasBeenInterrupted = !currentState.isPredicting || currentState.isRecording || currentState.isTraining;
    if (hasBeenInterrupted) {
        clearInterval(predictionInterval);
        predictionInterval = undefined;
        isPredicting(false);
        if (unsubscribeFromSettings)
            unsubscribeFromSettings();
        return;
    }
    if (!currentState.isConnected)
        return;
    // push data from data-points
    const { x, y, z } = getPrevData();
    // Turn the data into an object of up to 12 parameters
    const input = makeInputs(x, y, z);
    // Pass parameters to classify
    get(model).classify(input, handleResults);
}
// Once classified the results from the algorithm is sent
// to components through the prediction store.
function handleResults(error, result) {
    if (error) {
        alertUser(error);
        console.error(error);
        return;
    }
    prediction.set(result);
}
// creates input parameters for the algortihm.
// Utilizes the learningParameter array and the user settings to
// Create an option array which the learning algorithm takes in.
function createInputs(s) {
    const options = [];
    for (const axes in s.axes) {
        for (const param in s.params) {
            if (s.axes[axes] && s.params[param]) {
                const lookup = parseInt(axes) * 5 + parseInt(param);
                options.push(learningParameters[lookup]);
            }
        }
    }
    return options;
}
