<script lang="ts">
	export let light: boolean = false;
	export let onMouseEnter: (iconElement: Node) => void;
	export let onMouseLeave: () => void;
	export let offset: { x: number, y: number } = { x: 0, y: 0 };
	let iconElement;
	const color = light ? "rgba(255,255,255,0.5)" : "rgb(160,160,160)";
	const posTranslate = `translate(${offset.x}px, ${offset.y}px)`;
	let isHovering = false;
</script>

<div class="absolute">
	<div style="transform: translate({offset.x}px, {offset.y}px">
	<i
		bind:this={iconElement}
		class="far fa-question-circle"
		class:hovering={isHovering}
		on:mouseenter={() => {isHovering = true; onMouseEnter(iconElement)}}
		on:mouseleave={() => {isHovering = false; onMouseLeave()}}
		style="color:{color}"></i>
	</div>
</div>

<style>
    .hovering {
        transition: 0.2s ease;
        transform: scale(1.3);
    }
</style>