<script lang="ts">
	import OutputGesture from "../components/OutputGesture.svelte";
	import {
		buttonPressed,
		gestures,
		getPrevData,
		informUser,
		notReady,
		prediction,
		settings,
		state,
		storeGesturesLocally,
	} from "../script/common";
	import { get } from "svelte/store";
	import { onDestroy, onMount } from "svelte";
	import { classify } from "../script/ml";
	import Controlbar from "../components/Controlbar.svelte";
	import ControlbarBox from "../components/ControlbarBox.svelte";
	import { t } from "../i18n";
	import Information from "../components/information/Information.svelte";

	// When the prediction is running, Inject the confidence into each gesture
	// Each gesture is then responsible for its own confidence.
	const unsubscription = prediction.subscribe((data) => {
		data.forEach((point) => {
			for (let gesture of get(gestures)) {
				if (point.label === String(gesture.ID)) {
					gesture.confidence = point.confidence;
				}
			}
		});

		// send an update to all gesture listeners
		gestures.update((e) => e);
	});

	// Save to local storage every 3sek to avoid users work disappear
	const updateLocalGesturesInterval = setInterval(storeGesturesLocally, 3000);

	// To avoid memory leak. intervals and subscriptions
	onDestroy(unsubscription);
	onDestroy(() => {
		clearInterval(updateLocalGesturesInterval);
	});

	// In case of manual classification, variables for evaluation
	let recordingTime = 0;
	let lastRecording;

	/**
	 * Classify based on button click
	 */
	// method for recording gesture for that specific gesture
	function classifyClicked(e) {
		if (notReady()) return;

		$state.isRecording = true;
		lastRecording = undefined;
		informUser("Optager");

		// Get duration
		const duration = get(settings).duration;

		// Loading interval
		const loadingInterval = setInterval(() => {
			recordingTime++;
		}, duration / 30);

		// Once duration is over (1000ms default), stop recording
		setTimeout(() => {
			clearInterval(loadingInterval);
			lastRecording = getPrevData();
			$state.isRecording = false;
			recordingTime = 0;
			classify();
		}, duration);
	}

	// When microbit buttons are pressed, this is called
	// Assess whether settings match with button-clicked.
	// If so, the gesture calls the recording function.
	function triggerButtonsClicked(buttons) {
		if (firstMount) return;
		console.log("buttons clicked", buttons);

		const set = get(settings);
		if (
			!set.automaticClassification &&
			(buttons.buttonA || buttons.buttonB)
		)
			classifyClicked(undefined);
	}

	// Hack to avoid classifying when visiting the webpage
	let firstMount = true;
	onMount(() => {
		firstMount = false;
		updateOverlayDimensions();
	});

	let mainPane;
	let overlayWidth = 0;
	let overlayHeight = 0;

	const updateOverlayDimensions = () => {
		if (!mainPane) return;
		overlayWidth = mainPane.getBoundingClientRect().width;
		overlayHeight = mainPane.getBoundingClientRect().height;
	};

	// Crude fix for odd resizing behaviour
	window.addEventListener("resize", (_) => {
		updateOverlayDimensions();
		setTimeout(() => updateOverlayDimensions(), 600);
	});

	// Listen on clicks on the input micro:bit
	$: triggerButtonsClicked($buttonPressed);
</script>

<!-- Controlbar -->
<Controlbar expanded="true">
	<!-- Red line for showing recording progess during recording -->
	{#if $state.isRecording}
		<div
			class="bg-red-600 h-0.5 absolute"
			style="width: calc((100vw - 16rem)*{recordingTime /
				30}); transform: translate(0rem, 1.5rem);"
		/>
	{/if}

	<!-- Box for setting trigger for classifies -->
	<ControlbarBox>
		<div class="flex items-center">
			<input
				type="checkbox"
				id="automaticPredict"
				class="w-4 h-4 mr-2"
				bind:checked={$settings.automaticClassification}
				disabled={$state.isRecording}
			/>
			<label
				class={$settings.automaticClassification ? "" : "text-red-600"}
				for="automaticPredict"
				>{$settings.automaticClassification
					? $t("content.model.header.automaticOn")
					: $t("content.model.header.automaticOff")}</label
			>
		</div>

		<!--
      <label for="trigger" class="mr-2 ml-6">Klassificer</label>
      <select
        class="bg-gray-100 text-sm py-1 px-2 rounded btn transition ease border border-gray-400"
        name="trigger"
        id="trigger"
      >
        <option value="auto">Automatisk</option>
        <option value="A">A-knap</option>
        <option value="B">B-knap</option>
      </select>
    -->
	</ControlbarBox>
	<div>
		<Information
			text={$t("content.model.header.automatic.helpBody")}
			title={$t("content.model.header.automatic.helpHeading")}
			light={false}
			boxOffset={{ x: 20, y: 0 }}
			iconOffset={{ x: 0, y: -11 }}
			width={30}
		/>
	</div>

	<!-- If automatic. Set how often one wants to classify -->
	<!--
  <ControlbarBox>
    {#if $settings.automaticClassification}
      <div class="flex items-center">
        <label for="updatesPrSecond" class="ml-8 mr-2">Opdatering</label>
        <input
          id="updatesPrSecond"
          type="range"
          min="1"
          class="mt-1 w-full text-red-400"
          max="8"
          step="1"
          bind:value={$settings.updatesPrSecond}
        />
        <p class="ml-2 mr-2 whitespace-nowrap">
          {$settings.updatesPrSecond} pr. sekund
        </p>
      </div>
    {/if}
  </ControlbarBox>
  -->
</Controlbar>

<!-- Main pane -->
<main
	bind:this={mainPane}
	on:resize={() => updateOverlayDimensions()}
	class="w-full h-full"
>
	<!-- If no model has been trained yet, tell user -->
	{#if !$state.isPredicting}
		<div
			class="absolute z-1 bg-warm-gray-100/95 items-center flex"
			style="
         width: {overlayWidth}px;
         height: {overlayHeight}px"
		>
			<div class="m-auto text-center">
				<p class="text-black font-medium text-3xl">
					{$t("content.model.trainModelFirstHeading")}
				</p>
				<p class="text-black">
					{$t("content.model.trainModelFirstBody")}
				</p>
			</div>
		</div>
	{/if}

	<div class="w-full pt-15 pl-3">
		<!-- Display all gestures and their output capabilities -->
		{#each $gestures as gesture}
			<OutputGesture {gesture} />
		{/each}
	</div>
</main>
